import { Component, OnInit } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { ScrapeBaseItemComponent } from '../scrape-base-item/scrape-base-item.component'
import { Race } from '../../model/race'

@Component({
  selector: 'app-race-item',
  templateUrl: '../../base-classes/abstract-base-item/abstract-base-item.component.html',
  styleUrls: ['../../styles/global.scss','../../base-classes/abstract-base-item/abstract-base-item.component.scss']
})
export class RaceItemComponent extends ScrapeBaseItemComponent {

  public raceid: string

  setParams(params) {
    this.id = params['id']
    this.raceid = params['raceid']
  }

  getItem() {
    this.dataService.getChampRace(this.id, this.raceid)
    .subscribe(item => {
      this.dataGetSuccess(item)
    })
  }

  getLinkToList(listId: number) {
    switch (listId) {
      case 1:
        return "/data/champ/"+this.id+"/races/"
        break
      case 2:
        return "/data/champ/"+this.id+"/race/"+this.raceid+"/sessions"
        break
      default:
        break
      return ''
    }
  }


  getItemName() {
    return "Race"
  }

  getItemNames() {
    return "Races"
  }

  getListName(listId: number) {
    super.getListName(listId)
    switch (listId) {
      case 1:
        return this.getItemNames()
        break
      case 2:
        return "Race Sessions"
        break
      default:
        break
      return 'Unknown'
    }
  }

  haveButton(buttonId: number) {
    switch (buttonId) {
      case 1:
        return true
        break
      case 2:
        return true
        break
      default:
        break
      return false
    }
  }

  getItemId() {
    return this.id.toUpperCase()+' / '+this.raceid
  }

  getValidators() {
    return {}
  }

  getDisabledFields() {
    return ['id','raceid']
  }

  disableField(field: string, ctrls: any) {
    if (field == 'id' || field == 'raceid')
      ctrls[field].disable()
  }

  updateItem() {
    this.dataService.updateRace(this.id, this.raceid, JSON.stringify(this.item))
    .subscribe(
      val => this.dataSuccess(val, 'PATCH'),
      err => this.dataError(err, 'PATCH'),
      () => {}
    )
  }


}

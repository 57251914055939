import { Component, OnInit } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { ScrapeBaseItemComponent } from '../scrape-base-item/scrape-base-item.component'
import { Driver } from '../../model/driver'

@Component({
  selector: 'app-driver-item',
  templateUrl: '../../base-classes/abstract-base-item/abstract-base-item.component.html',
  styleUrls: ['../../styles/global.scss','../../base-classes/abstract-base-item/abstract-base-item.component.scss']
})
export class DriverItemComponent extends ScrapeBaseItemComponent {

  public driverid: string

  setParams(params) {
    super.setParams(params)
    this.driverid = params['driverid']
  }

  getItem() {
    this.dataService.getChampDriver(this.id, this.driverid, this.year)
    .subscribe(item => {
      this.dataGetSuccess(item)
    })
  }

  getLinkToList() {
    return "/data/champ/"+this.id+"/drivers/"
  }

  getItemName() {
    return "Driver"
  }

  getItemNames() {
    return "Drivers"
  }

  getItemId() {
    return this.id.toUpperCase()+' / '+this.driverid
  }

  getValidators() {
    return {}
  }

  getDisabledFields() {
    return ['id','driverid']
  }

  disableField(field: string, ctrls: any) {
    if (field == 'id' || field == 'driverid')
      ctrls[field].disable()
  }

  updateItem() {
    this.dataService.updateDriver(this.id, this.driverid, this.year, this.item.toJson())
    .subscribe(
      val => this.dataSuccess(val, 'PATCH'),
      err => this.dataError(err, 'PATCH'),
      () => {}
    )
  }


}

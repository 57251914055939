import { Component } from '@angular/core';
import { Session } from '../../model/session'
import { ViewEncapsulation } from '@angular/core';
import { ScrapeItemListComponent } from '../scrape-item-list/scrape-item-list.component'

@Component({
  selector: 'app-session-list',
  templateUrl: '../scrape-item-list/scrape-item-list.component.html',
  styleUrls: ['../../styles/global.scss','../scrape-item-list/scrape-item-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RaceSessionListComponent extends ScrapeItemListComponent {

  public raceid: string
  public id: string
  timerId: any

  setParams(params) {
    this.id = params['id']
    this.raceid = params['raceid']
  }

  ngOnInit(): void {
    super.ngOnInit()
    this.timerId = setInterval(() => {
      document.body.style.cursor='wait';
      this.getItems()
    }, 10000); // Activate every 10 secs
  }

  ngOnDestroy() {
    //super.ngOnDestroy()
    clearInterval(this.timerId)
  }

  haveScrapeItemsFeature() {
   return false
  }

  haveScrapeAllFeature() {
    return true
  }

  getLinkToEdit(item) {
    // console.log("Item = ", item)
    return "/data/champ/"+this.id+"/race/"+this.raceid+"/session/"+this.getItemId(item)
  }

  getLinkToChildren(item) {
    // console.log("Item = ", item)
    return "/data/champ/"+this.id+"/race/"+this.raceid+"/session/"+this.getItemId(item)+"/results"
  }

  getLinkToExtraItem(item) {
    // console.log("Item = ", item)
    return "/data/champ/"+this.id+"/race/"+this.raceid+"/session/"+this.getItemId(item)+"/notifications/mmlive-alerts"
  }

  getTitle() {
      return this.raceid+" Sessions"
  }
  getItemsName() {
    return this.id.toUpperCase()+" Sessions"
  }

  getItemName() {
    return this.id.toUpperCase()+" Session"
  }

  getItemId(item) {
    return item.sessionid
  }

  getCollectionName() {
    return this.id.toUpperCase()+' / '+this.raceid
  }
  addItemTHValues(tableid) {
    let str = ""
    let tab = document.getElementById(tableid)
    if (!tab)
      return
    let elem = this.addElement(tab, "thead", '')
    if (!elem)
      return
    while (elem.firstChild) {
      elem.removeChild(elem.lastChild)
    }
    this.addElement(elem, 'th', 'Description')
    this.addElement(elem, 'th', 'Status')
    this.addElement(elem, 'th', 'Lap / Laps')
    this.addElement(elem, 'th', 'Time left')
    this.addElement(elem, 'th', 'Added time')
    this.addElement(elem, 'th', 'Date')
    this.addElement(elem, 'th', 'Times')
    this.addElement(elem, 'th', '')
    this.addElement(elem, 'th', '')
    this.addElement(elem, 'th', '')
    this.addElement(elem, 'th', 'Live?')
  }


  addItemTDValues(item) {
    let elem = document.getElementById(this.getTableId())
    if (!elem)
      return
    let tr = this.addElement(elem, "tr", '')
    if (!tr)
      return
    // this.addElement(tr, 'td', this.getItemId(item))
    tr.onclick = (event) => this.editItem(event, item)
    let st = this.addElement(tr, 'td', this.capitalize(item.description))
    this.addElement(tr, 'td', item.status)
    this.addElement(tr, 'td', this.lapInfo(item))
    this.addElement(tr, 'td', item.time_left)
    this.addElement(tr, 'td', item.added_time)
    this.addElement(tr, 'td', item.date)
    this.addElement(tr, 'td', item.time_start+" - "+item.time_end)

    st.onclick = (event) => this.showStage(event, item.stage)

    let btn = this.addDeleteButtonIcon(tr, 'td', this.canEdit())
    btn.onclick = (event) => this.askDeleteItem(event, item)
    let btn2 = this.addChildrenButton(tr, 'td', 'Results')
    btn2.onclick = (event) => this.listItemChildren(event, item)
    let btn3 = this.addChildrenButtonIcon(tr, 'td', 'fa-bell')
    btn3.onclick = (event) => this.extraItemChildren(event, item)
    this.addScrapeStatusElement(item, tr)

  }

  showStage(event, stage) {
    document.location.href = "/data/stages/"+this.id+"/"+stage+"/default"
    event.stopPropagation()
    return false
  }

  addScrapeAllButton() {
    let elem = document.getElementById("topdiv")
    if (!elem)
      return
    let table = this.addElement(elem, 'table', '')
    let tr = this.addElement(table, "tr", '')
    let btn = this.addScrapeButton(tr, 'td', this.canEdit())
    btn.onclick = (event) => this.askScrapeAllItems()
  }

  addScrapeStatusElement(item, parent) {
    if (['Finished','Not started','Cancelled'].includes(item.status))
      return this.addElement(parent, 'td', '')
    let scrape_ok = item.scrape_status == 'Scrape OK'
    let el = this.addElement(parent, 'td', '')
    let sp = this.addElement(el, 'span', '')
    sp.classList.add((scrape_ok) ? 'led-ok' : 'led-fail')
    let ic = this.addElement(sp, 'i', '')
    if (scrape_ok) {
      ic.classList.add('far')
      ic.classList.add('fa-thumbs-up')
    }
    else {
      ic.classList.add('fas')
      ic.classList.add('fa-exclamation-triangle')
    }
  }


  getItems() {
    document.body.style.cursor='wait';
    this.dataService.getRaceSessions(this.id, this.raceid)
    .subscribe(items => {
      items.sort((a,b) => {
        return (a.scheduled < b.scheduled) ? -1 : 1
      })
      this.afterGetItems(items)
    })
  }

  deleteItem(item) {
    document.body.style.cursor='wait';
    this.dataService.deleteSession(this.id, this.getItemId(item))
    // this.dataService.deleteChamp(this.getItemId(item))
      .subscribe(res => this.afterSuccessfulDelete(res),
                 response => this.afterFailedDelete(response))
  }

  scrapeItem(item) {
    document.body.style.cursor='wait';
    this.dataService.scrapeChampRace(this.id, this.year, item.raceid, this.id, false, false)
      .subscribe(res => this.afterScrapeItemSuccess(res), err => this.afterScrapeError(err))
  }

  scrapeAllItems() {
    document.body.style.cursor='wait';
    this.dataService.scrapeChampRace(this.id, this.year, this.raceid, this.id, false, false)
      .subscribe(res => this.afterScrapeItemsSuccess(res), err => this.afterScrapeError(err))
  }

  getLap(session) {
    if (session.laps_completed)
      return Number(session.laps_completed)+1
    return session.laps_completed
  }

  lapInfo(session) {
    let lap = this.getLap(session)
    if (session.laps && lap)
      return lap+'/'+session.laps
    if (lap)
      return ''+lap
    return ''
  }

}

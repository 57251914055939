import { Component } from '@angular/core';
import { Router } from '@angular/router'
import { SecurityService } from './security/security.service'
import { AppUserAuth } from './security/app-user-auth'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./styles/global.scss', './app.component.scss'],
  animations: [
  // animation triggers go here
  ]
})
export class AppComponent {

  isProd: Boolean
  securityObject: AppUserAuth = null

  title = 'MotorMouth Admin'

  constructor(private router: Router,
              private securityService: SecurityService) {
    this.isProd = localStorage.getItem('env') == 'prod'
    console.log("Loading security object")
    this.securityObject = securityService.loadSecurityObject()

  }

  toggleProd() {
    this.isProd = !this.isProd
    //console.log("New env = ", this.isProd)
    localStorage.setItem('env', this.isProd ? 'prod' : 'test');
    this.reloadCurrentRoute()
  }

  reloadCurrentRoute() {
      let currentUrl = this.router.url;
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
          this.router.navigate([currentUrl]);
      });
  }

  logout(): void {
    this.securityService.logout()
  }
}

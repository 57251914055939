export class ContentStats {

  public sourceId: string
  public category: string
  public type: string
  public fromDate: string
  public lastDate: string
  public total: Number
  public lastUpdated: string

  constructor (
    sourceId: string,
    category: string,
    type: string,
    fromDate: string,
    lastDate: string,
    total: Number,
    lastUpdated: string) {
      this.sourceId = sourceId
      this.category = category
      this.type = type
      this.fromDate = fromDate
      this.lastDate = lastDate
      this.total = total
      this.lastUpdated = lastUpdated
    }

}

import { Component, OnInit } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { ScrapeBaseItemComponent } from '../scrape-base-item/scrape-base-item.component'
import { Session } from '../../model/session'

@Component({
  selector: 'app-session-item',
  templateUrl: '../../base-classes/abstract-base-item/abstract-base-item.component.html',
  styleUrls: ['../../styles/global.scss','../../base-classes/abstract-base-item/abstract-base-item.component.scss']
})
export class SessionItemComponent extends ScrapeBaseItemComponent {

  public sessionid: string
  public raceid: string

  setParams(params) {
    this.id = params['id']
    this.sessionid = params['sessionid']
    this.raceid = this.sessionid.substring(0, this.sessionid.lastIndexOf(':'))
  }

  getItem() {
    this.dataService.getRaceSession(this.id, this.raceid, this.sessionid)
    .subscribe(item => {
      this.dataGetSuccess(item)
      console.log(item)
    })
  }

  getLinkToList() {
    console.log("Link to list = ", "/data/champ/"+this.id+"/race/"+this.raceid+"/sessions/")
    return "/data/champ/"+this.id+"/race/"+this.raceid+"/sessions/"
  }

  getItemName() {
    return "Session"
  }

  getItemNames() {
    return "Sessions"
  }

  getItemId() {
    return this.id.toUpperCase()+' / '+this.sessionid
  }

  getValidators() {
    return {}
  }

  getDisabledFields() {
    return ['id','sessionid','stage','race_id']
  }

  disableField(field: string, ctrls: any) {
    if (field == 'id' || field == 'sessionid' || field == 'stage' || field == 'race_id')
      ctrls[field].disable()
  }

  updateItem() {
    this.dataService.updateSession(this.id, this.sessionid, this.item.stringify())
    .subscribe(
      val => this.dataSuccess(val, 'PATCH'),
      err => this.dataError(err, 'PATCH'),
      () => {}
    )
  }


}

import { Component, OnInit, Input , Output, EventEmitter} from '@angular/core';
import { Notification } from '../../model/notification'
import { BaseItemComponent } from '../constants/base-item/base-item.component'
import { DataService } from '../../services/data.service'
import { environment } from '../../constants'
import { FormGroup, FormControl, FormsModule, Validators } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { AppUserAuth } from '../../security/app-user-auth'
import { SecurityService } from '../../security/security.service'
import { StringValidator } from '../constants/base-item/string.validator'

@Component({
  selector: 'app-notification-item',
  templateUrl: '../../base-classes/abstract-base-item/abstract-base-item.component.html',
  styleUrls: ['../../styles/global.scss','../../base-classes/abstract-base-item/abstract-base-item.component.scss']
})
export class NotificationItemComponent extends BaseItemComponent {

  public topic: string
  public datestamp: Number
  public champid: string
  public raceid: string
  public sessionid: string

  item: Notification

  setParams(params) {
    this.topic = params['topic']
    this.datestamp = params['datestamp']
  }

  getItem() {
    this.dataService.getNotification(this.topic, this.datestamp)
    .subscribe(item => {
      console.log("ITEM = ", item)
      this.champid = item['activity_type']
      this.sessionid = item['stage_id']
      this.raceid = item['raceid']
      console.log("Have params: ", this.champid, this.sessionid, this.raceid)
      this.dataGetSuccess(item)
    })
  }

  disableField(field: string, ctrls: any) {
    if (field == 'datestamp')
      ctrls[field].disable()
    if (field == 'topic')
      ctrls[field].disable()
  }

  haveButton(buttonId: Number) {
    switch (buttonId) {
      case 1:
      case 2:
      case 3:
        return true
        break
      default:
        break
      return false
    }

  }

  getButtonDesc(buttonId: Number) {
    return this.getListName(buttonId)
  }


  getListName(listId: Number) {
    switch (listId) {
      case 1:
        return "Champ "+this.getItemNames()
        break
      case 2:
        return "Race "+this.getItemNames()
        break
      case 3:
        return "Session "+this.getItemNames()
        break
      default:
        break
      return 'Unknown'
    }
  }

  getLinkToList(listId: Number) {
    switch (listId) {
      case 1:
        return "/data/champ/"+this.champid+"/notifications/"+this.topic
        break
      case 2:
        return "/data/champ/"+this.champid+'/race/'+this.raceid+"/notifications/"+this.topic
        break
      case 3:
        return "/data/champ/"+this.champid+'/race/'+this.raceid+"/session/"+this.sessionid+"/notifications/"+this.topic
        break
      default:
        break
      return 'Unknown'
    }
  }

  updateItem() {
    let st = this.item
    let cat = st.toJson()
    this.dataService.updateNotification(this.topic, this.datestamp, cat)
    .subscribe(
      val => {
        this.dataSuccess(val, 'PUT')
      },
      err => this.dataError(err, 'PUT'),
      () => {}
    )
  }

  deleteItem() {
    let st = this.item
    // this.dataService.deleteNotification(st.topic, st.datestamp)
    // .subscribe(
    //   val => this.dataSuccess(val, 'DELETE'),
    //   err => this.dataError(err, 'DELETE'),
    //   () => {}
    // )
  }

  getValidators() {
    let vals = {}
    vals["topic"] = [Validators.required, StringValidator.cannotContainSpace]
    vals["datestamp"] = [Validators.required]
    return vals
  }

  getDisabledFields() {
    let list = ["topic","datestamp"]
    return list
  }

  getItemName() {
    return "Notification"
  }

  getItemNames() {
    return "Notifications"
  }

  getItemId() {
    return this.datestamp.toString()
  }

  duplicateItem() {
    let newItem = new Notification("{}")
    newItem.copy(this.item)
    this.item = newItem
    this.updateForm()
  }
}

import { Component, OnInit } from '@angular/core';
import { Source } from '../../model/source';
import { Observable } from 'rxjs';
import { MatTableModule } from '@angular/material/table'
import { MatSortModule } from '@angular/material/sort';
import { Router } from '@angular/router'

import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { BreadcrumbModule } from "xng-breadcrumb";

@Component({
  selector: 'app-data-menu',
  templateUrl: './data-menu.html',
  styleUrls: ['./data-menu.scss']
})
export class DataMenu  {
  state: string = "init"
  year: number = 2022

  constructor(private router: Router) {

  }

  ngOnInit(): void {
    let y = localStorage.getItem("year")
    //console.log("App Year = ", y)
    if (y && y !== undefined && y !== '0') {
      console.log("Setting year to ", y)
      this.year = Number(y)
    }
    localStorage.setItem("year", String(this.year))

    this.state = "ready"
    let url = this.router.routerState.snapshot.url;
    console.log("ROUTE ", url)
    if (url == '/')
      this.router.navigate(['/data/champs'])
  }


  setYear() {
    let el =  document.getElementById("yearSetting")
    var inputValue = (<HTMLInputElement>el).value
    //console.log("App: setting year to ", el.value)
    this.year = Number(inputValue)
    //this.year = Number(el.value)
    localStorage.setItem("year", String(this.year))
    this.reloadCurrentRoute()
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
    });
}

}

import { Component, OnInit, Input , Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { BaseItem } from '../../model/base-item'
import { DataService } from '../../services/data.service'
import { environment } from '../../constants'
import { UntypedFormGroup, UntypedFormControl, FormsModule, Validators } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { AppUserAuth } from '../../security/app-user-auth'
import { SecurityService } from '../../security/security.service'
//import { StringValidator } from './string.validator'
import { Location } from '@angular/common'
import {Title} from "@angular/platform-browser"
import {Router} from "@angular/router"

@Component({
  selector: 'app-abstract-base-item',
  templateUrl: './abstract-base-item.component.html',
  styleUrls: ['../../styles/global.scss','./abstract-base-item.component.scss']
})
export class AbstractBaseItemComponent implements OnInit {

  @Input() public id: string
  state: string = "pending"
  item: BaseItem
  formid: string
  pageTitle: string
  form: UntypedFormGroup
  fieldForm: UntypedFormGroup
  validators: any
  @Output() itemUpdated: EventEmitter<BaseItem> = new EventEmitter()
  securityObject: AppUserAuth = null

  constructor(protected dataService: DataService,
              protected route: ActivatedRoute,
              protected router: Router,
              protected securityService: SecurityService,
              protected loc: Location,
              protected titleService: Title) {
    this.securityObject = securityService.securityObject
    this.pageTitle = "Admin UI - "
    route.params.subscribe(params => {
      this.setParams(params)
      this.formid = "form-"+this.id
      this.setTitle()
      this.updateTitle()
      this.refresh()
    })
  }

  updateTitle() {
    this.titleService.setTitle(this.pageTitle)
  }

  setTitle() {
    this.pageTitle += "Abstract Base Item: "+this.id.toUpperCase()
  }

  ngOnChanges(changes: any) {
    if (changes.item)
      this.updateForm()
  }

  ngOnInit(): void {
    this.setTitle()
    this.updateTitle()
    this.refresh()
  }

  refresh() {
    this.getItem()
  }

  capitalize(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  goBack(): void {
    this.loc.back()
  }

  updateForm() {
    let validators = this.getValidators()
    let disabledFields = this.getDisabledFields()
    let ctrls = this.getControls(this.item, validators, disabledFields)
    this.form = new UntypedFormGroup(ctrls)
  }

  anyInvalidFields() {
    for (let key of this.getKeys()) {
      if (this.invalid(key))
        return true
    }
    return false
  }

  invalid(key) {
    return this.form.get(key).touched && this.form.get(key).invalid
  }

  validationError(key) {
    let str = ""
    for (let err in this.form.get(key).errors) {
      if (str)
        str = ", "+str
      str += key+": "+err
    }
    return str //key+" cannot contain a space"
    //f.location.errors.cannotContainSpace
  }

  getControls(fields, validators, disabled) {
    let ctrls = {}
    // console.log("Fields = ", fields)
    for (const field in fields) {
      let subField = fields[field]
      // console.log("Subfield = ", subField, typeof subField)
      if (subField === undefined || typeof subField === 'string' || typeof subField === 'boolean' || typeof subField === 'number') {
          if (field in validators)
            ctrls[field] = new UntypedFormControl(subField, validators[field])
          else
            ctrls[field] = new UntypedFormControl(subField)
          if (disabled.includes(field))
            this.disableField(field, ctrls)
      }
      // if (typeof subField === 'object')
      //   ctrls[field] = new UntypedFormControl(subField)
    }
    return ctrls
  }

  getFormKeys(group: any) {
    let keys = Object.keys(group.controls)
    //this.hidEl.nativeElement.value= 'updated'
    //console.log(keys)
    return keys
  }

  isBool(key, group: UntypedFormGroup) {
    //console.log("Value = ", group.value[key])
    if (typeof group.value[key] == 'boolean')
      return true
    return false
  }

  isText(key, group: UntypedFormGroup) {
    //console.log("Value = ", group.value[key])
    // if (typeof group.value[key] == 'boolean')
    //   return true
    return false
  }

  isList(key, group: UntypedFormGroup) {
    //console.log("Value = ", group.value[key])
    // if (typeof group.value[key] == 'boolean')
    //   return true
    return false
  }

  isExtra(key) {
    return false
  }

  isOther(key, group: UntypedFormGroup) {
    return !this.isExtra(key) && !this.isBool(key, group) && !this.isText(key, group)
  }

  save(): void {
    if (this.anyInvalidFields()) {
      console.log("Invalid fields")
      return
    }
    this.updateValues(this.item, this.form)

    //return
    this.updateItem(this.item)
  }

  getKeys() {
    let keys = this.getFormKeys(this.form)
    let newKeys = []
    for (const k of keys) {
      if (k != 'body')
        newKeys.push(k)
    }
    if (this.haveExtraKeys())
      return this.pushExtraKeys(newKeys)
    return newKeys
  }

  controlId(key) {
    return "input-"+key.toLowerCase()+"-"+this.id
  }

  updateValues(cat: BaseItem, form: any) {
    let keys = this.getFormKeys(form)
    for (const key of keys) {
      //console.log("Key = ", key, form.get(key))
      if (form.get(key) instanceof UntypedFormControl) {
         cat[key] = form.get(key).value
      }
    }
    console.log("SAVING: ", cat)
  }

  dataSuccess(val, method) {
    console.log(method+" call successful value returned in body",
                val);
    this.loadList()
  }


    dataError(val, method) {
      console.log(method+" call in error", val);
      this.refresh()
    }


  canEdit() {
    return this.securityObject.canEditData
  }

  goToButton(buttonId: Number) {

    this.router.navigate([this.getLinkForButton(buttonId)])
    return false
  }

  getButtonDesc(buttonId: Number) {
    return "List of "+this.getListName(buttonId)
  }

  getLinkForButton(buttonId: Number) {
    return this.getLinkToList(buttonId)
  }

  dataGetSuccess(item) {
    this.item = item
    this.addSpecialArea('special-area')
    this.updateForm()
    this.state = "ready"
  }

  ////////////////////////////////////////////////////////////////////////////////
  // NEED TO BE WRITTEN BY DERIVED CLASS

  haveExtraKeys( ) {
    return false
  }

  pushExtraKeys(keys) {
    return keys
  }

  haveAddFields() {
    return false
  }

  extraType(key) {
    return false
  }

  runExtraType(key) {
    return ''
  }
  
  addSpecialArea(divId) {
  }

  setParams(params) {
    this.id = params['id']
  }

  getItem() {
    console.log("Calling get item parent")

  }

  getLinkToList(listId: Number) : string {
    return ''
  }

  getListName(listId: Number) {
    switch (listId) {
      case 1:
        return this.getItemNames()
        break
      default:
        break
      return 'Unknown'
    }
  }

  haveButton(buttonId: Number) {
    switch (buttonId) {
      case 1:
        return true
        break
      default:
        break
      return false
    }
  }

  haveUpdateButton() {
    return true
  }

  getItemName()  {
    return ''
  }

  getItemNames() {
    return 'aa'
  }

  getItemId() {
    return this.id
  }

  getValidators() {
    return {}
  }

  getDisabledFields() {
    return []
  }

  disableField(field: string, ctrls: any) {
  }

  updateItem(item) {
  }

  loadList() {

  }
  // END: NEED TO BE WRITTEN BY DERIVED CLASS
  ////////////////////////////////////////////////////////////////////////////////


}

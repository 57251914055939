import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AppUserAuth } from './app-user-auth';
import { AppUser } from './app-user';
import { LOGIN_MOCKS } from './login-mocks';

@Injectable()
export class SecurityService {
  securityObject: AppUserAuth = new AppUserAuth();

  constructor() { }

  login(entity: AppUser): Observable<AppUserAuth> {
    // Initialize security object
    this.resetSecurityObject();

    // Use object assign to update the current object
    // NOTE: Don't create a new AppUserAuth object
    //       because that destroys all references to object
    this.setUser(entity.userName.toLowerCase())

    if (this.securityObject.userName !== "") {
      // Store into local storage
      localStorage.setItem("bearerToken",
         this.securityObject.bearerToken);
      localStorage.setItem("user",
        this.securityObject.userName);
    }

    return of<AppUserAuth>(this.securityObject);
  }

  logout(): void {
    console.log("Logging out")
    this.resetSecurityObject();
  }

  setUser(userName) {
    Object.assign(this.securityObject,
      LOGIN_MOCKS.find(user => user.userName.toLowerCase() === userName));
  }

  resetSecurityObject(): void {
    this.securityObject.userName = "";
    this.securityObject.bearerToken = "";
    this.securityObject.isAuthenticated = false;

    this.securityObject.canAccessContent = false;
    this.securityObject.canEditContent = false;
    this.securityObject.canAccessData = false;
    this.securityObject.canEditData = false;

    localStorage.removeItem("bearerToken");
    localStorage.removeItem("user");
  }

  loadSecurityObject(): AppUserAuth {
    let bt  = localStorage.getItem("bearerToken")
    let user = localStorage.getItem("user")
    console.log("bt = ", bt, ", user = ", user)
    if (bt && user)
      this.setUser(user)
    return this.securityObject
  }
}

<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
<div class="panel panel-primary">

  <h5 class="panel-title">Constants</h5>

  <ul>
    <li>
      <a routerLinkActive="active" routerLink="/data/champs">Champs</a>
    </li>
    <li>
      <a routerLinkActive="active" routerLink="/data/constants/countries">Countries</a>
    </li>
    <li>
      <a routerLinkActive="active" routerLink="/data/constants/cities">Cities</a>
    </li>
    <li>
      <a routerLinkActive="active" routerLink="/data/constants/equivs">Equivalents</a>
    </li>
  </ul>

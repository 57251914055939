import { Component } from '@angular/core';
import { Race } from '../../model/race'
import { FormGroup, FormControl, Validators } from '@angular/forms'
import { ViewEncapsulation } from '@angular/core';
import { ScrapeItemListComponent } from '../scrape-item-list/scrape-item-list.component'

@Component({
  selector: 'app-race-list',
  templateUrl: '../scrape-item-list/scrape-item-list.component.html',
  styleUrls: ['../../styles/global.scss','../scrape-item-list/scrape-item-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChampRaceListComponent extends ScrapeItemListComponent  {

  public id: string
  year: number = 2021
  public champid: string 

  // OVERRIDDEN METHODS:

  setParams(params) {
   this.id = params.id
  }

  haveExtraInfo() {
    return true
  }

  getLinkToEdit(item) {
    // console.log("Item = ", item)
    return "/data/champ/"+this.id+"/race/"+this.getItemId(item)
  }

  getLinkToChildren(item) {
    // console.log("Item = ", item)
//    return "/data/champ/"+this.id+"/sessions/"+this.getItemId(item)
    return "/data/champ/"+this.id+"/race/"+this.getItemId(item)+"/sessions/"
  }

  getLinkToExtraItem(item) {
    // console.log("Item = ", item)
    return "/data/champ/"+this.id+"/race/"+this.getItemId(item)+"/notifications/mmlive-alerts"
  }

  getItemsName() {
    return this.id.toUpperCase()+" Races"
  }

  getItemName() {
    return this.id.toUpperCase()+" Race"
  }

  getIndexName() {
    return "Round"
  }

  getIndexesName() {
    return "Rounds"
  }

  getItemId(item) {
    return item.raceid
  }

  addItemTHValues(tableid) {
    let str = ""
    let tab = document.getElementById(tableid)
    if (!tab)
      return
    let elem = this.addElement(tab, "thead", '')
    if (!elem)
      return
    while (elem.firstChild) {
      elem.removeChild(elem.lastChild)
    }
    this.addElement(elem, 'th', 'Round')
    this.addElement(elem, 'th', 'Race')
    this.addElement(elem, 'th', 'Country')
    this.addElement(elem, 'th', 'Start')
    this.addElement(elem, 'th', 'Status')
    this.addElement(elem, 'th', '')
    this.addElement(elem, 'th', '')
    this.addElement(elem, 'th', '')
    this.addElement(elem, 'th', '')
  }


  addItemTDValues(item) {
    let elem = document.getElementById(this.getTableId())
    if (!elem)
      return
    let tr = this.addElement(elem, "tr", '')
    if (!tr)
      return
    // this.addElement(tr, 'td', this.getItemId(item))
    tr.onclick = (event) => this.editItem(event, item)
    this.addElement(tr, 'td', item.round)
    this.addElement(tr, 'td', item.name)
    this.addElement(tr, 'td', item.country)
    this.addElement(tr, 'td', item.scheduled)
    this.addElement(tr, 'td', item.status)

    let btn = this.addDeleteButtonIcon(tr, 'td', this.canEdit())
    btn.onclick = (event) => this.askDeleteItem(event, item)
    let btn2 = this.addChildrenButton(tr, 'td', 'Sessions')
    btn2.onclick = (event) => this.listItemChildren(event, item)
    let btn3 = this.addScrapeButton(tr, 'td', this.canEdit())
    btn3.onclick = (event) => this.askScrapeItem(event, item)
    let btn4 = this.addChildrenButtonIcon(tr, 'td', 'fa-bell')
    btn4.onclick = (event) => this.extraItemChildren(event, item)
  }

  getItems() {
    document.body.style.cursor='wait';
    this.dataService.getChamp(this.id, this.year)
    .subscribe(res => {
      this.champid = res.api_id
      console.log("xx ID = ", this.champid)
      this.dataService.getChampRaces(this.id, this.year)
      .subscribe(items => {
        items.sort((a,b) => (a.round < b.round) ? -1 : 1)
        console.log(items)
        this.afterGetItems(items)
      })
    })
  }

  deleteItem(item) {
    document.body.style.cursor='wait';
    this.dataService.deleteRace(this.id, this.getItemId(item))
    // this.dataService.deleteChamp(this.getItemId(item))
      .subscribe(res => this.afterSuccessfulDelete(res),
                 response => this.afterFailedDelete(response))
  }

  scrapeItem(item) {
    document.body.style.cursor='wait';
    this.dataService.scrapeChampRace(this.id, this.year, item.raceid, this.champid, false, false)
      .subscribe(res => this.afterScrapeItemSuccess(res), err => this.afterScrapeError(err))
  }

  scrapeItems() {
    this.beforeScrapeItems()
    this.dataService.scrapeChampRaces(this.id, this.year, this.startScrapeIndex, this.numToScrape, this.champid, this.isQuiet, this.withExtraInfo)
      .subscribe(res => this.afterScrapeItemsSuccess(res), err => this.afterScrapeError(err))
  }

  haveDeleteAllFeature() {
    return false
  }


}


<!-- <app-source-list></app-source-list> -->
<!-- <div class="container">-->
<ul id="menu-account" >
  <li >
    <a routerLinkActive="active" routerLink="contents">Content</a>
  </li>
  <li >
    <a routerLinkActive="active"  routerLink="content-sources">Content by Source</a>
  </li>
  <li >
    <a  routerLinkActive="active" routerLink="/content/content-categories">Content by Category</a>
  </li>
  <li >
    <a  routerLinkActive="active" class="toggle-menu" (click)="openSubMenu('sources-text')" >Text Sources</a>
    <ul id="sub-menu-sources-text" [@openClose]="(isOpenSubMenu('sources-text')) ? 'open' : 'closed'" class="header" >
      <li *ngFor="let src of textSources">
        <a routerLinkActive="active" routerLink="/content/source/{{ src.id }}">{{ src.id }}</a>
      </li>
    </ul>
  </li>
  <li>
    <a routerLinkActive="active" class="toggle-menu" (click)="openSubMenu('sources-video')" >Video Sources</a>
    <ul id="sub-menu-sources-video" [@openClose]="(isOpenSubMenu('sources-video')) ? 'open' : 'closed'" class="header" >
      <li *ngFor="let src of videoSources">
        <a routerLinkActive="active" routerLink="/content/source/{{ src.id }}">{{ src.id }}</a>
      </li>
    </ul>
  </li>
  <li >
    <a  routerLinkActive="active" class="toggle-menu" (click)="openSubMenu('categories')" >Categories</a>
    <ul id="sub-menu-categories" [@openClose]="(isOpenSubMenu('categories')) ? 'open' : 'closed'" class="header" >
      <li *ngFor="let cat of categories">
        <a routerLinkActive="active" routerLink="/content/categories/{{ cat.id }}">{{ cat.name }}</a>
      </li>
    </ul>
  </li>
  <li >
    <a routerLinkActive="active" routerLink="/content/failed_content">Failed content</a>
  </li>
</ul>
<!--
<ul ngbNav  class="nav-tabs">
  <li ngbNavItem>
    <a ngbNavLink routerLinkActive="active" routerLink="content-sources">Content by Source</a>
  </li>
  <li ngbNavItem>
    <a ngbNavLink routerLinkActive="active" routerLink="content-categories">Content by Category</a>
  </li>
</ul> -->

<!-- </div> -->

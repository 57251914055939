import { Component, OnInit, Input, Output } from '@angular/core';
import { FailedContent } from '../../model/failedcontent'
import { BaseItemComponent } from '../../data/constants/base-item/base-item.component'
import { DataService } from '../../services/data.service'
import { Validators } from '@angular/forms'
import { StringValidator } from '../../data/constants/base-item/string.validator'

@Component({
  selector: 'app-failed-content-item',
  templateUrl: '../../base-classes/abstract-base-item/abstract-base-item.component.html',
  styleUrls: ['../../styles/global.scss','../../base-classes/abstract-base-item/abstract-base-item.component.scss']
})
export class FailedContentItemComponent extends BaseItemComponent {

  @Input() public sessionid: string
  @Input() public acquiredstamp: string
  item: FailedContent
  public type: string

  setParams(params) {
    this.type = params['type']
    this.acquiredstamp = params['acquiredstamp']
  }

  getItem() {
    console.log("Getting item")
    this.dataService.getFailedContentItem(this.type, this.acquiredstamp)
    .subscribe(item => {
      console.log("ITEM = ", item)
      this.dataGetSuccess(item)
    })
  }

  disableField(field: string, ctrls: any) {
    if (field == 'type' || field == 'acquiredstamp' || field == 'acquireddate')
      ctrls[field].disable()
  }


  deleteItem() {
    let st = this.item
    this.dataService.deleteScrapeStat(st.type, st.acquiredstamp)
    .subscribe(
      val => this.dataSuccess(val, 'DELETE'),
      err => this.dataError(err, 'DELETE'),
      () => {}
    )
  }

  getValidators() {
    let vals = {}
    vals["acquiredstamp"] = [Validators.required, StringValidator.cannotContainSpace]
    vals["type"] = [Validators.required, StringValidator.cannotContainSpace]
    vals["acquireddate"] = [Validators.required, StringValidator.cannotContainSpace]
    return vals
  }

  getDisabledFields() {
    let list = ["type", "acquiredstamp", "acquireddate"]
    return list
  }

  getItemName() {
    return "Failed Content"
  }

  getItemNames() {
    return "Failed Content"
  }

  getItemId() {
    return this.type+' - '+this.item.acquiredstamp
  }

  getLinkToList() {
    return "/content/failed_content/"+this.type
  }

  duplicateItem() {
    let newItem = new FailedContent()
    newItem.copy(this.item)
    this.item = newItem
    this.updateForm()
  }


}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router'
import { DataMenu } from './data/data-menu/data-menu'
import { DataSideMenuComponent } from './data/data-menu/side-menu.component'
import { LoginComponent } from './login/login.component'

const  appRoutes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  { path: '', component: DataMenu,
    data: {breadcrumb: { skip: true }},
    children: [
      {
        path: '',
        outlet: 'sidemenu',
        component: DataSideMenuComponent,
        data: {breadcrumb: { skip: true }},
      }
    ]
  }
]

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes,{ useHash: true, relativeLinkResolution: 'legacy' })
  ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }

import {v4 as uuidv4} from 'uuid'

export class BaseItem {

  public id: string
  public name: string

  constructor (
    body: any = null, type: any = null) {
      if (body)
        this.fromJson(body, type)
  }

  fromJson(body, type) {
    this.getMainParams(body, type)
  }

  toJson() {
    let res = {}
    this.setMainParams(res)
    console.log("READY TO stringify: ", res)
    return JSON.stringify(res)
  }

  getValues(body, extra) {
      this.id = body.id
      this.name = body.name
  }

  setValues(body) {
      body.id = this.id
      body.name = this.name
  }

  setVal(val, def='') {
    return val ? val : def
  }

  setBoolVal(val, def=false) {
    return val ? val : def
  }

  setIntVal(val, def=0) {
    return val ? val : def

    // I think sending this to Dynamo is unsafe
    // it can think they are Decimals when reading them back
    //return val ? Number(val) : def
  }

  getMainParams(body: any, extra: any = null) {
    this.getValues(body, extra)
  }

  setMainParams(res: any) {

  }

  copy(item: BaseItem) {
    this.id = uuidv4()
    this.name = item.name+" (copy)"
  }


  stringify() {
    return ""
  }


  static getNewItem(id='') {
    return new BaseItem()
  }
}

import { Component, OnInit } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { ScrapeBaseItemComponent } from '../scrape-base-item/scrape-base-item.component'
import { Result } from '../../model/result'

@Component({
  selector: 'app-result-item',
  templateUrl: '../../base-classes/abstract-base-item/abstract-base-item.component.html',
  styleUrls: ['../../styles/global.scss','../../base-classes/abstract-base-item/abstract-base-item.component.scss']
})
export class ResultItemComponent extends ScrapeBaseItemComponent {

  public sessionid: string
  public driverid: string
  public realDriverid: string
  public raceid: string

  setParams(params) {
    this.id = params['id']
    this.sessionid = params['sessionid']
    this.driverid = params['driverid']
    this.raceid = this.sessionid.substring(0, this.sessionid.lastIndexOf(':'))
  }

  getItem() {
    this.dataService.getSessionResult(this.id, this.sessionid, this.driverid)
    .subscribe(item => {
      //this.getDriverId(item.name)
      this.dataGetSuccess(item)
    })
  }

  getLinkToList() {
    return "/data/champ/"+this.id+"/race/"+this.raceid+"/session/"+this.sessionid+"/results"
  }

  getItemName() {
    return "Result"
  }

  getItemNames() {
    return "Results"
  }

  getItemId() {
    return this.id.toUpperCase()+' / '+this.driverid
  }

  getValidators() {
    return {}
  }

  getDisabledFields() {
    return ['id','sessionid','driverid','name']
  }

  disableField(field: string, ctrls: any) {
    if (field == 'id' || field == 'sessionid' || field == 'driverid' || field == 'name')
      ctrls[field].disable()
  }

  updateItem() {

    this.dataService.updateResult(this.id, this.sessionid, this.driverid, this.item.toJson())
    .subscribe(
      val => this.dataSuccess(val, 'PATCH'),
      err => this.dataError(err, 'PATCH'),
      () => {}
    )
  }

  getDriverId(name) {
    let yr = this.getYearFromSessionId(this.sessionid)
    this.dataService.getChampDrivers(this.id, yr)
    .subscribe(items => {
      for (const item of items)
        if (item.name == name) {
          this.realDriverid = item.id
        }
      console.log("NO driver id found: ", name)
    })

  }

  getYearFromSessionId(id) {
    let s = id.split(':')
    return s[2]
  }
}

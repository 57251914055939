declare var require: any
import { BaseItem } from './base-item'

export class Result extends BaseItem {

  public name: string
  public country_code: string
  public id: string
  public champid: string
  public time: string
  public fastestLapTime: string
  public gap: string
  public laps: string
  public pits: Number
  public position: Number
  public points: string
  public team: string
  public sessionid: string
  public status: string
  public api_id: string
  public grid: Number

  // constructor (
  //   public body: string) {
  //     //console.log("body = ", body)
  //     this.fromJson(body)
  // }

  fromJson(body) {
    this.getValues(body)
  }

  toJson() {
    return JSON.stringify(this.setValues())
  }

  getValues(body) {
      this.id = body.driver_id
      this.sessionid = body.session_id
      this.name = body.driver
      if (body.hasOwnProperty('country_code'))
        this.country_code = body.country_code
      if (body.hasOwnProperty('laps'))
        this.laps = body.laps
      if (body.hasOwnProperty('points'))
        this.points = body.points
      if (body.hasOwnProperty('pitstops'))
        this.pits = body.pitstops
      this.position = body.position
      this.grid = body.grid
      this.team = body.team
      if (body.hasOwnProperty('time'))
        this.time = body.time
      if (body.hasOwnProperty('fastest_lap_time'))
        this.fastestLapTime = body.fastest_lap_time
      if (body.hasOwnProperty('gap'))
        this.gap = body.gap
      this.status = body.status


  }

  setMainParams(body) {
    body.driver_id = this.id
    body.session_id = this.sessionid
    body.driver = this.name
    body.country_code = this.country_code
    body.laps = this.laps
    body.points = this.points
    body.pitstops = this.pits
    body.position = this.position
    body.grid = this.grid
    body.team = this.team
    body.time = this.time
    body.fastest_lap_time = this.fastestLapTime
    body.gap = this.gap
    body.status = this.status
    body.api_id = this.api_id
  }

  setValues() {
      let body = {
        "driver_id": this.id,
        "session_id": this.sessionid,
        "driver": this.name,
        "country_code":   this.country_code,
        "laps": this.laps,
        "points": this.points,
        "pitstops": this.pits,
        "position": this.position,
        "team": this.team,
        "time": this.time,
        "fastest_lap_time": this.fastestLapTime,
        "gap": this.gap,
        "grid": this.grid,
        "status": this.status
    }
    return body
  }


}

import { Component, OnInit, Input , Output, EventEmitter} from '@angular/core';
import { Gpname } from '../../../model/gpname'
import { BaseItemComponent } from '../base-item/base-item.component'
import { DataService } from '../../../services/data.service'
import { environment } from '../../../constants'
import { FormGroup, FormControl, FormsModule, Validators } from '@angular/forms'
import { AppUserAuth } from '../../../security/app-user-auth'
import { StringValidator } from '../base-item/string.validator'

@Component({
  selector: 'app-gpname-item',
  templateUrl: '../../../base-classes/abstract-base-item/abstract-base-item.component.html',
  styleUrls: ['../../../styles/global.scss','../../../base-classes/abstract-base-item/abstract-base-item.component.scss']
})
export class GpnameItemComponent extends BaseItemComponent {

  @Input() public pattern: string
  @Input() public id: string
  item: Gpname

  setParams(params) {
    this.pattern = params['pattern']
    this.id = params['id']
  }

  getItem() {
    this.dataService.getGpname(this.id, this.pattern)
    .subscribe(item => {
      console.log("ITEM = ", item)
      this.dataGetSuccess(item)
    })
  }

  disableField(field: string, ctrls: any) {
    if (field == 'pattern' || field == 'id')
      ctrls[field].disable()
  }


  updateItem() {
    let st = this.item
    let cat = st.toJson()
    this.dataService.updateGpname(st.id, cat)
    .subscribe(
      val => {
        this.dataSuccess(val, 'PUT')
      },
      err => this.dataError(err, 'PUT'),
      () => {}
    )
  }

  deleteItem() {
    let st = this.item
    this.dataService.deleteGpname(st.id, st.pattern)
    .subscribe(
      val => this.dataSuccess(val, 'DELETE'),
      err => this.dataError(err, 'DELETE'),
      () => {}
    )
  }

  getValidators() {
    let vals = {}
    vals["pattern"] = [Validators.required, StringValidator.cannotContainSpace]
    vals["id"] = [Validators.required, StringValidator.cannotContainSpace]
    return vals
  }

  getDisabledFields() {
    let list = ["id", "pattern"]
    return list
  }

  getItemName() {
    return this.id.toUpperCase()+" GP Name"
  }

  getItemNames() {
    return this.id.toUpperCase()+" GP Names"
  }

  getItemId() {
    return this.pattern
  }

  getLinkToList() {
    return "/data/champ/"+this.id+"/gpnames"
  }

  duplicateItem() {
    let newItem = new Gpname()
    newItem.copy(this.item)
    this.item = newItem
    this.updateForm()
  }


}

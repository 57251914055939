<div class="panel panel-primary">

  <h5 *ngIf="state === 'ready'" class="panel-title">{{ getItemName() }} Fields for {{ getItemId() }}</h5>
  <div id="special-area"></div>

  <form id="form-{{ id }}" [formGroup]="form" *ngIf="state === 'ready'" (ngSubmit)="save()" class="form-horizontal">
  <div class="panel-body">
    <div class="col-sm-12">
      <table >
        <tr *ngFor="let key of getKeys()" class="field form-group">
          <!-- <div *ngIf="!['articlePage', 'mainListing'].includes(key) && key !== 'type'"> -->
            <td class="label">
              <label class="control-label" for="{{ key }}">{{ capitalize(key) }}</label>
            </td>
            <td class="col-sm-6">
              <input *ngIf="isBool(key, this.form)" id="{{ key }}" formControlName="{{ key }}" type="checkbox" class="checkbox form-control">
              <textarea *ngIf="isText(key, this.form)" id="{{ controlId(key) }}" formControlName="{{ key }}" rows="4" class="text form-control"></textarea>
              <input *ngIf="isOther(key, this.form)" id="{{ controlId(key) }}" formControlName="{{ key }}" type="text"  class="text form-control">
              <div *ngIf="invalid(key)" class="alert alert-danger">
                   {{ validationError(key)}}
               </div>
            </td>
          <!-- </div> -->
        </tr>
        <tr *ngFor="let im of getImages()" class="field form-group">
          <td class="image-label">
            <div *ngIf="isFirstImage(im)">Main image</div>
            <div>
              Size = {{ im.size }}
            </div>
          </td>
          <td>
            <table>
              <tr>
                <td class="col-sm-6">
                  <img width="100%" src="{{ im.url }}"/>
                </td>
              </tr>
              <tr>
                <td class="caption">
                  {{ im.caption }}
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </div>
  </div>

  <div *ngIf="canEdit()" class="panel-footer">
    <button *ngIf="haveUpdateButton()" class="btn btn-primary">Submit changes above</button>
    <button (click)="goBack()" class="btn btn-primary">Cancel</button>
  </div>

</form>

</div>


import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router'
import { ContentListComponent } from './content-list/content-list.component'
import { SourceListComponent } from './source-list/source-list.component'
import { SourceItemComponent } from './source-item/source-item.component'
import { CategoryListComponent } from './category-list/category-list.component'
import { CategoryItemComponent } from './category-item/category-item.component'
import { ContentSourceListComponent } from './content-source-list/content-source-list.component'
import { ContentCategoryListComponent } from './content-category-list/content-category-list.component'
import { ContentItemComponent } from './content-item/content-item.component'
import { SideMenuComponent } from './content-menu/side-menu.component'
import { ContentMenu } from './content-menu/content-menu'
import { AuthGuard } from '../security/auth.guard'
import { FailedContentListComponent } from './failed-content-list/failed-content-list.component'
import { FailedContentItemComponent } from './failed-content-item/failed-content-item.component'

const  contentRoutes: Routes = [
  { path: '', component: ContentMenu},
  { path: 'content', component: ContentMenu,
  data: { breadcrumb: {alias: 'Content'} },
  children: [
      {
        path: '',
        outlet: 'sidemenu',
        component: SideMenuComponent
      },
      {
        path: 'failed_content',
        canActivate: [AuthGuard],
        data: {claimName: 'canAccessData'},
        component: FailedContentListComponent
      },
      {
        path: 'failed_content/:type',
        canActivate: [AuthGuard],
        data: {claimName: 'canAccessData'},
        component: FailedContentListComponent
      },
      {
        path: 'failed_content/:type/:reason_code',
        canActivate: [AuthGuard],
        data: {claimName: 'canAccessData'},
        component: FailedContentListComponent
      },
      {
        path: 'failed_content_item/:type/:acquiredstamp',
        canActivate: [AuthGuard],
        data: {claimName: 'canAccessData'},
        component: FailedContentItemComponent
      },
      {
        path: 'content-sources',
        canActivate: [AuthGuard],
        data: {claimName: 'canAccessData'},
        component: ContentSourceListComponent
      },
      {
        path: 'content-categories',
        canActivate: [AuthGuard],
        data: {claimName: 'canAccessData'},
        component: ContentCategoryListComponent
      },
      {
        path: 'sources',
        canActivate: [AuthGuard],
        data: {claimName: 'canAccessData'},
        component: SourceListComponent
      },
      {
        path: 'contents',
        canActivate: [AuthGuard],
        data: {claimName: 'canAccessData'},
        component: ContentListComponent
      },
      {
        path: 'content/:id',
        canActivate: [AuthGuard],
        data: {claimName: 'canAccessData'},
        component: ContentItemComponent
      },
      {
        path: 'source/:id',
        canActivate: [AuthGuard],
        data: {claimName: 'canAccessData'},
        component: SourceItemComponent
      },
      {
        path: 'categories',
        canActivate: [AuthGuard],
        data: {claimName: 'canAccessData'},
        component: CategoryListComponent
      },
      {
        path: 'categories/:id',
        canActivate: [AuthGuard],
        data: {claimName: 'canAccessData'},
        component: CategoryItemComponent
      }
  ]
},
 // { path: 'content-menu/content-sources', component: ContentSourceListComponent, outlet: 'maincontent' },
  // { path: 'content-menu/content-categories', component: ContentCategoryListComponent },
  // // { path: 'sources', component: SourceListComponent },
  // // { path: 'sources/:id', component: SourceListComponent },
  // { path: 'categories', component: CategoryListComponent },
  // { path: 'categories/:id', component: CategoryListComponent },
  // { path: 'content-menu', component: ContentMenu },
  //{ path: '', redirectTo: '/content-menu', pathMatch: 'full'}
]

@NgModule({
  imports: [
    RouterModule.forRoot(contentRoutes, { relativeLinkResolution: 'legacy' })
  ],
  exports: [ RouterModule ]
})
export class ContentRoutingModule { }

import { BaseItem } from './base-item'

export class Equiv extends BaseItem {

  public equiv: string
  public name: string

  getMainParams(body) {
      this.name = body.name
      this.equiv = body.equiv
  }

  setMainParams(body) {
      body.name = this.name
      body.equiv = this.equiv
  }

  copy(item: Equiv) {
    this.name = item.name+'copy'
    this.equiv = item.equiv
  }

  static getNewItem() {
    return new Equiv({"name": '', "equiv": ""})
  }
}

import { BaseItem } from './base-item'

export class ScrapeStat extends BaseItem {

  public champid: string
  public fields: any
  public type: string
  public acquiredstamp: string
  public raceid: string
  public sessionid: string
  public message: string
  public date: string
  public rule_trigger: string

  getMainParams(body) {
      this.champid = body.champ
      this.fields = body.fields
      this.raceid = body.raceid
      this.acquiredstamp = body.acquiredstamp
      this.sessionid = body.sessionid
      this.message = body.message
      this.type = body.type
      this.date = body.date
      this.rule_trigger = body.rule_trigger
  }

  setMainParams(body) {
      body.champ = this.champid
      body.raceid = this.raceid
      body.acquiredstamp = this.acquiredstamp
      body.message = this.message
      body.date = this.date
      body.type = this.type
      body.sessionid = this.sessionid
      body.fields = this.fields
      body.rule_trigger = this.rule_trigger
  }

  copy(item: ScrapeStat) {
    this.champid = item.champid
    this.fields = item.fields
    this.acquiredstamp = (parseFloat(item.acquiredstamp)+1).toString()
    this.message = item.message
    this.date = item.date
    this.type = item.type
    this.sessionid = item.sessionid
    this.raceid = item.raceid
    this.rule_trigger = item.rule_trigger
  }

  // static getNewItem(id) {
  //   return new LiveSession({"champid": id, "sessionName": "", "id": "", "timestamp": ""})
  // }
}

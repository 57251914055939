import { Component, OnInit } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { ScrapeBaseItemComponent } from '../scrape-base-item/scrape-base-item.component'
import { Team } from '../../model/team'

@Component({
  selector: 'app-team-item',
  templateUrl: '../../base-classes/abstract-base-item/abstract-base-item.component.html',
  styleUrls: ['../../styles/global.scss','../../base-classes/abstract-base-item/abstract-base-item.component.scss']
})
export class TeamItemComponent extends ScrapeBaseItemComponent {

  public teamid: string

  setParams(params) {
    this.id = params['id']
    this.teamid = params['teamid']
  }

  getItem() {
    this.dataService.getChampTeam(this.id, this.teamid, this.year)
    .subscribe(item => {
      this.dataGetSuccess(item)
      console.log(item)
    })
  }

  getLinkToList() {
    return "/data/champ/"+this.id+"/teams/"
  }

  getItemName() {
    return "Team"
  }

  getItemNames() {
    return "Teams"
  }

  getItemId() {
    return this.id.toUpperCase()+' / '+this.teamid
  }

  getValidators() {
    return {}
  }

  getDisabledFields() {
    return ['id','teamid']
  }

  disableField(field: string, ctrls: any) {
    if (field == 'id' || field == 'teamid')
      ctrls[field].disable()
  }

  updateItem() {
    this.dataService.updateTeam(this.id, this.teamid, this.year, this.item.toJson())
    .subscribe(
      val => this.dataSuccess(val, 'PATCH'),
      err => this.dataError(err, 'PATCH'),
      () => {}
    )
  }


}

import { Component, Input } from '@angular/core';
import { Champ } from '../../../model/champ'
import { BaseItemComponent } from '../base-item/base-item.component'
import { DataService } from '../../../services/data.service'
import { Validators } from '@angular/forms'
import { StringValidator } from '../base-item/string.validator'

@Component({
  selector: 'app-champ-item',
  templateUrl: '../../../base-classes/abstract-base-item/abstract-base-item.component.html',
  styleUrls: ['../../../styles/global.scss','../../../base-classes/abstract-base-item/abstract-base-item.component.scss']
})
export class ChampItemComponent extends BaseItemComponent {

  @Input() public id: string
  item: Champ
  isLiveSession = false
  year: number

  setTitle() {
    this.pageTitle = "Admin UI - "+this.getItemName()+" Fields: "+this.getItemId().toUpperCase()
  }

  setParams(params) {
    this.id = params['id']
    this.getYear()
  }
  
  getYear() {
    let y = localStorage.getItem("year")
    if (y !== undefined && y !== '0')
      this.year =Number(y)
 }

  getItem() {
    this.dataService.getChamp(this.id, this.year)
    .subscribe(item => {
      console.log("GOT item = ", item)

      this.getLiveSessions(item)
    })
  }

  disableField(field: string, ctrls: any) {
    if (['id','year',"name"].includes(field))
      ctrls[field].disable()
  }

  // addSpecialArea(divId) {
  //   let str = ""
  //   console.log("Div id = ", divId)
  //   let div = document.getElementById(divId)
  //   if (!div)
  //     return
  //   let content  = "<hr>"
  //   content += (this.isLiveSession) ? '<i class="fa fa-bolt"></i>' : '<i class="fa fa-bell-slash"></i>'
  //   content += '<hr>'
  //
  //   div.innerHTML = content
  // }

  updateItem() {
    let st = this.item
    let cat = st.toJson()
    this.dataService.updateChamp(cat)
    .subscribe(
      val => {
        this.dataSuccess(val, 'PUT')
      },
      err => this.dataError(err, 'PUT'),
      () => {}
    )
  }

  deleteItem() {
    let st = this.item
    this.dataService.deleteChamp(st.id)
    .subscribe(
      val => this.dataSuccess(val, 'DELETE'),
      err => this.dataError(err, 'DELETE'),
      () => {}
    )
  }

  haveAddFields() {
    return true
  }

  getValidators() {
    let vals = {}
    vals["id"] = [Validators.required, StringValidator.cannotContainSpace]
    vals["name"] = [Validators.required]
    vals["race_name"] = [Validators.required]
    vals["last_session"] = [Validators.required, StringValidator.cannotContainSpace]
    vals["sourceid"] = [Validators.required, StringValidator.cannotContainSpace]
    return vals
  }

  getDisabledFields() {
    let list = ["id","year","name"]
    return list
  }

  getItemName() {
    return "Champ"
  }

  getItemNames() {
    return "Champs"
  }

  getItemId() {
    return this.id
  }

  getLinkToList() {
    return "/data/champs/"
  }

  duplicateItem() {
    let newItem = new Champ()
    newItem.copy(this.item)
    this.item = newItem
    this.updateForm()
  }

  getScrapeStatus(item) {
    this.dataService.getScrapeStatsForChamp(this.id)
        .subscribe(scrapeItems =>
          this.setScrapeStatus(scrapeItems.filter((a) => a.champid == this.id), item))
  }

  setScrapeStatus(scrapeItems, item) {
    let scrapeStatus = true
    if (scrapeItems && scrapeItems.length > 0) {
      let latestInfo = scrapeItems[0]
      if ('type' in latestInfo)
        scrapeStatus = !latestInfo.type.toLowerCase().includes('failed')
    }
    item.scrapeStatus = scrapeStatus
    this.dataGetSuccess(item)
  }


  getLiveSessions(item) {
    this.dataService.getLiveSessions()
        .subscribe(liveItems => this.setLiveSession(liveItems, item))
  }


  setLiveSession(liveItems, item) {
    let isLiveSession = false
    if (liveItems && liveItems.length > 0) {
      console.log("Live items: ", liveItems)
      let latestInfo = liveItems[0]
      isLiveSession = !['Finished', 'Not started', 'Cancelled'].includes(latestInfo.status)
    }
    item.liveSession = isLiveSession
    this.isLiveSession = isLiveSession
    if (isLiveSession)
      this.getScrapeStatus(item)
    else
      this.dataGetSuccess(item)

  }

}

<div  class="panel panel-primary">

  <h5 class="panel-title">{{ getTitle() }} </h5>
  <div class="panel-body">
    <div class="col-sm-12">

      <div *ngIf="haveFilter()" >
        <form id="form-list-select">
          <table>
          <tr>
            <td *ngFor="let filter of getFilters()" class="small-option">
              <label class="filter">{{ filter.name }}: </label>
              <select class="filter-select" (change)="changeSelectFilter(filter.id, $event.target.value)">
                <option [selected]="isSelectedFilter(filter.id, 'all')" value="all">All</option>
                <option [selected]="isSelectedFilter(filter.id, selection.id)" value="{{ selection['id'] }}" *ngFor="let selection of getFilterOptions(filter)">{{ selection.name }}</option>
              </select>
            </td>
            <td *ngIf="haveDateFilter()" class="small-option">
              <label class="filter">Date from: </label>
              <input
                (click)="cal.toggle()"
                (ngModelChange)="runDateFilter($event)"
                class="date-input"
                placeholder="yyyy-mm-dd"
                name="dp"
                [(ngModel)]="model"
                ngbDatepicker
                #cal="ngbDatepicker"
              />
              <button class="btn btn-outline-secondary bi bi-calendar3" (click)="cal.toggle()" type="button"><i class="fa fa-calendar"></i></button>
          </td>
          <!-- <td *ngIf="haveDateFilter()">
            <button class="btn btn-outline-secondary" (click)="runDateFilter($event)"><i class="fa fa-arrow-right"></i></button>
          </td> -->
          <!-- <td *ngIf="haveLimitFilter() " class="small-option">
            <label>Max results: </label>
            <input id="limit"                   
            style="width: 5em"
            type="number" 
            name="limit" 
            [(ngModel)]="limit"
            min="1" max="100">
          </td>   -->
        </tr>
        </table>
      </form>
    </div>


      <div id="topdiv" >
        <button id="refresh" (click)="refresh()" class="btn flushright">
          <i class="fa fa-refresh"></i>
        </button>
        <table id="{{ getTableId() }}" class="items">
        </table>
        <p *ngIf="state == 'ready' && !haveItems()" class="status-message">
          No {{ getItemsName() }} found.
        </p>
        <p *ngIf="state !== 'ready'" class="loading-message">
          Loading {{ getItemsName() }}...
        </p>
      </div>

    </div>
  </div>

  <mat-paginator *ngIf="state == 'ready' && maxItems > numOnPage" 
    [length]="this.maxItems" 
    [pageSize]="this.numOnPage" 
    [pageIndex]="this.page"
    (page)="handlePageChange($event)"
    [showFirstLastButtons]="true"> 
  </mat-paginator>


  <div *ngIf="state === 'ready'">
    <button id="deleteAllButton" *ngIf="haveDeleteAllFeature()" (click)="askDeleteAllItems()" class="btn btn-primary">Delete all {{ getItemsName() }}</button>
    <button id="extraFeatureButton" *ngIf="haveExtraFeature()" (click)="askRunExtraFeature()" class="btn btn-primary">{{ extraFeatureName() }}</button>
  </div>

  <script>
    document.addEventListener("DOMContentLoaded", refresh);
  </script>
</div>

import { ContentStats } from './content-stats'
import { BaseItem } from './base-item'

export class Source extends BaseItem {

  public mainListing: any
  public articlePage: any
  public name: string
  public id: string
  public url: string
  public channelId: string
  public frequency: string
  public categories: string
  public type: string
  public active: boolean
  public stats: ContentStats


  fromJson(body, type) {
    body = JSON.parse(body)
    this.getMainParams(body)
    if (this.type == 'text') {
      if ('mainListing' in body) {   // taken from new DB
        this.mainListing = this.loadSectionFromStr(body.mainListing)
        this.mainListing['nm'] = 'mainListing'
        this.articlePage = this.loadSectionFromStr(body.articlePage)
        this.articlePage['nm'] = 'articlePage'
      }
      else {     // taken from old DB
        this.mainListing = this.getMainListing(body)
        this.articlePage = this.getArticlePage(body)
        if (this.mainListing.time_tag == 'inside')
          this.getTagCss(body, this.articlePage, 'inside_time')
      }
    }
  }

  toJson() {
    let res = {}
    this.setMainParams(res)
    if (this.type == 'text') {
      res['articlePage'] = this.articlePage //JSON.stringify(this.articlePage)
      res['mainListing'] = this.mainListing //JSON.stringify(this.mainListing)
      //res.articlePage = this.setArticlePage()
      // if (this.mainListing.time.tag == 'inside')
      //   this.articlePage.time = this.getTagCss(body, 'inside_time')
    }
    //console.log("before stringigy: ", res)
    return JSON.stringify(res)
  }

  loadSectionFromStr(str) {
    let res = {}
    for (const prop in str) {
      if (prop.includes('_css'))
        res[prop] = JSON.stringify(str[prop])
      else
        res[prop] = str[prop]
    }
    return res
  }

  hasLazyLoad() {
    if (this.type == 'video')
      return false
    return this.mainListing['lazy_load_list']  ||  this.articlePage['lazy_load_article']
  }

  getMainParams(body) {
      this.id = body.id
      this.type = body.type
      this.name = body.name
      this.frequency = body.frequency
      if (this.type == 'video')
        this.channelId = body.channel_id
      else
        this.url = body.url
      this.categories = JSON.stringify(body.categories)
      if (body.active == undefined)
        this.active = true
      else
        this.active = body.active
  }

  setMainParams(body) {
      body.id = this.id
      body.name = this.name
      body.url = this.url
      body.frequency = this.frequency
      body.channel_id = this.channelId
      body.categories = JSON.parse(this.categories)
      body.type = this.type
      body.active = this.active
  }

  getArticlePage(body) {
    let res = {}

    res['nm'] = 'articlePage'
    this.getTagCss(body, res, 'article_start')
    this.getTagCss(body, res, 'text_start')
    this.getTagCss(body, res, 'image_envelope')
    this.getTag(body, res, 'caption_tag')
    this.getTagCss(body, res, 'title')
    this.getTag(body, res, 'caption_attribute')
    this.getTag(body, res, 'image-attribute')
    this.getTag(body, res, 'paragraph_tag')
    this.getBoolTag(body, res, 'background_image')
    this.getBoolTag(body, res, 'lazy_load_article')
    this.getTag(body, res, 'wait_for_article')

    return res
  }

  setArticlePage(res) {
    let art = this.articlePage
    //console.log(art)
    res.article_start_tag = art.article_start_tag
    res.article_start_css = art.articleStart.css
    res.text_start_tag = art.textStart.tag
    res.text_start_css = art.textStart.css
    res.image_envelope_tag = art.imageEnvelope.tag
    res.image_envelope_css = art.imageEnvelope.css
    res.caption_tag = art.captionTag
    res.title_tag = art.title.tag
    res.title_css = art.title.css
    // res['captionAttribute'] = this.getTag(body, 'caption_attribute')
    // res['imageAttribute'] = this.getTag(body, 'image-attribute')
    // res['paragraphTag'] = this.getTag(body, 'paragraph_tag')
    // res['backgroundImage'] = this.getBool(body, 'background_image')
    // res['lazyLoad'] = this.getBool(body, 'lazy_load_article')
    // res['waitFor'] = this.getTag(body, 'wait_for_article')
  }

  getMainListing(body) {
    let res = {}

    res['nm'] = 'mainListing'
    this.getTagCss(body, res, 'main')
    this.getTagCss(body, res, 'main_start')
    this.getTagCss(body, res, 'time')
    this.getTag(body, res, 'time_tag_2')
    this.getTag(body, res, 'date_format')
    this.getTag(body, res, 'link_tag')
    this.getTag(body, res, 'link_attribute')
    this.getTag(body, res, 'time_attribute')
    this.getBoolTag(body, res, 'date_in_url')
    this.getBoolTag(body, res, 'rss')
    this.getBoolTag(body, res, 'lazy_load_list')
    this.getTag(body, res, 'wait_for_list')

    return res
  }

  getTagCss(body, res, tagPrefix) {
    if (!(tagPrefix+'_tag' in body)) {
      res[tagPrefix+'_tag'] = ''
      res[tagPrefix+'_css'] = '{}'
    }
    else {
      res[tagPrefix+'_tag'] = body[tagPrefix+'_tag']
      res[tagPrefix+'_css'] = JSON.stringify(body[tagPrefix+'_css'])
    }
  }

  getTag(body, res, tag) {
    if (tag in body)
      res[tag] = body[tag]
    else
      res[tag] = ''
  }

  getCss(body, tag) {
    if (tag in body)
      return JSON.stringify(body[tag])
    return '{}'
  }

  getBoolTag(body, res, tag, def=false) {
    if (tag in body)
      res[tag] = body[tag]
    else
      res[tag] = def
  }

}

import { Component, OnInit, Input } from '@angular/core';
import { environment } from '../../../constants'
import { Stage } from '../../../model/stage'
import { ItemListComponent } from '../item-list/item-list.component'
import { ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl, FormsModule, Validators } from '@angular/forms'
import { StringValidator } from '../base-item/string.validator'

declare var require: any

@Component({
  selector: 'app-stage-list',
  templateUrl: '../item-list/item-list.component.html',
  styleUrls: ['../../../styles/global.scss','../item-list/item-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StageListComponent extends ItemListComponent {

  public id: string

// OVERRIDDEN METHODS:

  setParams(params) {
    this.id = params.id
  }

  getLinkToEdit(item) {
    // console.log("Item = ", item)
    return "/data/champ/"+this.id+"/stages/"+item.abbrev+'/'+item.location
  }

  getItemsName() {
    return this.id.toUpperCase()+" Stages"
  }

  getItemName() {
    return this.id.toUpperCase()+" Stage"
  }

  getItemId(item) {
    return item.sort
  }

  addItemTHValues(tableid) {
    let str = ""
    let tab = document.getElementById(tableid)
    if (!tab)
      return
    let elem = this.addElement(tab, "thead", '')
    if (!elem)
      return
    while (elem.firstChild) {
      elem.removeChild(elem.lastChild)
    }
    // this.addElement(elem, 'th', 'Champ')
    // this.addElement(elem, 'th', 'Sort')
    this.addElement(elem, 'th', 'Abbrev')
    this.addElement(elem, 'th', 'Name')
    this.addElement(elem, 'th', 'Type')
    this.addElement(elem, 'th', 'Location')
    this.addElement(elem, 'th', 'Duration')
    this.addElement(elem, 'th', 'Live name')
    this.addElement(elem, 'th', 'Archive name')
    this.addElement(elem, 'th', 'Not Live')
    this.addElement(elem, 'th', 'Not Scrapeable')
    this.addElement(elem, 'th', 'Concurrent')
    this.addElement(elem, 'th', 'Order')
    this.addElement(elem, 'th', '')
  }


  addItemTDValues(item) {
    let elem = document.getElementById(this.getTableId())
    if (!elem)
      return
    let tr = this.addElement(elem, "tr", '')
    if (!tr)
      return
    // this.addElement(tr, 'td', item.id)
    tr.onclick = (event) => this.editItem(event, item)
    this.addElement(tr, 'td', item.abbrev)
    this.addElement(tr, 'td', item.name)
    this.addElement(tr, 'td', item.sessionType)
    this.addElement(tr, 'td', item.location)
    this.addElement(tr, 'td', item.duration)
    this.addElement(tr, 'td', item.liveName)
    this.addElement(tr, 'td', item.archiveName)
    // this.addBoolElement(tr, 'td', item.active, true, item, 'active')
    this.addBoolElement(tr, 'td', item.notLive, false)
    // btn.onclick = (event) => this.askDeleteItem(event, item)
    this.addBoolElement(tr, 'td', item.notScrapeable, false)
    // btn.onclick = (event) => this.askDeleteItem(event, item)
    this.addBoolElement(tr, 'td', item.concurrent, false)
    // btn.onclick = (event) => this.askDeleteItem(event, item)
    this.addElement(tr, 'td', item.order+'')
    let btn = this.addDeleteButtonIcon(tr, 'td', this.canEdit())
    btn.onclick = (event) => this.askDeleteItem(event, item)
  }

  getItems() {
    if (this.id === undefined)
      return
    document.body.style.cursor='wait';
    this.dataService.getStages(this.id)
        .subscribe(items => this.afterGetItems(items))
  }


  deleteItem(item) {
    document.body.style.cursor='wait';
    this.dataService.deleteStage(this.id, item.abbrev, item.location)
      .subscribe(res => this.afterSuccessfulDelete(res),
                 response => this.afterFailedDelete(response))
  }

  getValidators() {
    let vals = {}
    vals["location"] = [Validators.required, StringValidator.cannotContainSpace]
    vals["id"] = [Validators.required, StringValidator.cannotContainSpace]
    vals['abbrev'] = [Validators.required, StringValidator.cannotContainSpace]
    return vals
  }

  getDisabledFields() {
    let list = ["id"]
    return list
  }

  getNewItem() {
    return Stage.getNewItem(this.id)
  }

  createItem(form) {

    let item = new Stage()
    this.updateValues(item, form)
    let cat = item.toJson()
    this.dataService.updateStage(item.id, cat)
    .subscribe(
      val => this.dataSuccess(val, 'PUT'),
      err => this.dataError(err, 'PUT'),
      () => {}
    )
  }

  haveDeleteAllFeature() {
    return false
  }


  // updateValues(cat: Stage, form: any) {
  //   let keys = this.getFormKeys(form)
  //   for (const key of keys) {
  //     if (form.get(key) instanceof FormControl) {
  //        cat[key] = form.get(key).value
  //     }
  //   }
  // }


// END: OVERRIDDEN METHODS


}

<form [formGroup]="sourceForm" *ngIf="state === 'ready'" (ngSubmit)="save()" class="form-horizontal">
  <div class="panel panel-primary">

    <h4 class="panel-title">Source Fields</h4>

    <ul ngbNav #nav="ngbNav" class="nav-tabs">
      <li ngbNavItem>
        <a ngbNavLink>General Settings</a>
        <ng-template ngbNavContent>
          <div class="col-sm-10">
            <table>
              <tr *ngFor="let key of getGeneralKeys()" class="field form-group">
                <!-- <div *ngIf="!['articlePage', 'mainListing'].includes(key) && key !== 'type'"> -->
                  <td class="label">
                    <label class="control-label" for="{{ key }}">{{ key }}</label>
                  </td>
                  <td class="col-sm-6">
                    <input *ngIf="isBool(key, this.sourceForm)" id="{{ key }}" formControlName="{{ key }}" type="checkbox" class="checkbox form-control">
                    <input *ngIf="!isBool(key, this.sourceForm)" id="{{ key }}" formControlName="{{ key }}" type="text" class="text form-control">
                  </td>
                <!-- </div> -->
              </tr>
            </table>
          </div>
          <p>

              <form [formGroup]="fieldForms.get('general')" *ngIf="state === 'ready'" (ngSubmit)="addField('general')" class="form-horizontal">
                <label class="control-label">Add Field: </label>
                <select formControlName="type" name="type">
                  <option value="boolean">Boolean</option>
                  <option value="string">String</option>
                </select>
                <input formControlName="value" name="value" type="text" placeholder="Name of field">
                <button class="btn btn-primary">Add</button>
              </form>

        </ng-template>
      </li>
      <li ngbNavItem>
        <a ngbNavLink>Main Listing</a>
        <ng-template ngbNavContent>
          <div class="form-group">
            <div class="col-sm-10" formGroupName='mainListing'>
              <table>
                <tr  *ngFor="let key2 of getFormKeys(this.sourceForm.get('mainListing'))" class="form-group">
                  <td class="label">
                    <label class="control-label" for="{{ key2 }}">{{ key2 }}</label>
                  </td>
                  <td class="col-sm-6">
                    <input *ngIf="isBool(key2, this.sourceForm.get('mainListing'))" id="{{ key2 }}" formControlName="{{ key2 }}" type="checkbox" class="checkbox form-control">
                    <input *ngIf="!isBool(key2, this.sourceForm.get('mainListing'))" id="{{ key2 }}" formControlName="{{ key2 }}" type="text" class="text form-control">
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <p>

              <form [formGroup]="fieldForms.get('mainListing')" *ngIf="state === 'ready'" (ngSubmit)="addField('mainListing')" class="form-horizontal">
                <label class="control-label">Add Field: </label>
                <select formControlName="type" name="type">
                  <option value="boolean">Boolean</option>
                  <option value="string">String</option>
                </select>
                <input formControlName="value" name="value" type="text" placeholder="Name of field">
                <button class="btn btn-primary">Add</button>
              </form>

        </ng-template>
      </li>
      <li ngbNavItem>
        <a ngbNavLink>Article Page</a>
        <ng-template ngbNavContent>
          <div class="form-group">
            <div class="col-sm-10" formGroupName='articlePage'>
              <table>
                <tr  *ngFor="let key2 of getFormKeys(this.sourceForm.get('articlePage'))" class="form-group">
                  <td class="label">
                    <label class="control-label" for="{{ key2 }}">{{ key2 }}</label>
                  </td>
                  <td class="col-sm-6">
                    <input *ngIf="isBool(key2, this.sourceForm.get('articlePage'))" id="{{ key2 }}" formControlName="{{ key2 }}" type="checkbox" class="checkbox form-control">
                    <input *ngIf="!isBool(key2, this.sourceForm.get('articlePage'))" id="{{ key2 }}" formControlName="{{ key2 }}" type="text" class="text form-control">
                  </td>
                </tr>
              </table>
            </div>
          </div>

          <p>

              <form [formGroup]="fieldForms.get('articlePage')" *ngIf="state === 'ready'" (ngSubmit)="addField('articlePage')" class="form-horizontal">
                <label class="control-label">Add Field: </label>
                <select formControlName="type" name="type">
                  <option value="boolean">Boolean</option>
                  <option value="string">String</option>
                </select>
                <input formControlName="value" name="value" type="text" placeholder="Name of field">
                <button class="btn btn-primary">Add</button>
              </form>

        </ng-template>
      </li>
    </ul>

<div [ngbNavOutlet]="nav"></div>
<!--
    <div class="panel-body">

      <h4 class="panel-title">General Settings</h4>

      <div class="col-sm-10">
        <table>
          <tr *ngFor="let key of getGeneralKeys()" class="field form-group">
              <td class="label">
                <label class="control-label" for="{{ key }}">{{ key }}</label>
              </td>
              <td class="col-sm-6">
                <input *ngIf="isBool(key, this.sourceForm)" id="{{ key }}" formControlName="{{ key }}" type="checkbox" class="checkbox form-control">
                <input *ngIf="!isBool(key, this.sourceForm)" id="{{ key }}" formControlName="{{ key }}" type="text" class="text form-control">
              </td>
          </tr>
        </table>
      </div>

      <div *ngIf="this.source.type == 'text'">
        <h4 class="panel-title">Main Listing</h4>
        <div class="form-group">
          <div class="col-sm-10" formGroupName='mainListing'>
            <table>
              <tr  *ngFor="let key2 of getFormKeys(this.sourceForm.get('mainListing'))" class="form-group">
                <td class="label">
                  <label class="control-label" for="{{ key2 }}">{{ key2 }}</label>
                </td>
                <td class="col-sm-6">
                  <input *ngIf="isBool(key2, this.sourceForm.get('mainListing'))" id="{{ key2 }}" formControlName="{{ key2 }}" type="checkbox" class="checkbox form-control">
                  <input *ngIf="!isBool(key2, this.sourceForm.get('mainListing'))" id="{{ key2 }}" formControlName="{{ key2 }}" type="text" class="text form-control">
                </td>
              </tr>
            </table>
          </div>
        </div>

        <h4 class="panel-title">Article Page</h4>
        <div class="form-group">
          <div class="col-sm-10" formGroupName='articlePage'>
            <table>
              <tr  *ngFor="let key2 of getFormKeys(this.sourceForm.get('articlePage'))" class="form-group">
                <td class="label">
                  <label class="control-label" for="{{ key2 }}">{{ key2 }}</label>
                </td>
                <td class="col-sm-6">
                  <input *ngIf="isBool(key2, this.sourceForm.get('articlePage'))" id="{{ key2 }}" formControlName="{{ key2 }}" type="checkbox" class="checkbox form-control">
                  <input *ngIf="!isBool(key2, this.sourceForm.get('articlePage'))" id="{{ key2 }}" formControlName="{{ key2 }}" type="text" class="text form-control">
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>

    </div> -->

    <div class="panel-footer">
      <button class="btn btn-primary">Submit Changes above</button>
    </div>
  </div>
</form>

<!-- <p>

  <h4 class="panel-title">Add Field to this source section</h4>


    <form [formGroup]="fieldForm" *ngIf="state === 'ready'" (ngSubmit)="addField()" class="form-horizontal">
      <label class="control-label">Add Field: </label>
      <select formControlName="section" name="section">
        <option value="general">General</option>
        <option value="mainListing">Main Listing</option>
        <option value="articlePage">Article Page</option>
      </select>
      <select formControlName="type" name="type">
        <option value="boolean">Boolean</option>
        <option value="string">String</option>
      </select>
      <input formControlName="value" name="value" type="text" placeholder="Name of field">
      <button class="btn btn-primary">Add</button>
    </form> -->

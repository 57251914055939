import { Component } from '@angular/core';
import { AbstractItemListComponent } from '../../base-classes/abstract-item-list/abstract-item-list.component'
import { ViewEncapsulation } from '@angular/core';


import { Category } from '../../model/category';
import { ContentStats } from '../../model/content-stats'

@Component({
  selector: 'app-content-category-list',
  templateUrl: '../../base-classes/abstract-item-list/abstract-item-list.component.html',
  styleUrls: ['../../styles/global.scss','../../base-classes/abstract-item-list/abstract-item-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContentCategoryListComponent extends AbstractItemListComponent {

  stats: ContentStats[]

  getTableId() {
    return 'table-category-stats'
  }

  setTitle() {
    this.pageTitle += "Content stats for Categories"
  }

  getLinkToEdit(item) {
    // console.log("Item = ", item)
    let ln = "/content/categories/"+this.getItemId(item)
    return ln
  }

  haveScrapeItemsFeature() {
    return false
   }
 
  getItemsName() {
    return "Category stats"
  }

  getItemName() {
    return "Category"
  }

  getItemId(item) {
    return item.id
  }

  getItems() {
    document.body.style.cursor='wait';
    this.dataService.getCategories()
        .subscribe(items => {
          this.items = items
          // console.log("Items", items)
          this.getStats()
        })
  }

  addItemTHValues(tableid) {
    let str = ""
    let tab = document.getElementById(tableid)
    if (!tab) {
      console.log("No elem", tableid)
      return
    }
    let elem = this.addElement(tab, "thead", '', 'stats-row')
    if (!elem)
      return
    while (elem.firstChild) {
      elem.removeChild(elem.lastChild)
    }
    this.addElement(elem, 'th', 'id')
    this.addElement(elem, 'th', 'category')
    this.addElement(elem, 'th', 'total')
    this.addElement(elem, 'th', 'last content')
  }


  addItemTDValues(item) {
    let elem = document.getElementById(this.getTableId())
    if (!elem) {
      console.log("No elem", this.getTableId())
      return
    }
    let tr = this.addElement(elem, "tr", '')
    if (!tr) 
      return
    // this.addElement(tr, 'td', this.getItemId(item))
    if (this.getItemId(item))
      tr.onclick = (event) => this.editItem(event, item)
    this.addElement(tr, 'td', item.id)
    this.addElement(tr, 'td', item.name)
    this.addElement(tr, 'td', this.getTotalFromStats(item.stats))
    this.addElement(tr, 'td', this.getHumanDate(item.stats.lastDate))
  }

  getStats() {
    this.dataService.getContentStatsForCategories(this.dateFrom)
      .subscribe(items => {
        this.stats = items
        // console.log("Stats ", items)
        this.setStatsForCategories()
        this.afterGetItems(this.items)
    })
  }

  setStatsForCategories() {
    // console.log("Setting stats")
    // console.log(this.stats)
    for (const item of this.items) {
      let s = item as Category
      for (const stat of this.stats) {
        if (s.id == stat.category)
          s.stats = stat
      }
    }
  }


  getTotalForCategory(category) {
    if (category.stats)
      return category.stats.total
    return '-'
  }

  haveDeleteAllFeature() {
    return false
  }

  haveFilter() {
    return true
  }

  haveDateFilter(): boolean {
    return true
  }

  getTotalFromStats(stats) {
    if (stats)
      return (stats.total) > 0 ? stats.total : '-' 
    return '-'
  }

}

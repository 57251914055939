declare var require: any

export class Session {

  public description: string
  public stage: string
  public sessionid: string
  public scheduled: string
  public scheduled_end: string
  public status: string
  public laps_completed: string
  public laps: string
  public time_left: string
  public added_time: string
  public date: string
  public time_start: string
  public time_end: string
  public country: string
  public scrape_status: string
  public race_id: string
  public url: string
  public results_url: string
  public meta_info: {}
  public met: string
  public api_id: string
  public race_api_id: string
  public race_description: string

  constructor (
    body: string) {
      // console.log("body = ", body)
      this.fromJson(body)
  }

  fromJson(body) {
    this.getSession(body)
    this.getMeta(body)
    this.getTimes()
  }

  // toJson() {
  //   let res = this.setCategory()
  //   return JSON.stringify(res)
  // }

  getTimes() {
    if (this.scheduled == 'TBC') {
      this.date = 'TBC'
      this.time_start = 'TBC'
      this.time_end = 'TBC'
    }
    else {
      let dt = Date.parse(this.scheduled)
      let d = new Date(dt)
      this.date = this.addZero(d.getDate())+'/'+this.addZero(d.getMonth()+1)
      this.time_start = this.addZero(d.getHours())+':'+this.addZero(d.getMinutes())
      dt = Date.parse(this.scheduled_end)
      d = new Date(dt)
      this.time_end = this.addZero(d.getHours())+':'+this.addZero(d.getMinutes())
    }
  }

  addZero(val: number) {
    return (val > 9) ? val+'' : '0'+val
  }

  getMeta(body) {
    if (body.meta_info) {
      this.meta_info  = body.meta_info
      this.met = JSON.stringify(this.meta_info)
      //this.meta = JSON.parse(this.meta_info)
    }
  }


  getSession(body) {
      this.sessionid = body.session_id
      this.description = body.description
      this.stage = body.stage
      this.api_id = body.api_id
      this.scheduled = body.scheduled
      this.scheduled_end = body.scheduled_end
      this.status = body.status
      this.laps_completed = body.laps_completed
      this.laps = body.laps
      this.time_left = body.time_left
      this.added_time = body.added_time
      this.country = body.country
      this.scrape_status = body.scrape_status
      this.race_id = body.race_id
      this.url = body.url
      this.results_url = body.results_url
      this.race_api_id = body.race_api_id
      this.race_description = body.race_description
  }

  stringify() {
    let saveItem = this
    if (saveItem.met) {
      saveItem.meta_info = JSON.parse(saveItem.met)
      delete saveItem.met
    }
    return JSON.stringify(saveItem)
  }

}

import { BaseItem } from './base-item'

export class Country extends BaseItem {

  public countryCode: string
  public name: string
  public offset: string
  public tag: string
  public timezone: number

  getMainParams(body) {
      this.countryCode = body.country_code
      this.name = body.country_name
      this.offset = body.offset
      this.tag = body.tag
      this.timezone = body.timezone
  }

  setMainParams(body) {
      body.country_code = this.countryCode
      body.country_name = this.name
      body.offset = this.offset
      body.tag = this.tag
      body.timezone = this.timezone
  }

  copy(item: Country) {
    this.countryCode = item.countryCode+'-copy'
    this.name = item.name
    this.offset = item.offset
    this.tag = item.tag
    this.timezone = item.timezone
  }

  static getNewItem() {
    return new Country({"country_code": '', "country_name": ""})
  }
}

import { Component } from '@angular/core';
import { Champ } from '../../../model/champ'
import { ItemListComponent } from '../item-list/item-list.component'
import { ViewEncapsulation } from '@angular/core';
import { FormControl, Validators } from '@angular/forms'
import { StringValidator } from '../base-item/string.validator'

declare var require: any

@Component({
  selector: 'app-champ-list',
  templateUrl: '../item-list/item-list.component.html',
  styleUrls: ['../../../styles/global.scss','../item-list/item-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChampListComponent extends ItemListComponent {

  year: number

  getYear() {
    let y = localStorage.getItem("year")
    if (y !== undefined && y !== '0')
      this.year =Number(y)
  }

// OVERRIDDEN METHODS:


  setTitle() {
    this.pageTitle += "Champ list"
  }

  getLinkToEdit(item) {
    // console.log("Item = ", item)
    return "/data/champ/"+this.getItemId(item)
  }

  getItemsName() {
    return "Champs"
  }

  getItemName() {
    return "Champ"
  }

  getItemId(item) {
    return item.id
  }

  addItemTHValues(tableid) {
    let str = ""
    let tab = document.getElementById(tableid)
    if (!tab)
      return
    let elem = this.addElement(tab, "thead", '')
    if (!elem)
      return
    while (elem.firstChild) {
      elem.removeChild(elem.lastChild)
    }
    this.addElement(elem, 'th', 'Id')
    this.addElement(elem, 'th', 'Name')
    this.addElement(elem, 'th', 'Year')
    this.addElement(elem, 'th', 'Race Name')
    this.addElement(elem, 'th', 'Last Session')
    this.addElement(elem, 'th', 'Source Id')
    this.addElement(elem, 'th', 'Active')
    this.addElement(elem, 'th', 'Notify')
    this.addElement(elem, 'th', 'No Teams')
    this.addElement(elem, 'th', '')
  }


  addItemTDValues(item) {
    let elem = document.getElementById(this.getTableId())
    if (!elem)
      return
    let tr = this.addElement(elem, "tr", '')
    if (!tr)
      return
    this.addElement(tr, 'td', this.getItemId(item))
    tr.onclick = (event) => this.editItem(event, item)
    this.addElement(tr, 'td', item.name)
    this.addElement(tr, 'td', item.year)
    this.addElement(tr, 'td', item.raceName)
    this.addElement(tr, 'td', item.lastSession)
    this.addElement(tr, 'td', item.sourceid)
    this.addBoolElement(tr, 'td', item.active, true, item, 'active')
    this.addBoolElement(tr, 'td', item.notify, true, item, 'notify')
    this.addBoolElement(tr, 'td', item.noTeamStandings, true, item, 'noTeamStandings')

    let btn = this.addDeleteButtonIcon(tr, 'td', this.canEdit())
    btn.onclick = (event) => this.askDeleteItem(event, item)
  }

  getItems() {
    this.getYear()
    document.body.style.cursor='wait';
    this.dataService.getChamps(this.year)
        .subscribe(items => this.afterGetItems(items))
  }


  deleteItem(item) {
    document.body.style.cursor='wait';
    this.dataService.deleteChamp(this.getItemId(item))
      .subscribe(res => this.afterSuccessfulDelete(res),
                 response => this.afterFailedDelete(response))
  }

  getValidators() {
    let vals = {}
    vals["id"] = [Validators.required, StringValidator.cannotContainSpace]
    vals["name"] = [Validators.required]
    vals["sourceid"] = [Validators.required, StringValidator.cannotContainSpace]
    vals["lastSession"] = [Validators.required, StringValidator.cannotContainSpace]
    vals["raceName"] = [Validators.required]
    return vals
  }

  getDisabledFields() {
    let list = []
    return list
  }

  getNewItem() {
    return Champ.getNewItem()
  }

  createItem(form) {

    let item = new Champ()
    this.updateValues(item, form)
    let cat = item.toJson()
    this.dataService.updateChamp(cat)
    .subscribe(
      val => this.dataSuccess(val, 'PUT'),
      err => this.dataError(err, 'PUT'),
      () => {}
    )
  }

  updateItem(item: Champ) {
    let st = item
    let cat = st.toJson()
    console.log("UPDATING: ", cat)
    this.dataService.updateChamp(cat)
    .subscribe(
      val => {
        this.dataSuccess(val, 'PUT')
      },
      err => this.dataError(err, 'PUT'),
      () => {}
    )
  }

  haveDeleteAllFeature() {
    return false
  }


  // CAN WE LEAVE THIS IN BASE CLASS ONLY?
  // updateValues(cat: Champ, form: any) {
  //   let keys = this.getFormKeys(form)
  //   for (const key of keys) {
  //     if (form.get(key) instanceof FormControl) {
  //        cat[key] = form.get(key).value
  //     }
  //   }
  // }


// END: OVERRIDDEN METHODS


}

import { Component, OnInit, Input } from '@angular/core';
import { environment } from '../../../constants'
import { City } from '../../../model/city'
import { ItemListComponent } from '../item-list/item-list.component'
import { ViewEncapsulation } from '@angular/core';
import { FormGroup, UntypedFormControl, FormsModule, Validators } from '@angular/forms'
import { StringValidator } from '../base-item/string.validator'

declare var require: any

@Component({
  selector: 'app-city-list',
  templateUrl: '../item-list/item-list.component.html',
  styleUrls: ['../../../styles/global.scss','../item-list/item-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CityListComponent extends ItemListComponent {


// OVERRIDDEN METHODS:

  getLinkToEdit(item) {
    // console.log("Item = ", item)
    return "/data/constants/cities/"+this.getItemId(item)
  }

  getItemsName() {
    return "Cities"
  }

  getItemName() {
    return "City"
  }

  getItemId(item) {
    return item.name
  }

  addItemTHValues(tableid) {
    let str = ""
    let tab = document.getElementById(tableid)
    if (!tab)
      return
    let elem = this.addElement(tab, "thead", '')
    if (!elem)
      return
    while (elem.firstChild) {
      elem.removeChild(elem.lastChild)
    }
    this.addElement(elem, 'th', 'Name')
    this.addElement(elem, 'th', 'Country Code')
    this.addElement(elem, 'th', 'Country Name')
    this.addElement(elem, 'th', 'Timezone')
    this.addElement(elem, 'th', '')
  }


  addItemTDValues(item) {
    let elem = document.getElementById(this.getTableId())
    if (!elem)
      return
    let tr = this.addElement(elem, "tr", '')
    if (!tr)
      return
    this.addElement(tr, 'td', this.getItemId(item))
    tr.onclick = (event) => this.editItem(event, item)
    this.addElement(tr, 'td', item.countryCode)
    this.addElement(tr, 'td', item.countryName)
    this.addElement(tr, 'td', item.timezone)
    let btn = this.addDeleteButtonIcon(tr, 'td', this.canEdit())
    btn.onclick = (event) => this.askDeleteItem(event, item)
  }

  getItems() {
    document.body.style.cursor='wait';
    this.dataService.getCities()
        .subscribe(items => this.afterGetItems(items))
  }


  deleteItem(item) {
    document.body.style.cursor='wait';
    this.dataService.deleteCity(this.getItemId(item))
      .subscribe(res => this.afterSuccessfulDelete(res),
                 response => this.afterFailedDelete(response))
  }

  getValidators() {
    let vals = {}
    vals["countryCode"] = [Validators.required, StringValidator.cannotContainSpace]
    vals["countryName"] = [Validators.required]
    vals["name"] = [Validators.required]
    return vals
  }

  getDisabledFields() {
    let list = []
    return list
  }

  getNewItem() {
    return City.getNewItem()
  }

  createItem(form) {

    let item = new City()
    this.updateValues(item, form)
    let cat = item.toJson()
    this.dataService.updateCity(cat)
    .subscribe(
      val => this.dataSuccess(val, 'PUT'),
      err => this.dataError(err, 'PUT'),
      () => {}
    )
  }

  // CAN WE LEAVE THIS IN BASE CLASS ONLY?
  updateValues(cat: City, form: any) {
    let keys = this.getFormKeys(form)
    for (const key of keys) {
      if (form.get(key) instanceof UntypedFormControl) {
         cat[key] = form.get(key).value
      }
    }
  }

  haveDeleteAllFeature() {
    return false
  }


// END: OVERRIDDEN METHODS


}

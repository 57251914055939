import { AppUserAuth } from "./app-user-auth";

// generate password on command line by
// > pwgen 17 1

export const LOGIN_MOCKS: AppUserAuth[] = [
  {
    userName: "frank",
    bearerToken: "abi393kdkd9393ikd",
    isAuthenticated: true,
    canAccessContent: true,
    canEditContent: true,
    canAccessData: true,
    canEditData: true,
  },
  {
    userName: "tim",
    bearerToken: "sd9f923k3kdmcjkhd",
    isAuthenticated: true,
    canAccessContent: true,
    canEditContent: false,
    canAccessData: true,
    canEditData: false,
  },
  {
    userName: "test",
    bearerToken: "eedeeZ1Moow1Ue5ch",
    isAuthenticated: true,
    canAccessContent: true,
    canEditContent: true,
    canAccessData: true,
    canEditData: true,
  },

];

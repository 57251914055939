import { BaseItem } from './base-item'

declare var require: any

export class Notification extends BaseItem {

  public topic: string
  public datestamp: number
  public activity_type: string
  public date: string
  public device: string
  public slug: string
  public stage_id: string
  public text: string
  public title: string
  public raceid: string

  getMainParams(body) {
    this.topic = body.topic
    this.datestamp = body.datestamp
    this.activity_type = body.activity_type
    this.date = body.date
    this.device = body.device
    this.slug = body.slug
    this.stage_id = body.stageId
    this.text = body.text
    this.title = body.title
    this.getRaceId()
  }

  setMainParams(body) {
    body.topic = this.topic
    body.datestamp = this.datestamp
    body.activity_type = this.activity_type
    body.date = this.date
    body.device = this.device
    body.slug = this.slug
    body.stageId = this.stage_id
    body.text = this.text
    body.title = this.title
  }

  copy(item: Notification) {
    this.topic = item.topic
    this.datestamp = item.datestamp
    this.activity_type = item.activity_type
    this.date = item.date
    this.device = item.device
    this.slug = item.slug
    this.stage_id = item.stage_id
    this.raceid = item.raceid
  }

  getRaceId() {
    let pieces = this.stage_id.split(':')
    this.raceid = pieces[0]+':'+pieces[1]+':'+pieces[2]
  }

}

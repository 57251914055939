import { Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { DataService } from '../../services/data.service';
import { Observable } from 'rxjs'
import { ActivatedRoute } from '@angular/router'
import { BaseItem } from '../../model/base-item'
import { AppUserAuth } from '../../security/app-user-auth'
import { SecurityService } from '../../security/security.service'
import { htmlEncode, htmlDecode } from 'js-htmlencode'
import { UntypedFormGroup, UntypedFormControl, FormsModule, Validators } from '@angular/forms'
import { Title } from "@angular/platform-browser"
import { Location } from '@angular/common'
import { NgbDateStruct, NgbCalendar, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap'

@Component({
  selector: 'app-abstract-item-list',
  templateUrl: './abstract-item-list.component.html',
  styleUrls: ['./abstract-item-list.component.scss']
})
export class AbstractItemListComponent implements OnInit {

  items: BaseItem[]
  item: BaseItem
  isProd: boolean
  pageTitle: string
  securityObject: AppUserAuth = null
  state: string
  form: UntypedFormGroup
  validators: any
  page: number = 1;
  maxPages: number
  maxItems: number
  numOnPage: number = 15
  numSelectFilters: number = 0
  selectFilters: {}[]
  selectedFilter: []
	date: { year: number; month: number };
  model: NgbDateStruct;
  public daysBack: number = 5
  dateFrom: string
  public limit:  number = 100

  constructor(protected dataService : DataService,
            protected cd: ChangeDetectorRef,
            protected route: ActivatedRoute,
            protected securityService: SecurityService,
            protected loc: Location,
            protected titleService: Title,
            private calendar: NgbCalendar) {

        this.securityObject = securityService.securityObject
        this.state = "pending"
        this.pageTitle = "Admin UI - "
        this.route.params.subscribe( params => {
          this.createSelectFilters()
          this.setParams(params)
          this.setTitle()
          this.updateTitle()
          this.updateFilterSelections()
          this.updateDateSelection()
          this.setDateFrom()
          this.refresh()
        })
  }

  setDateFrom() {
    let now = new Date()
    var dateOffset = (24*60*60*1000) //1 day
    if (!this.dateFrom) 
      now.setTime(now.getTime() - (dateOffset*this.daysBack));
    else
      now = new Date(this.dateFrom)
    this.model = {year: now.getFullYear(), month: now.getMonth()+1, day: now.getDate()}
    this.dateFrom = this.getDateFromModel(this.model)
  }

  getDateFromModel(model) {
    let m = (model.month < 10) ? '0'+model.month : model.month
    let d = (model.day < 10) ? '0'+model.day : model.day
    return this.model.year +'-'+m+'-'+d+'T00:00:00'
  
  }

  runDateFilter($event) {
    this.dateFrom = this.getDateFromModel($event)
    // console.log("Model ", $event, "date from ", this.dateFrom)
    localStorage.setItem('dateFrom', this.dateFrom)
    this.refresh()
  }

  daysDifference(d0, d1) {
    var diff = new Date(+d1).setHours(12) - new Date(+d0).setHours(12);
    return Math.round(diff/8.64e7);
  }

  getSelectFilterName(filterId) {

  }

  updateTitle() {
    this.titleService.setTitle(this.pageTitle)
  }

  setTitle() {
    this.pageTitle += "Item List"
  }

  ngOnInit() {
    this.isProd = localStorage.getItem('env') == 'prod'
//    console.log("Initing")
    this.refresh()
  }

  refresh() {
    console.log("refreshing")
    if (this.haveFilter() && this.haveSelectFilters()) 
      this.getFilterList(0)
    else
      this.getItems()
  }

   
  handlePageChange(event) {
    this.page = event.pageIndex;
    this.showItems()
  }

  anyInvalidFields() {
    for (let key of this.getKeys()) {
      if (this.form.get(key).invalid) {
        this.form.get(key).markAsTouched({ onlySelf: true });
        return true
      }
    }
    return false
  }

  getTableId() {
    return "table-"+this.getItemName().replace(/ /g,'').toLowerCase()
  }

  getKeys() {
    let keys = this.getFormKeys(this.form)
    let newKeys = []
    for (const k of keys) {
      if (k != 'body')
        newKeys.push(k)
    }
    return newKeys
  }

  getItemNames() {
    return this.items.map((cat) => {return cat.name})
  }

  isBool(key, group: UntypedFormGroup) {
    if (typeof group.value[key] == 'boolean')
      return true
    return false
  }

  askDeleteItem(event, item) {
    var result = confirm("Are you sure you want to delete this "+this.getItemName()+"?");
    if (result) {
      this.deleteItem(item)
    }
    event.stopPropagation()
    return false
  }

  askDeleteAllItems() {
    var result = confirm("Are you sure you want to delete all "+this.getItemsName()+"?");
    if (result) {
      this.deleteAllItems()
    }
    return false
  }

  followLink(event, url) {
    let a=document.createElement('a')
    a.target='_blank'
    a.href=url
    a.click()
    // document.location.href = url
    event.stopPropagation()
    return false
  }

  addIconElement(faId, faParent='far') {
    let icon = document.createElement('i')
    icon.classList.add(faParent)
    icon.classList.add(faId)
    return icon
  }

  addBoolElement(parent: any, elType: string, val: boolean, editAllowed: boolean = false, item: BaseItem = null, field: string = '') {
    let el = this.addElement(parent, elType, '')
    if (!editAllowed || !this.canEdit()) {
      if (val) {
        let icon = this.addIconElement('fa-check-circle')
        el.appendChild(icon)
      }
      return el
    }

    let label = document.createElement('label')
    label.classList.add('switch')
    let inp = document.createElement('input')
    inp.setAttribute('type', 'checkbox')
    inp.checked = val
    let sp = document.createElement('span')
    sp.classList.add('slider')
    sp.classList.add('round')
    label.appendChild(inp)
    label.appendChild(sp)
    el.appendChild(label)
    if (field) {
        el.onclick = (event) => this.toggleState(event, item, field)
    }
    return el

  }

  addDeleteButtonIcon(parent: any, elType: string, enabled: boolean) {
    let el = this.addElement(parent, elType, '')
    let btn = document.createElement('button')
    if (!enabled)
      btn.setAttribute('disabled', 'true')
    btn.classList.add('delbtn')
    btn.classList.add('btn')
    btn.appendChild(this.addIconElement('fa-trash-alt'))
    el.appendChild(btn)
    return el
  }

  addLinkButtonIcon(parent: any, elType: string, enabled: boolean) {
    let el = this.addElement(parent, elType, '')
    let btn = document.createElement('button')
    if (!enabled)
      btn.setAttribute('disabled', 'true')
    btn.classList.add('btn')
    btn.appendChild(this.addIconElement("fa-external-link-alt","fas"))
    el.appendChild(btn)
    return el
  }

  invalid(key) {
    return this.form.get(key).touched && this.form.get(key).invalid
  }

  validationError(key) {
    let str = ""
    for (let err in this.form.get(key).errors) {
      if (str)
        str = ", "+str
      str += key+": "+err
    }
    return str
  }

  canEdit() {
    return this.securityObject.canEditData
  }

  capitalize(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  goBack(): void {
    this.loc.back()
  }

  dataSuccess(val, method) {
    console.log(method+" call successful value returned in body",
                val);
    this.refresh()
  }

  dataError(val, method) {
    console.log(method+" call in error", val);
    this.refresh()
  }

  editItem(event, item) {
    document.location.href = this.getLinkToEdit(item)
  }

  toggleState(event, item, field) {
    item[field] = !item[field]
    this.updateItem(item)
    event.stopPropagation()
    return false
  }

  delay(ms: number) {
      return new Promise( resolve => setTimeout(resolve, ms) );
  }

  getFormKeys(group: any) {
    let keys = Object.keys(group.controls)
    return keys
  }

  loadList() {
    document.location.href = this.getLinkToList()
  }

  updateFilterSelections() {
    for (let fil of this.getFilters()) {
      let sel = localStorage.getItem(fil['id'])
      if (sel) {
        fil['selected'] = sel
      }
    }
  }

  updateDateSelection() {
    let sel = localStorage.getItem('dateFrom')
    if (sel) {
      this.dateFrom = sel
    }

  }


  isSelectedFilter(filterId, optionId) {
    for (let fil of this.getFilters()) {
      if (fil['id'] == filterId) {
        return fil['selected'] == optionId
      }
    }
    return false
  }

  changeSelectFilter(filterId, val) {
    for (let fil of this.getFilters()) {
      if (fil['id'] == filterId) {
        fil['selected'] = val
        localStorage.setItem(fil['id'], val)
        this.refresh()
      }
    }
  }


  getFilters() {
    return (this.selectFilters) ? this.selectFilters : []
  }

  postGetFilterOptions(index, fil, items) {
    index++
    fil['options'] = items
    if (index >= this.selectFilters.length)
      this.getItems()
    else
      this.getFilterList(index)
  }

  haveSelectFilters() {
    return this.selectFilters.length > 0
  }

  getFilterOptions(filter) {
    return filter['options']
  }

  getFilter(filterId) {
    for (let fil of this.getFilters()) 
      if (fil['id'] == filterId) 
        return fil
  }


  getFilterSelectedOption(filterId) {
    let fil = this.getFilter(filterId)
    return fil['selected']
  }
  ////////////////////////////////////////////////////////////////////////////////
  // NEED TO BE WRITTEN BY DERIVED CLASS

  createSelectFilters() {
    let fil = {
      'name': 'Filter1',
      'id': 'filter1',
      'selected': 'all',
      'options': []
    }
    this.selectFilters = []
    //this.selectFilters.push(fil)
  }

  getFilterList(index) {
    if (!this.haveSelectFilters())
      return


    // document.body.style.cursor='wait';
    // let fil = this.selectFilters[index]
    // if (fil['id'] == 'category') {
    //   this.dataService.getCategories()
    //       .subscribe(items => {
    //         console.log("got categories", items)
    //         this.postGetFilterOptions(index, fil, items)
    //       })
    // }
    // else if (fil['id'] == 'source') {
    //   this.dataService.getSources(this.type)
    //       .subscribe(items => {
    //         console.log("got sources", items)
    //         this.postGetFilterOptions(index, fil, items)
    //       })
      
  }

  setParams(params) {
    // this.id = params.id
  }

  deleteItem(item) {
    document.body.style.cursor='wait';
    // this.dataService.get<type>()
    //     .subscribe(items => this.afterGetItems(items))
  }

  getItems() {
    document.body.style.cursor='wait';
  }


  getItem() {
  }

  deleteAllItems() {

  }

  getLinkToEdit(item) {
    return "/data/items/"+item.id
  }

  getItemsName() {
    return "Items"
  }

  getItemName() {
    return "Item"
  }

  getTitle() {
    return this.getItemsName()
  }

  getIndexName() {
    return "Index"
  }

  getIndexesName() {
    return "Indexes"
  }

  getItemId(item) {
    return item.id
  }

  getValidators() {
    return {}
  }

  getDisabledFields() {
    return []
  }

  getLinkToList() {
    return "/data/items"
  }

  disableField(field: string, ctrls: any) {
    // if (field == 'sort' || field == 'id')
      ctrls[field].disable()
  }


  addItemTDValues(item) {
  }

  addItemTHValues(id: string) {
  }

  addNewItemTDValues() {
  }

  haveItems() {
    return this.items && this.items.length > 0
  }

  haveFilter() {
    return false
  }

  haveDateFilter() {
    return false
  }

  haveDeleteAllFeature() {
    return true
  }

  // END: NEED TO BE WRITTEN BY DERIVED CLASS
  ////////////////////////////////////////////////////////////////////////////////

  getHumanDate(dtStr) {
    let dt = dtStr.split('T')
    if (dt.length == 1)
      dt = dtStr.split(' ')
    return dt[0]
  }


  updateValues(cat: BaseItem, form: any) {
    let keys = this.getFormKeys(form)
    for (const key of keys) {
      if (form.get(key) instanceof UntypedFormControl) {
         cat[key] = form.get(key).value
      }
    }
    console.log("SAVING: ", cat)
  }


  afterGetItems(items) {
    this.items = items
    this.page = 0
    this.maxItems = items.length
    this.maxPages = items.length / this.numOnPage
    if (items.length % this.numOnPage > 0)
      this.maxPages += 1
    this.showItems()
    this.state = "ready"
    document.body.style.cursor='default';
  }

  showItems() {
    this.clearTableValues()
    this.addItemTHValues(this.getTableId())
    let start = this.page*this.numOnPage
    let num = this.numOnPage*(this.page+1)
    let theseItems = this.items.slice(start,num)
    for (let item of theseItems)
      this.addItemTDValues(item)
    //this.addNewItemTDValues()

  }

  afterSuccessfulDelete(res) {
    console.log("Delete item successful", res);
    this.refresh()
    document.body.style.cursor='default';
  }

  afterFailedDelete(res) {
    console.log("DELETE call in error", res);
    this.refresh()
    document.body.style.cursor='default';
  }

  clearTableValues() {
    let elem = document.getElementById(this.getTableId())
    if (!elem) 
      return
    while (elem.firstChild) {
      elem.removeChild(elem.lastChild)
    }
  }

  addElement(parent, name, val, id='') {
    let el = document.createElement(name)
    el.textContent = val
    if (id)
      el.id = id
    parent.appendChild(el)
    return el
  }

  updateItem(item) {
  }

  haveExtraFeature() {
    return false
  }

}

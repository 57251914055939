import { Component, OnInit, Input , Output, EventEmitter} from '@angular/core';
import { BaseItem } from '../../../model/base-item'
import { DataService } from '../../../services/data.service'
import { environment } from '../../../constants'
import { UntypedFormGroup, UntypedFormControl, FormsModule, Validators } from '@angular/forms'
// import { ActivatedRoute } from '@angular/router'
// import { AppUserAuth } from '../../../security/app-user-auth'
// import { SecurityService } from '../../../security/security.service'
// import { StringValidator } from './string.validator'
// import { Location } from '@angular/common'
// import {Title} from "@angular/platform-browser"
import { AbstractBaseItemComponent } from '../../../base-classes/abstract-base-item/abstract-base-item.component'

@Component({
  selector: 'app-base-item',
  templateUrl: '../../../base-classes/abstract-base-item/abstract-base-item.component.html',
  styleUrls: ['../../../styles/global.scss','../../../base-classes/abstract-base-item/abstract-base-item.component.scss']
})
export class BaseItemComponent extends AbstractBaseItemComponent {

  setTitle() {
    this.pageTitle += this.getItemName()+" Field"
  }

  dataGetSuccess(item) {
    this.item = item
    console.log("SUCCESS item = ", item)
    this.addSpecialArea('special-area')
    this.updateForm()
    this.createFieldForm()
    this.state = "ready"
  }

  updateForm() {
    console.log("UPDATING FORM", this.item)
    let validators = this.getValidators()
    let disabledFields = this.getDisabledFields()
    let ctrls = this.getControls(this.item, validators, disabledFields)
    this.form = new UntypedFormGroup(ctrls)
  }

  createFieldForm() {
    this.fieldForm = new UntypedFormGroup({
      type: new UntypedFormControl('string'),
      value: new UntypedFormControl(''),
   })
  }

  anyInvalidFields() {
    for (let key of this.getKeys()) {
      if (this.invalid(key))
        return true
    }
    return false
  }

  invalid(key) {
    return this.form.get(key).touched && this.form.get(key).invalid
  }

  getControls(fields, validators, disabled) {
    let ctrls = {}
    //console.log("Fields = ", fields)
    for (const field in fields) {
      let subField = fields[field]
      //console.log("Subfield = ", subField)
      if (subField === undefined || typeof subField === 'string' || typeof subField === 'boolean' || typeof subField === 'number') {

          if (field in validators)
            ctrls[field] = new UntypedFormControl(subField, validators[field])
          else
            ctrls[field] = new UntypedFormControl(subField)
          if (disabled.includes(field))
            this.disableField(field, ctrls)
      }
    }
    return ctrls
  }

  addField() {
    let type = this.fieldForm.value.type
    let val = this.fieldForm.value.value
    //console.log("Adding field: ", type, val)
    this.addToItem(type, val)
  }

  save(): void {
    console.log("Saving")
    if (this.anyInvalidFields()) {
      console.log("Invalid fields")
      return
    }
    this.updateValues(this.item, this.form)

    //return
    this.updateItem(this.item)
  }

  addToItem(type, value) {
    let cat = this.item
    if (type == 'string') {
      cat[value] = ''
    }
    else if (type == 'boolean') {
      cat[value] = false
    }
    this.updateForm()
  }


  // askDeleteItem() {
  //   var result = confirm("Are you sure you want to delete this "+this.getItemName()+"?");
  //   if (result) {
  //     this.deleteItem()
  //   }
  // }

  setValues(form: any) {
    let res = {}
    let keys = this.getFormKeys(form)
    //console.log("KEYS", keys)
    for (const key of keys) {
      if (form.get(key) instanceof UntypedFormControl) {
        res[key] = form.get(key).value
      }
    }
    return res
  }

  ////////////////////////////////////////////////////////////////////////////////
  // NEED TO BE WRITTEN BY DERIVED CLASS

  addSpecialArea(divId) {
  }

  setParams(params) {
    this.id = params['id']
  }


  duplicateItem() {
    let newItem = new BaseItem()
    newItem.copy(this.item)
    this.item = newItem
    this.updateForm()
  }

  // END: NEED TO BE WRITTEN BY DERIVED CLASS
  ////////////////////////////////////////////////////////////////////////////////

}

import { Component, OnInit, Input , Output, EventEmitter} from '@angular/core';
import { Country } from '../../../model/country'
import { BaseItemComponent } from '../base-item/base-item.component'
import { DataService } from '../../../services/data.service'
import { environment } from '../../../constants'
import { FormGroup, FormControl, FormsModule, Validators } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { AppUserAuth } from '../../../security/app-user-auth'
import { SecurityService } from '../../../security/security.service'
import { StringValidator } from '../base-item/string.validator'

@Component({
  selector: 'app-country-item',
  templateUrl: '../../../base-classes/abstract-base-item/abstract-base-item.component.html',
  styleUrls: ['../../../styles/global.scss','../../../base-classes/abstract-base-item/abstract-base-item.component.scss']
})
export class CountryItemComponent extends BaseItemComponent {

  @Input() public code: string
  item: Country

  setParams(params) {
    this.code = params['id']
  }

  getItem() {
    this.dataService.getCountry(this.code)
    .subscribe(item => {
      console.log("ITEM = ", item)
      this.dataGetSuccess(item)
    })
  }

  disableField(field: string, ctrls: any) {
    if (field == 'countryCode')
      ctrls[field].disable()
  }


  updateItem() {
    let st = this.item
    let cat = st.toJson()
    this.dataService.updateCountry(cat)
    .subscribe(
      val => {
        this.dataSuccess(val, 'PUT')
      },
      err => this.dataError(err, 'PUT'),
      () => {}
    )
  }

  deleteItem() {
    let st = this.item
    this.dataService.deleteCountry(st.countryCode)
    .subscribe(
      val => this.dataSuccess(val, 'DELETE'),
      err => this.dataError(err, 'DELETE'),
      () => {}
    )
  }

  getValidators() {
    let vals = {}
    vals["countryCode"] = [Validators.required, StringValidator.cannotContainSpace]
    vals["name"] = [Validators.required]
    return vals
  }

  getDisabledFields() {
    let list = ["countryCode"]
    return list
  }

  getItemName() {
    return "Country"
  }

  getItemNames() {
    return "Countries"
  }

  getItemId() {
    return this.code
  }

  getLinkToList() {
    return "/data/constants/countries/"
  }

  duplicateItem() {
    let newItem = new Country()
    newItem.copy(this.item)
    this.item = newItem
    this.updateForm()
  }
}

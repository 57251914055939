import { Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, FormsModule, Validators } from '@angular/forms'
import { AbstractItemListComponent } from '../../base-classes/abstract-item-list/abstract-item-list.component'

@Component({
  selector: 'app-scrape-item-list',
  templateUrl: './scrape-item-list.component.html',
  styleUrls: ['../../styles/global.scss', '../../base-classes/abstract-item-list/abstract-item-list.component.scss','./scrape-item-list.component.scss']
})
export class ScrapeItemListComponent extends AbstractItemListComponent {

  isQuiet: boolean = false
  withExtraInfo: boolean = false
  year: number
  validators: any
  maxNumToScrape: number
  numToScrape: number
  startScrapeIndex: number
  public id: string

  refresh() {
    this.getYear()
    super.refresh()
  }

  askScrapeItem(event, item) {
    var result = confirm("Are you sure you want to scrape?")
    if (result) {
      this.scrapeItem(item)
    }
    event.stopPropagation()
    return false
  }

  askScrapeItems() {
    var result = confirm("Are you sure you want to scrape?")
    if (result) {
      this.scrapeItems()
    }
    return false
  }

  askScrapeAllItems() {
    var result = confirm("Are you sure you want to scrape?")
    if (result) {
      this.scrapeAllItems()
    }
    return false
  }

  askRunExtraFeature() {
    var result = confirm("Are you sure you want to "+this.extraFeatureName()+"?");
    if (result) {
      this.runExtraFeature()
    }
    return false
  }


  listItemChildren(event, item) {
    document.location.href = this.getLinkToChildren(item)
    event.stopPropagation()
    return false
  }

  extraItemChildren(event, item) {
    document.location.href = this.getLinkToExtraItem(item)
    event.stopPropagation()
    return false
  }


  addChildrenButtonIcon(parent: any, elType: string, name: string) {
    let el = this.addElement(parent, elType, '')
    let btn = document.createElement('button')
    btn.setAttribute('content', name)
    btn.classList.add('btn')
    btn.appendChild(this.addIconElement(name))
    el.appendChild(btn)
    return el
  }

  addChildrenButton(parent: any, elType: string, name: string) {
    let el = this.addElement(parent, elType, '')
    let btn = document.createElement('button')
    btn.setAttribute('content', name)
    btn.textContent = name
    btn.classList.add('btn')
    el.appendChild(btn)
    return el
  }

  addScrapeButton(parent: any, elType: string, enabled: boolean) {
    let el = this.addElement(parent, elType, '')
    let btn = document.createElement('button')
    if (!enabled)
      btn.setAttribute('disabled', 'true')
    btn.setAttribute('content', "Scrape")
    btn.textContent = "Scrape"
    btn.classList.add('btn')
    el.appendChild(btn)
    return el
  }

  getYear() {
     let y = localStorage.getItem("year")
     if (y !== undefined && y !== '0')
       this.year =Number(y)
  }

  counter(from: number, to: number) {
      let a = new Array()
      for (let i = 0; i <= to-from; i++)
        a[i] = from+i
      return a
  }

  createScrapeForm() {
    if (!this.haveScrapeItemsFeature || this.form)
      return
    this.numToScrape = 1
    this.startScrapeIndex = 1
    this.form = new UntypedFormGroup({
      start: new UntypedFormControl(this.startScrapeIndex, [Validators.required, Validators.min(1), Validators.max(this.getMaxNumToScrape())]),
      num: new UntypedFormControl(this.numToScrape, Validators.required),
      quiet: new UntypedFormControl(this.isQuiet, Validators.required),
      extrainfo: new UntypedFormControl(this.withExtraInfo, Validators.required),
   })
   //this.num = this.numRounds
  }

  getScrapeValues() {
    let keys = this.getFormKeys(this.form)
    this.startScrapeIndex = this.form.get('start').value
    this.numToScrape = this.form.get('num').value
    this.isQuiet = this.form.get('quiet').value
    if (this.haveExtraInfo())
      this.withExtraInfo = this.form.get('extrainfo').value
  }


  beforeScrapeItems() {
    if (this.haveScrapeItemsFeature() && this.form) {
      this.form.disable()
      this.getScrapeValues()
    }
    if (this.haveScrapeAllFeature()) {
      let elem = document.getElementById("scrapeAllButton");
      (<HTMLInputElement>elem).disabled = true
    }
    document.body.style.cursor='wait'
  }

  afterScrapeError(err) {
    console.log("Scrape items: call in error", err);
    this.enableButtons()
    document.body.style.cursor='default';
  }

  enableButtons() {
    if (this.haveScrapeItemsFeature() && this.form)
      this.form.enable()
    if (this.haveScrapeAllFeature()) {
      let elem = document.getElementById("scrapeAllButton");
      (<HTMLInputElement>elem).disabled = false
    }
  }

  afterScrapeItemsSuccess(res) {
    console.log("Scrape "+this.getItemsName()+" successful:", res);
    this.refresh()
    this.enableButtons()
    document.body.style.cursor='default';
  }

  afterScrapeItemSuccess(res) {
    console.log("Scrape "+this.getItemName()+" successful:", res);
    this.refresh()
    this.enableButtons()
    document.body.style.cursor='default';
  }

  afterSuccessfulUpdate(res) {
    console.log("Update item successful", res);
    //this.refresh()
    document.body.style.cursor='default';
  }

  onStartIndexChanged(){
    if (this.maxNumToScrape > this.getMaxNumToScrape())
      this.maxNumToScrape = this.getMaxNumToScrape()
  }

  getMaxNumToScrape() {
    return this.items.length - this.startScrapeIndex + 1
  }

  getMaxStartScrapeIndex() {
    return this.items.length - 2
  }

  ////////////////////////////////////////////////////////////////////////////////
  // NEED TO BE WRITTEN BY DERIVED CLASS

  setTitle() {
    this.pageTitle += this.getItemsName()+" List"
  }

  scrapeItem(item) {
    document.body.style.cursor='wait';
  }

  haveExtraFeature() {
    return false
  }

  haveDeleteAllFeature() {
    return this.items.length > 0
  }

  haveExtraInfo() {
    return false
  }

  extraFeatureName() {
    return "Extra Feature"
  }


  runExtraFeature() {

  }

  scrapeItems() {
    this.beforeScrapeItems()
    // this.dataService.scrapeChampRaces(this.id, this.year, this.start, this.num, this.quiet, this.extrainfo)
    //   .subscribe(res => this.afterScrapeSuccess(res), err => this.afterScrapeError(err))
    this.afterScrapeItemsSuccess("ok")
  }

  scrapeAllItems() {
    this.beforeScrapeItems()
    // this.dataService.scrapeChampRaces(this.id, this.year, this.start, this.num, this.quiet, this.extrainfo)
    //   .subscribe(res => this.afterScrapeSuccess(res), err => this.afterScrapeError(err))
    this.afterScrapeItemsSuccess("ok")
  }

  getStartScrapeIndex() {
    return 1
  }

  getLinkToChildren(item) {
    return "#"
  }

  getLinkToExtraItem(item) {
    return "#"
  }

  getCollectionName() {
    return this.id.toUpperCase()
  }


  haveScrapeItemsFeature() {
    return true
  }

  haveScrapeAllFeature() {
    return false
  }

  // END: NEED TO BE WRITTEN BY DERIVED CLASS
  ////////////////////////////////////////////////////////////////////////////////

  // afterGetItems(items) {
  //   this.items = items
  //   if (this.haveScrapeItemsFeature())
  //     this.createScrapeForm()
  //   this.clearTableValues()
  //   this.addItemTHValues("table")
  //   for (let item of items) {
  //     this.addItemTDValues(item)
  //   }
  //   if (this.haveScrapeAllFeature())
  //     this.enableScrapeAllButton()
  //   this.state = "ready"
  //   document.body.style.cursor='default';
  // }

  showItems() {
    if (this.haveScrapeItemsFeature())
      this.createScrapeForm()
    super.showItems()
    if (this.haveScrapeAllFeature())
      this.enableScrapeAllButton()
  }

  enableScrapeAllButton() {
  }

}

import { Component, Input , Output, OnInit, EventEmitter } from '@angular/core'
import { Source } from '../../model/source'
import { DataService } from '../../services/data.service'
import { environment } from '../../constants'
import { ActivatedRoute } from '@angular/router'

import { UntypedFormGroup, UntypedFormControl, FormsModule, ReactiveFormsModule } from '@angular/forms'

@Component({
  selector: 'app-source-item',
  templateUrl: './source-item.component.html',
  styleUrls: ['../../styles/global.scss','../../styles/sources.scss','./source-item.component.scss']
})
export class SourceItemComponent implements OnInit   {

  sourceForm: UntypedFormGroup
  fieldForms: Map<string,any>
  state: string
  source: Source
  //@Input() public source: Source
  @Input() public id: string
  @Output() sourceUpdated: EventEmitter<Source> = new EventEmitter()

  constructor(private dataService: DataService,
              protected route: ActivatedRoute) {
    this.route.params.subscribe( params => {
      this.setParams(params)
      this.getSource()
    })

  }

  ngOnInit() {
    this.state = ""
    // this.getSource()
    // /console.log(this.source)
  }

  setParams(params) {
    this.id = params['id']
  }


  ngOnChanges(changes: any) {
    //console.log(changes)
    if (changes.source)
      this.updateSource()
  }

  getSource() {
    this.state = ''
    console.log("GETTING SOURCE", this.id)
    this.dataService.getSource(this.id)
        .subscribe(source => {
          this.source = source
          this.state = 'ready'
          console.log("Got source: ", source)
          this.updateSource()
          this.createFieldForms()
    })

  }
  updateSource() {

    let ctrls = this.getControls(this.source)
    this.sourceForm = new UntypedFormGroup(ctrls)
  }

  addToSource(type, value, sectionName) {
    let src = this.source
    if (sectionName !== 'general')
      src = src[sectionName]
    if (type == 'string') {
      src[value] = ''
    }
    else if (type == 'boolean') {
      src[value] = false
    }
    this.updateSource()
  }

  createFieldForms() {
    this.fieldForms = new Map()
    this.fieldForms.set('general', this.createFieldForm())
    this.fieldForms.set('mainListing', this.createFieldForm())
    this.fieldForms.set('articlePage', this.createFieldForm())
  }

  createFieldForm() {
    return new UntypedFormGroup({
      type: new UntypedFormControl('string'),
      value: new UntypedFormControl(''),
    })
  }

  getControls(fields) {
    let ctrls = {}
    for (const field in fields) {
      let subField = fields[field]
      if (typeof subField === 'string' || typeof subField === 'boolean' || subField === undefined) {
        if (field !== 'nm')
          ctrls[field] = new UntypedFormControl(subField)
      }
      else {
        let subCtrls = this.getControls(subField)
        ctrls[subField['nm']] = new UntypedFormGroup(subCtrls)
      }
    }
    return ctrls
  }

  capitalize(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  isFormGroup(obj: any) {
    return obj instanceof UntypedFormGroup
  }

  getFormKeys(group: any) {
    let keys = Object.keys(group.controls)
    return keys
  }

  getGeneralKeys() {
    let keys = this.getFormKeys(this.sourceForm)
    let newKeys = []
    for (const k of keys) {
      if (!['articlePage', 'mainListing'].includes(k) && k !== 'type') {
        if (!this.excludedFields(k, this.source.type))
          newKeys.push(k)
      }
    }
    return newKeys
  }

  excludedFields(key, type) {
    if (key == 'body')
      return true
    if (type == 'text') {
      if (key == 'channelId')
        return true
    }
    else {
      if (key == 'url')
        return true
    }
    return false
}

  getFormKeys2(group: UntypedFormGroup) {
    let keys = Object.keys(group.controls)
    return keys
  }

  isBool(key, group: UntypedFormGroup) {
    if (typeof group.value[key] == 'boolean')
      return true
    return false
  }

  addField(section) {
    let form = this.fieldForms.get(section)
    let type = form.value.type
    let val = form.value.value
    console.log("Adding field: ", section, type, val)
    this.addToSource(type, val, section)
  }

  save(): void {
    this.updateValues(this.source, this.sourceForm)
    if (this.source.type == 'text') {
      this.source['mainListing'] = this.setValues(this.sourceForm.get('mainListing'))
      this.source['articlePage'] = this.setValues(this.sourceForm.get('articlePage'))
    }
    let src = this.source.toJson()

    this.dataService.updateSource(src)

    setTimeout(() => {  this.refreshList() }, 1000);
    //setTimeout(this.refreshList, 1000)
  }

  refreshList() {
    this.sourceUpdated.emit(this.source)
  }

  updateValues(src: Source, form: any) {
    let keys = this.getFormKeys(form)
    for (const key of keys) {
      if (form.get(key) instanceof UntypedFormControl) {
        if (key.includes('_css'))
          src[key] = JSON.parse(form.get(key).value)
        else
          src[key] = form.get(key).value
      }
    }
  }

  setValues(form: any) {
    let res = {}
    let keys = this.getFormKeys(form)
    for (const key of keys) {
      if (form.get(key) instanceof UntypedFormControl) {
        if (key.includes('_css'))
          res[key] = JSON.parse(form.get(key).value)
        else
          res[key] = form.get(key).value
      }
    }
    return res
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { environment } from '../../constants'
import { Notification } from '../../model/notification'
import { ItemListComponent } from '../constants/item-list/item-list.component'
import { ViewEncapsulation } from '@angular/core';
import { FormGroup, UntypedFormControl, FormsModule, Validators } from '@angular/forms'
import { StringValidator } from '../constants/base-item/string.validator'

@Component({
  selector: 'app-notification-list',
  templateUrl: '../constants/item-list/item-list.component.html',
  styleUrls: ['../../styles/global.scss','../constants/item-list/item-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NotificationListComponent extends ItemListComponent {

  public topic: string


// OVERRIDDEN METHODS:

  setParams(params) {
    this.topic = params.topic
  }


  setTitle() {
    this.pageTitle += "Notification list"
  }

  getLinkToEdit(item) {
    // console.log("Item = ", item)
    return "/data/notification/"+this.topic+"/"+this.getItemId(item)
  }

  getItemsName() {
    return "Notifications"
  }

  getItemName() {
    return "Notification"
  }

  getItemId(item) {
    return item.datestamp
  }

  addItemTHValues(tableid) {
    let str = ""
    let tab = document.getElementById(tableid)
    if (!tab)
      return
    let elem = this.addElement(tab, "thead", '')
    if (!elem)
      return
    while (elem.firstChild) {
      elem.removeChild(elem.lastChild)
    }
    this.addElement(elem, 'th', 'Topic')
    this.addElement(elem, 'th', 'Date')
    this.addElement(elem, 'th', 'Activity')
    this.addElement(elem, 'th', 'Device')
    this.addElement(elem, 'th', 'Slug')
    this.addElement(elem, 'th', 'Stage')
    this.addElement(elem, 'th', 'Title')
  }

  addItemTDValues(item) {
    let elem = document.getElementById(this.getTableId())
    if (!elem)
      return
    let tr = this.addElement(elem, "tr", '')
    if (!tr)
      return
    this.addElement(tr, 'td', this.getItemId(item))
    tr.onclick = (event) => this.editItem(event, item)
    this.addElement(tr, 'td', item.topic)
    this.addElement(tr, 'td', item.date)
    this.addElement(tr, 'td', item.activity_type)
    this.addElement(tr, 'td', item.device)
    this.addElement(tr, 'td', item.slug)
    this.addElement(tr, 'td', item.stage_id)
    this.addElement(tr, 'td', item.title)
    let btn = this.addDeleteButtonIcon(tr, 'td', this.canEdit())
    btn.onclick = (event) => this.askDeleteItem(event, item)
  }

  // addNewItemTDValues() {
  //   let elem = document.getElementById("newtable")
  //   if (!elem)
  //     return
  //   let tr = this.addElement(elem, "tr", '')
  //   if (!tr)
  //     return
  //   let el = this.addElement(tr, 'td', '')
  //   let fld = document.createElement('input')
  //   el.appendChild(fld)
  //   el = this.addElement(tr, 'td', '')
  //   fld = document.createElement('input')
  //   el.appendChild(fld)
  // }

  getItems() {
    document.body.style.cursor='wait';
    this.dataService.getNotifications(this.topic)
        .subscribe(items => this.afterGetItems(items))
  }


  deleteItem(item) {
    document.body.style.cursor='wait';
    // this.dataService.deleteNotification(this.topic, this.getItemId(item))
    //   .subscribe(res => this.afterSuccessfulDelete(res),
    //              response => this.afterFailedDelete(response))
  }

  getValidators() {
    let vals = {}
    vals["topic"] = [Validators.required, StringValidator.cannotContainSpace]
    return vals
  }

  getDisabledFields() {
    let list = []
    return list
  }

  haveDeleteAllFeature() {
    return false
  }

// END: OVERRIDDEN METHODS


}

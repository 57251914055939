import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http'
import { DataRoutingModule } from './data-routing.module';

import { DataMenu } from './data-menu/data-menu'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { MatTableModule } from '@angular/material/table'
import { MatSortModule } from '@angular/material/sort';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { RaceSessionListComponent } from './race-session-list/race-session-list.component'
import { ChampRaceListComponent } from './champ-race-list/champ-race-list.component'
import { ChampScheduleItemComponent } from './champ-schedule-item/champ-schedule-item.component'
import { ChampScheduleListComponent } from './champ-schedule-list/champ-schedule-list.component'
import { DataSideMenuComponent } from './data-menu/side-menu.component';
import { ChampDriverListComponent } from './champ-driver-list/champ-driver-list.component';
import { ChampTeamListComponent } from './champ-team-list/champ-team-list.component';
import { SessionResultListComponent } from './session-result-list/session-result-list.component';
import { RaceItemComponent } from './race-item/race-item.component';
import { SessionItemComponent } from './session-item/session-item.component';
import { StageListComponent } from './constants/stage-list/stage-list.component'
import { StageItemComponent } from './constants/stage-item/stage-item.component';
import { BaseItemComponent } from './constants/base-item/base-item.component';
import { CountryItemComponent } from './constants/country-item/country-item.component';
import { ItemListComponent } from './constants/item-list/item-list.component';
import { ConstsListComponent } from './consts-list/consts-list.component';
import { CountryListComponent } from './constants/country-list/country-list.component';
import { CityListComponent } from './constants/city-list/city-list.component';
import { CityItemComponent } from './constants/city-item/city-item.component';
import { EquivItemComponent } from './constants/equiv-item/equiv-item.component';
import { EquivListComponent } from './constants/equiv-list/equiv-list.component';
import { GpnameListComponent } from './constants/gpname-list/gpname-list.component';
import { GpnameItemComponent } from './constants/gpname-item/gpname-item.component';
import { ChampListComponent } from './constants/champ-list/champ-list.component'
import { ChampItemComponent } from './constants/champ-item/champ-item.component';
import { ChampLivesessionListComponent } from './constants/champ-livesession-list/champ-livesession-list.component';
import { ChampLivesessionItemComponent } from './constants/champ-livesession-item/champ-livesession-item.component';
import { ScrapeStatsListComponent } from './constants/scrape-stats-list/scrape-stats-list.component';
import { ScrapeStatsItemComponent } from './constants/scrape-stats-item/scrape-stats-item.component';
import { ResultItemComponent } from './result-item/result-item.component';
import { ScrapeBaseItemComponent } from './scrape-base-item/scrape-base-item.component';
import { DriverItemComponent } from './driver-item/driver-item.component';
import { TeamItemComponent } from './team-item/team-item.component';
import { BreadcrumbModule } from "xng-breadcrumb"
import { BreadcrumbService } from 'xng-breadcrumb';
import { AbstractBaseItemComponent } from '../base-classes/abstract-base-item/abstract-base-item.component';
import { AbstractItemListComponent } from '../base-classes/abstract-item-list/abstract-item-list.component';
import { NotificationListComponent } from './notification-list/notification-list.component';
import { NotificationItemComponent } from './notification-item/notification-item.component';
import { ChampNotificationListComponent } from './champ-notification-list/champ-notification-list.component';
import { SessionNotificationListComponent } from './session-notification-list/session-notification-list.component';
import { RaceNotificationListComponent } from './race-notification-list/race-notification-list.component'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatDatepickerModule } from '@angular/material/datepicker';

@NgModule({
  declarations: [
    DataMenu,
    DataSideMenuComponent,
    ChampRaceListComponent,
    ChampScheduleListComponent,
    ChampScheduleItemComponent,
    RaceSessionListComponent,
    ChampDriverListComponent,
    ChampTeamListComponent,
    SessionResultListComponent,
    RaceItemComponent,
    SessionItemComponent,
    StageListComponent,
    StageItemComponent,
    BaseItemComponent,
    CountryItemComponent,
    ItemListComponent,
    ConstsListComponent,
    CountryListComponent,
    CityListComponent,
    CityItemComponent,
    EquivItemComponent,
    EquivListComponent,
    GpnameListComponent,
    GpnameItemComponent,
    ChampListComponent,
    ChampItemComponent,
    ChampLivesessionListComponent,
    ChampLivesessionItemComponent,
    ScrapeStatsListComponent,
    ScrapeStatsItemComponent,
    ResultItemComponent,
    ScrapeBaseItemComponent,
    DriverItemComponent,
    TeamItemComponent,
    AbstractBaseItemComponent,
    AbstractItemListComponent,
    NotificationListComponent,
    NotificationItemComponent,
    ChampNotificationListComponent,
    SessionNotificationListComponent,
    RaceNotificationListComponent,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    HttpClientModule,
    DataRoutingModule,
    BrowserAnimationsModule,
    MatTableModule,
    MatSortModule,
    NgbModule,
    BreadcrumbModule,
    MatPaginatorModule,
    MatDatepickerModule
  ],
  providers: [    BreadcrumbService],
  bootstrap: [DataMenu]
})
export class DataModule { }

import { Component, OnInit, Input } from '@angular/core';
import { LiveSession } from '../../../model/livesession'
import { ItemListComponent } from '../item-list/item-list.component'
import { ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms'
import { StringValidator } from '../base-item/string.validator'

declare var require: any

@Component({
  selector: 'app-champ-livesession-list',
  templateUrl: '../item-list/item-list.component.html',
  styleUrls: ['../../../styles/global.scss','../item-list/item-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChampLivesessionListComponent extends ItemListComponent {

  public champid: string

// OVERRIDDEN METHODS:

  setParams(params) {
    this.champid = params.id
  }

  getLinkToEdit(item) {
    // console.log("Item = ", item)
    return "/data/livesession/"+item.id
  }

  getItemsName() {
    return this.champid.toUpperCase()+" Live Sessions"
  }

  getItemName() {
    return this.champid.toUpperCase()+" Live Session"
  }

  getItemId(item) {
    return item.id
  }

  addItemTHValues(tableid) {
    let str = ""
    let tab = document.getElementById(tableid)
    if (!tab)
      return
    let elem = this.addElement(tab, "thead", '')
    if (!elem)
      return
    while (elem.firstChild) {
      elem.removeChild(elem.lastChild)
    }
    this.addElement(elem, 'th', 'Champ')
    this.addElement(elem, 'th', 'id')
    this.addElement(elem, 'th', 'Session stage')
    this.addElement(elem, 'th', 'Timestamp (s)')
    this.addElement(elem, 'th', 'Updated')
    this.addElement(elem, 'th', 'Status')
    this.addElement(elem, 'th', '')
  }


  addItemTDValues(item) {
    let elem = document.getElementById(this.getTableId())
    if (!elem)
      return
    let tr = this.addElement(elem, "tr", '')
    if (!tr)
      return
    this.addElement(tr, 'td', item.champid)
    tr.onclick = (event) => this.editItem(event, item)
    this.addElement(tr, 'td', item.id.slice(0,8)+'...')
    this.addElement(tr, 'td', item.sessionName)
    this.addElement(tr, 'td', item.timestamp.split('.')[0])
    this.addElement(tr, 'td', item.updated.split('.')[0])
    this.addElement(tr, 'td', item.status)
    let btn = this.addDeleteButtonIcon(tr, 'td', this.canEdit())
    btn.onclick = (event) => this.askDeleteItem(event, item)
    // console.log("Added items TD")
  }

  getItems() {
    if (this.champid === undefined)
      return
    document.body.style.cursor='wait';
    this.dataService.getLiveSessions()
        .subscribe(items => this.afterGetItems(items.filter((a) => a.champid == this.champid)))
  }


  deleteItem(item) {
    document.body.style.cursor='wait';
    this.dataService.deleteLiveSession(item.id)
      .subscribe(res => this.afterSuccessfulDelete(res),
                 response => this.afterFailedDelete(response))
  }

  getValidators() {
    let vals = {}
    vals["sessionName"] = [Validators.required, StringValidator.cannotContainSpace]
    vals["timestamp"] = [Validators.required, StringValidator.cannotContainSpace]
    vals["id"] = [Validators.required, StringValidator.cannotContainSpace]
    vals["updated"] = [Validators.required]
    return vals
  }

  getDisabledFields() {
    let list = ["champid"]
    return list
  }

  // getNewItem() {
  //   return LiveSession.getNewItem(this.champid)
  // }

  // createItem(form) {
  //
  //   let item = new LiveSession()
  //   this.updateValues(item, form)
  //   let cat = item.toJson()
  //   this.dataService.updateLiveSession(cat)
  //   .subscribe(
  //     val => this.dataSuccess(val, 'PUT'),
  //     err => this.dataError(err, 'PUT'),
  //     () => {}
  //   )
  // }
  //
  updateValues(cat: LiveSession, form: any) {
    let keys = this.getFormKeys(form)
    for (const key of keys) {
      if (form.get(key) instanceof UntypedFormControl) {
         cat[key] = form.get(key).value
      }
    }
  }

  haveCreateFeature() {
    return false
  }


// END: OVERRIDDEN METHODS


}

export const environment = {
//  apiUrlBase: "https://d9h6g2tp7d.execute-api.eu-west-1.amazonaws.com/prod/",
  prodContentApiUrlBase: "https://api.motormouth.club",
  testContentApiUrlBase: "https://api-test.motormouth.club",
  testDataApiUrlBase: "https://mmdata-test.motormouth.club",
  prodDataApiUrlBase: "https://mmdata.motormouth.club",
  testUtilityApiUrlBase: "https://mmutility-test.motormouth-data.co.uk",
  prodUtilityApiUrlBase: "https://mmutility.motormouth-data.co.uk",
  isProd: true
}

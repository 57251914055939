import { Component } from '@angular/core';
import { Session } from '../../model/session'
import { ViewEncapsulation } from '@angular/core';
import { ScrapeItemListComponent } from '../scrape-item-list/scrape-item-list.component'

@Component({
  selector: 'app-result-list',
  templateUrl: '../scrape-item-list/scrape-item-list.component.html',
  styleUrls: ['../../styles/global.scss','../scrape-item-list/scrape-item-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SessionResultListComponent extends ScrapeItemListComponent {

  public sessionid: string
  public id: string
  public raceid: string

  setParams(params) {
    this.id = params['id']
    this.sessionid = params['sessionid']
    let ind = this.sessionid.lastIndexOf(":")
    this.raceid = this.sessionid.substring(0,ind)
  }

  haveScrapeItemsFeature() {
   return false
  }

  haveScrapeAllFeature() {
    return true
  }

  haveDeleteAllFeature() {
    return true
  }

  getLinkToEdit(item) {
    // console.log("Item = ", item)
    return "/data/champ/"+this.id+"/race/"+this.raceid+"/session/"+this.getItemId()+"/result/"+item.id
  }

  getLinkToChildren(item) {
    // console.log("Item = ", item)
    return "/data/champ/"+this.id+"/race/"+this.raceid+"/session/"+this.getItemId()+"/results/"
  }

  getTitle() {
    return this.sessionid+" Results"
  }

  getItemsName() {
    return this.id.toUpperCase()+" Results"
  }

  getItemName() {
    return this.id.toUpperCase()+" Result"
  }

  getItemId() {
    return this.sessionid
  }

  getCollectionName() {
    return this.id.toUpperCase()+' / '+this.sessionid
  }

  addItemTHValues(tableid) {
    let str = ""
    let tab = document.getElementById(tableid)
    if (!tab)
      return
    let elem = this.addElement(tab, "thead", '')
    if (!elem)
      return
    while (elem.firstChild) {
      elem.removeChild(elem.lastChild)
    }
    this.addElement(elem, 'th', 'Pos')
    this.addElement(elem, 'th', 'Name')
    this.addElement(elem, 'th', 'Ctry')
    this.addElement(elem, 'th', 'Team')
    this.addElement(elem, 'th', 'Time')
    this.addElement(elem, 'th', 'Fastest Lap')
    this.addElement(elem, 'th', 'Gap')
    this.addElement(elem, 'th', 'Status')
    this.addElement(elem, 'th', 'Grid')
    this.addElement(elem, 'th', 'Laps')
    this.addElement(elem, 'th', 'Pits')
    this.addElement(elem, 'th', 'Points')
    this.addElement(elem, 'th', '')
  }


  addItemTDValues(item) {
    let elem = document.getElementById(this.getTableId())
    if (!elem)
      return
    let tr = this.addElement(elem, "tr", '')
    if (!tr)
      return
    // this.addElement(tr, 'td', this.getItemId(item))
    tr.onclick = (event) => this.editItem(event, item)
    this.addElement(tr, 'td', item.position)
    this.addElement(tr, 'td', item.name)
    this.addElement(tr, 'td', item.country_code)
    this.addElement(tr, 'td', item.team)
    this.addElement(tr, 'td', item.time)
    this.addElement(tr, 'td', item.fastestLapTime)
    this.addElement(tr, 'td', item.gap)
    this.addElement(tr, 'td', item.status)
    this.addElement(tr, 'td', item.grid)
    this.addElement(tr, 'td', item.laps)
    this.addElement(tr, 'td', item.pits)
    this.addElement(tr, 'td', item.points)

    let btn = this.addDeleteButtonIcon(tr, 'td', this.canEdit())
    btn.onclick = (event) => this.askDeleteItem(event, item)

  }

  afterScrapeItemsSuccess(res) {
    console.log("Scrape "+this.getItemsName()+" successful:", res);
    this.refresh()
    //this.scrapeForm.enable()
    document.body.style.cursor='default';
  }

  getItems() {
    document.body.style.cursor='wait';
    this.dataService.getSessionResults(this.id, this.sessionid)
    .subscribe(items => {
      items.sort((a,b) => {
        return (a.position < b.position) ? -1 : 1
      })
      this.afterGetItems(items)
    })
  }

  deleteItem(item) {
    document.body.style.cursor='wait';
    this.dataService.deleteResult(this.id, this.sessionid, item.id)
    // this.dataService.deleteResult(this.id, this.sessionid, this.driverid)
      .subscribe(res => this.afterSuccessfulDelete(res),
                 response => this.afterFailedDelete(response))
  }

  scrapeAllItems() {
    document.body.style.cursor='wait';
    this.dataService.scrapeSessionResults(this.id, this.sessionid)
      .subscribe(res => this.afterScrapeItemsSuccess(res), err => this.afterScrapeError(err))
  }

  deleteAllItems() {
    document.body.style.cursor='wait';
    this.dataService.deleteSessionResults(this.id, this.sessionid)
    // this.dataService.deleteResult(this.id, this.sessionid, this.driverid)
      .subscribe(res => this.afterSuccessfulDelete(res),
                 response => this.afterFailedDelete(response))
  }


}

import { Component, OnInit, Input, Output } from '@angular/core';
import { ScrapeStat } from '../../../model/scrapestat'
import { BaseItemComponent } from '../base-item/base-item.component'
import { DataService } from '../../../services/data.service'
import { Validators } from '@angular/forms'
import { StringValidator } from '../base-item/string.validator'

@Component({
  selector: 'app-scrape-stats-item',
  templateUrl: '../../../base-classes/abstract-base-item/abstract-base-item.component.html',
  styleUrls: ['../../../styles/global.scss','../../../base-classes/abstract-base-item/abstract-base-item.component.scss']
})
export class ScrapeStatsItemComponent extends BaseItemComponent {

  @Input() public sessionid: string
  @Input() public acquiredstamp: string
  item: ScrapeStat

  setParams(params) {
    this.sessionid = params['sessionid']
    this.acquiredstamp = params['acquiredstamp']
  }

  getItem() {
    this.dataService.getScrapeStat(this.sessionid, this.acquiredstamp)
    .subscribe(item => {
      console.log("ITEM = ", item)
      this.dataGetSuccess(item)
    })
  }

  disableField(field: string, ctrls: any) {
    if (field == 'sessionid' || field == 'acquiredstamp' || field == 'champid')
      ctrls[field].disable()
  }


  updateItem() {
    let st = this.item
    console.log("Item = ", st)
    let cat = st.toJson()
    this.dataService.updateScrapeStat(cat)
    .subscribe(
      val => {
        this.dataSuccess(val, 'PUT')
      },
      err => this.dataError(err, 'PUT'),
      () => {}
    )
  }

  deleteItem() {
    let st = this.item
    this.dataService.deleteScrapeStat(st.sessionid, st.acquiredstamp)
    .subscribe(
      val => this.dataSuccess(val, 'DELETE'),
      err => this.dataError(err, 'DELETE'),
      () => {}
    )
  }

  getValidators() {
    let vals = {}
    vals["acquiredstamp"] = [Validators.required, StringValidator.cannotContainSpace]
    vals["sessionid"] = [Validators.required, StringValidator.cannotContainSpace]
    vals["champid"] = [Validators.required, StringValidator.cannotContainSpace]
    return vals
  }

  getDisabledFields() {
    let list = ["champid", "acquiredstamp"]
    return list
  }

  getItemName() {
    return this.item.champid.toUpperCase()+" Scrape Stat"
  }

  getItemNames() {
    return this.item.champid.toUpperCase()+" Scrape Stats"
  }

  getItemId() {
    return this.sessionid+' - '+this.item.date
  }

  getLinkToList() {
    return "/data/champ/"+this.item.champid+"/scrapestats"
  }

  duplicateItem() {
    let newItem = new ScrapeStat()
    newItem.copy(this.item)
    this.item = newItem
    this.updateForm()
  }


}

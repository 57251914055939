import { Component } from '@angular/core';
import { Schedule } from '../../model/schedule'
import { ViewEncapsulation } from '@angular/core';
import { ScrapeItemListComponent } from '../scrape-item-list/scrape-item-list.component'

@Component({
  selector: 'app-schedule-list',
  templateUrl: '../scrape-item-list/scrape-item-list.component.html',
  styleUrls: ['../../styles/global.scss','../scrape-item-list/scrape-item-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChampScheduleListComponent extends ScrapeItemListComponent  {

  public id: string
  // OVERRIDDEN METHODS:

  setParams(params) {
   this.id = params.id
  }

  haveScrapeItemsFeature() {
   return false
  }

  haveScrapeAllFeature() {
    return false
  }

  getLinkToEdit(item) {
    // console.log("Item = ", item)
    return "/data/champ/"+this.id+"/schedule/"+this.getItemId(item)
  }

  capitalize(s: string) {
      return s && s[0].toUpperCase() + s.slice(1)
  }

  getEnv() {
    return this.capitalize(localStorage.getItem('env'))
  }

  getItemsName() {
    return this.id.toUpperCase()+" Schedules"
  }

  getItemName() {
    return this.id.toUpperCase()+" Schedule"
  }

  getItemId(item) {
    return item.name
  }

  getProdTest(item) {
    let func = item.function
    return (func.includes('Prod')) ? 'Prod' : 'Test'
  }

  haveDeleteAllFeature() {
    return true
  }

  haveExtraFeature() {
    return true
  }

  extraFeatureName() {
    return "Generate Schedules"
  }

  addItemTHValues(tableid) {
    let str = ""
    let tab = document.getElementById(tableid)
    if (!tab)
      return
    let elem = this.addElement(tab, "thead", '')
    if (!elem)
      return
    while (elem.firstChild) {
      elem.removeChild(elem.lastChild)
    }
    this.addElement(elem, 'th', 'Name')
    this.addElement(elem, 'th', 'Descripton')
    this.addElement(elem, 'th', 'Target')
    this.addElement(elem, 'th', 'Date/Time (GMT)')
    this.addElement(elem, 'th', 'Enabled')
    this.addElement(elem, 'th', '')
  }


  addItemTDValues(item) {
    let elem = document.getElementById(this.getTableId())
    if (!elem)
      return
    let tr = this.addElement(elem, "tr", '')
    if (!tr)
      return
    // this.addElement(tr, 'td', this.getItemId(item))
    tr.onclick = (event) => this.editItem(event, item)
    this.addElement(tr, 'td', item.name)
    this.addElement(tr, 'td', item.description)
    this.addElement(tr, 'td', this.getProdTest(item))
    this.addElement(tr, 'td', item.date)
    this.addBoolElement(tr, 'td', item.enabled, true, item, 'enabled')

    let btn = this.addDeleteButtonIcon(tr, 'td', this.canEdit())
    btn.onclick = (event) => this.askDeleteItem(event, item)
  }

  getItems() {
    document.body.style.cursor='wait';
    this.dataService.getChampSchedules(this.id)
    .subscribe(items => {
      items = items.filter((item) => {return this.getProdTest(item) == this.getEnv()})
      items.sort((a,b) => (a.date < b.date) ? -1 : 1)
      this.afterGetItems(items)
    })
  }

  deleteItem(item) {
    document.body.style.cursor='wait';
    this.dataService.deleteSchedule(this.getItemId(item))
      .subscribe(res => this.afterSuccessfulDelete(res),
                 response => this.afterFailedDelete(response))
  }

  updateItem(item: Schedule) {
    this.dataService.updateSchedule(JSON.stringify(item))
    .subscribe(
      val => {
        this.dataSuccess(val, 'PUT')
      },
      err => this.dataError(err, 'PUT'),
      () => {}
    )
  }

  deleteAllItems() {
    this.dataService.deleteChampSchedules(this.id)
    .subscribe(res => this.afterScrapeItemSuccess(res),
    err => this.dataError(err, 'DELETE'))
  }

  runExtraFeature() {
    document.body.style.cursor='wait';
    this.dataService.generateChampSchedules(this.id)
    .subscribe(
      val => {
        this.dataSuccess(val, 'PUT')
      },
      err => this.dataError(err, 'PUT'),
      () => {}
    )
  }

}

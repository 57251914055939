import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http'
import { ContentRoutingModule } from './content-routing.module';

import { ContentMenu } from './content-menu/content-menu'
import { SourceListComponent } from './source-list/source-list.component'
import { SourceItemComponent } from './source-item/source-item.component'
import { CategoryListComponent } from './category-list/category-list.component'
import { CategoryItemComponent } from './category-item/category-item.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContentSourceListComponent } from './content-source-list/content-source-list.component';
import { ContentCategoryListComponent } from './content-category-list/content-category-list.component';
import { ContentItemComponent } from './content-item/content-item.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { MatTableModule } from '@angular/material/table'
import { MatSortModule } from '@angular/material/sort';
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatNativeDateModule } from '@angular/material/core'
import { FailedContentListComponent } from './failed-content-list/failed-content-list.component'
import { FailedContentItemComponent } from './failed-content-item/failed-content-item.component'

import { NgbModule } from '@ng-bootstrap/ng-bootstrap'

import { SideMenuComponent } from './content-menu/side-menu.component'
import { MatPaginatorModule} from '@angular/material/paginator';
import { BreadcrumbModule } from "xng-breadcrumb"
import { ContentListComponent } from './content-list/content-list.component';

@NgModule({
  declarations: [
    SourceListComponent,
    SourceItemComponent,
    ContentMenu,
    SideMenuComponent,
    CategoryListComponent,
    CategoryItemComponent,
    ContentSourceListComponent,
    ContentCategoryListComponent,
    ContentItemComponent,
    ContentListComponent,
    FailedContentListComponent,
    FailedContentItemComponent,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    HttpClientModule,
    ContentRoutingModule,
    BrowserAnimationsModule,
    MatTableModule,
    MatSortModule,
    NgbModule,
    BreadcrumbModule,
    MatPaginatorModule,
    MatDatepickerModule,
    MatNativeDateModule,
  ],
  // exports: [
  //   ContentMenu
  // ],
  providers: [
    ],
  bootstrap: [ContentMenu]
})
export class ContentModule { }

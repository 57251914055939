import { BaseItem } from './base-item'

export class Content extends BaseItem {

  public id: string
  public images: []
  public headline: string
  public summary: string
  public categories: string
  public sourceid: string
  public published: string
  public updated: string
  public url: string
  public type: string
  public discovered_categories: string

  getMainParams(body) {
    if ('body' in body)
      this.retrieveFromSingleContent(body)
    else
      this.retrieveFromListOfContent(body)
  }

  retrieveFromSingleContent(item) {
    this.id = item.uuid
    this.type = item.type
    let body = JSON.parse(item.body) 
    this.headline = body.headline
    this.summary = body.summary
    this.categories = JSON.stringify(body.categories)
    this.discovered_categories = JSON.stringify(body.discovered_categories)
    this.sourceid = body.source.id
    this.published = body.published
    this.updated = body.updated
    this.url = body.url
    this.images = body.media.images
    console.log("Images = ", this.images)
  }

  retrieveFromListOfContent(body) {
    this.id = body.uuid
    this.headline = body.headline
    this.summary = body.summary
    this.categories = JSON.stringify(body.categories)
    this.discovered_categories = JSON.stringify(body.discovered_categories)
    this.sourceid = body.source.id
    this.published = body.published
    this.updated = body.updated
    this.url = body.url
    this.images = body.media.images
  }

  // setMainParams(body) {
  //     body.id = this.id
  //     body.api_id = this.api_id
  //     body.name = this.name
  //     body.notify = this.notify
  //     body.active = this.active
  //     body.race_name = this.raceName ? this.raceName : ''
  //     body.last_session = this.lastSession ? this.lastSession : ''
  //     body.sourceid = this.sourceid ? this.sourceid : ''
  //     body.noTeamStandings = this.noTeamStandings
  // }

}

import { AbstractControl, ValidationErrors } from '@angular/forms';

export class StringValidator {
    static cannotContainSpace(control: AbstractControl) : ValidationErrors | null {
        if((control.value as string).indexOf(' ') >= 0){
            return {"cannot contain space": true}
        }

        return null;
    }
}


<nav class="navbar navbar-fixed-top navbar-dark bg-inverse">
    <div class="container">
        <a class="navbar-brand">MotorMouth Content & Data Admin</a>
        <div *ngIf="securityObject.canAccessData" class="onoffswitch">
            <input (click)="toggleProd()" type="checkbox" name="onoffswitch" class="onoffswitch-checkbox" id="myonoffswitch" tabindex="0" [checked]="isProd">
            <label class="onoffswitch-label" for="myonoffswitch">
                <span class="onoffswitch-inner"></span>
                <span class="onoffswitch-switch"></span>
            </label>
        </div>


        <ul id="menu-main" class="nav navbar-nav" routerLinkActive="active">
            <li class="nav-item" ><a class="nav-link" *ngIf="securityObject.canAccessContent" routerLink="content/content-categories">Content</a></li>
            <li class="nav-item" ><a class="nav-link" *ngIf="securityObject.canAccessData" routerLink="data/champs" >Data</a></li>
            <li class="nav-item">
                <a  class="nav-link" routerLink="login" *ngIf="!securityObject.isAuthenticated">Login</a>
                <a  class="nav-link" href="#" (click)="logout()" *ngIf="securityObject.isAuthenticated">Logout {{securityObject.userName}}</a>
            </li>
        </ul>
    </div>
</nav>


  <!-- <div class="container">


  <ul ngbNav #nav="ngbNav" class="nav-tabs">
    <li ngbNavItem>
      <a ngbNavLink routerLinkActive="active" routerLink="content-menu">Content</a>
    </li>
    <li ngbNavItem>
      <a ngbNavLink routerLinkActive="active" routerLink="live-data-menu">Live Data</a>
    </li>
  </ul>


</div> -->
<router-outlet></router-outlet>

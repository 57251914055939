import { Component } from '@angular/core';
import { AbstractItemListComponent } from '../../base-classes/abstract-item-list/abstract-item-list.component'
import { ViewEncapsulation } from '@angular/core';


import { Source } from '../../model/source';
import { ContentStats } from '../../model/content-stats'

@Component({
  selector: 'app-content-source-list',
  templateUrl: '../../base-classes/abstract-item-list/abstract-item-list.component.html',
  styleUrls: ['../../styles/global.scss','../../base-classes/abstract-item-list/abstract-item-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContentSourceListComponent extends AbstractItemListComponent {

  stats: ContentStats[]

  getTableId() {
    return 'table-content-stats'
  }

  setTitle() {
    this.pageTitle += "Content stats for Sources"
  }

  getLinkToEdit(item) {
    // console.log("Item = ", item)
    let ln = "/content/source/"+this.getItemId(item)
    return ln
  }

  haveScrapeItemsFeature() {
    return false
   }
 
  getItemsName() {
    return "Source stats"
  }

  getItemName() {
    return "Source"
  }

  getItemId(item) {
    return item.id
  }

  addItemTHValues(tableid) {
    let str = ""
    let tab = document.getElementById(tableid)
    if (!tab) {
      console.log("No elem", tableid)
      return
    }
    let elem = this.addElement(tab, "thead", '')
    if (!elem)
      return
    while (elem.firstChild) {
      elem.removeChild(elem.lastChild)
    }
    this.addElement(elem, 'th', 'source')
    this.addElement(elem, 'th', 'id')
    // this.addElement(elem, 'th', 'url')
    this.addElement(elem, 'th', 'type')
    this.addElement(elem, 'th', 'total')
    this.addElement(elem, 'th', 'last content')
    this.addElement(elem, 'th', 'last checked')
    this.addElement(elem, 'th', 'status')
  }


  addItemTDValues(item) {
    let elem = document.getElementById(this.getTableId())
    if (!elem) {
      console.log("No elem", this.getTableId())
      return
    }
    let tr = this.addElement(elem, "tr", '', 'stats-row')
    if (!tr) 
      return
    // this.addElement(tr, 'td', this.getItemId(item))
    if (this.getItemId(item))
      tr.onclick = (event) => this.editItem(event, item)
    this.addElement(tr, 'td', item.name)
    this.addElement(tr, 'td', item.id)
    // this.addElement(tr, 'td', this.getUrlForSource(item))
    this.addElement(tr, 'td', item.type)
    this.addElement(tr, 'td', this.getTotalFromStats(item.stats))
    this.addElement(tr, 'td', this.getHumanDate(item.stats.lastDate))
    this.addElement(tr, 'td', this.getHumanDate(item.stats.lastUpdated))
    this.addElement(tr, 'td', this.getStatusForSource(item))
  }


  getItems() {
    document.body.style.cursor='wait';
    let typeFilter = this.getFilterSelectedOption('type')

    this.dataService.getSources(typeFilter)
        .subscribe(items => {
          this.items = items
          this.getStats()
        })
  }

  getUrlForSource(source) {
    if (source.type == 'text')
      return source.url
    else
      return 'https://www.youtube.com/channel/'+source['channelId']
  }

  getStats() {
    this.dataService.getContentStatsForSources(this.dateFrom)
      .subscribe(items => {
        this.stats = items
        this.setStatsForSources()
        this.afterGetItems(this.items)
    })
  }

  getStatusForSource(source: Source) {
    if (!source.active)
      return 'Inactive'
    if  (source.hasLazyLoad())
      return "Needs Lazy load"
    return "OK"
  }

  setStatsForSources() {
    for (const item of this.items) {
      let s = item as Source
      for (const stat of this.stats) {
        if (s.id == stat.sourceId)
          s.stats = stat
      }
    }
  }

  getTotalFromStats(stats) {
    if (stats)
      return (stats.total) > 0 ? stats.total : '-' 
    return '-'
  }

  createSelectFilters() {
    this.selectFilters = []
    let fil = {
      'name': 'Type',
      'id': 'type',
      'selected': 'all',
      'options': [{'name': 'Text', 'id': 'text'},{'name': 'Video', 'id': 'video'}]
    }
    this.selectFilters.push(fil)
  }

  getFilterList(index) {
    if (!this.haveSelectFilters())
      return


    document.body.style.cursor='wait';
    let fil = this.selectFilters[index]
    if (fil['id'] == 'type') {
      index++
      if (index >= this.selectFilters.length)
        this.getItems()
      else
        this.getFilterList(index)
    }      
  }


  haveFilter() {
    return true
  }

  haveDateFilter(): boolean {
    return true
  }

  haveDeleteAllFeature(): boolean {
    return false
  }
}

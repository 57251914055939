import { BaseItem } from './base-item'
import { Session } from './session'

export class LiveSession extends BaseItem {

  public champid: string
  public sessionName: string
  public type: string
  public updated: string
  public timestamp: string
  public id: string
  public session: Session
  public status: string

  setChamp(id) {
    this.champid = id
  }

  getMainParams(body) {
      this.champid = body.champ
      this.sessionName = body.session_name
      this.updated = body.updated
      this.timestamp = body.timestamp
      this.id = body.id
      this.type = body.type

      if (body.session) {
        // console.log("SESSIOB received = ", body.session)
        this.session = JSON.parse(body.session)
        //let ses = new Session(JSON.parse(body.session))
        this.status = this.session.status
      }

  }

  setMainParams(body) {
      body.champ = this.champid
      body.id = this.id
      body.timestamp = this.timestamp
      body.session_name = this.sessionName
      body.updated = this.updated
      body.type = this.type
      //let ses = new Session(JSON.parse(this.session))
      //ses.status = this.status
      body.status = this.status
      body.session = JSON.stringify(this.session)//.replace(/\"/g, '\\"')
  }

  copy(item: LiveSession) {
    this.id = item.id+"-1"
    this.sessionName = item.sessionName
    this.timestamp = item.timestamp
    this.type = item.type
    this.updated = item.updated
    this.champid = item.champid
  }

  static getNewItem(id) {
    return new LiveSession({"champid": id, "sessionName": "", "id": "", "timestamp": ""})
  }
}

import { BaseItem } from './base-item'
import { Session } from './session'

export class Champ extends BaseItem {

  public id: string
  public name: string
  public notify: boolean
  public active: boolean
  public sourceid: string
  public lastSession: string
  public raceName: string
  public raceMappings: string
  public sessionMappings: string
  public liveSession: Session = null
  public scrapeStatus: boolean
  public noTeamStandings: boolean
  public api_id: string
  public year: number


  getMainParams(body) {
      console.log("BODY year = ", body.year)
      this.id = body.id
      this.name = body.name
      this.year = body.year
      this.notify = this.setBoolVal(body.notify, false)
      this.active = this.setBoolVal(body.active, false)
      this.sourceid = body.sourceid ? body.sourceid : ''
      this.lastSession = body.last_session ? body.last_session : ''
      this.raceName = body.race_name ? body.race_name : ''
      this.api_id = body.api_id ? body.api_id : ''
      this.noTeamStandings = this.setBoolVal(body.noTeamStandings, false)
      let b = this.setBoolVal(body.noTeamStandings, false)
      // if (body.active == undefined)
      //   this.active = true
      // else
      //   this.active = body.active

  }

  setMainParams(body) {
      body.id = this.id
      body.api_id = this.api_id
      body.name = this.name
      body.year = this.year
      body.notify = this.notify
      body.active = this.active
      body.race_name = this.raceName ? this.raceName : ''
      body.last_session = this.lastSession ? this.lastSession : ''
      body.sourceid = this.sourceid ? this.sourceid : ''
      body.noTeamStandings = this.noTeamStandings
  }

  copy(item: Champ) {
    this.id = item.id+'copy'
    this.name = item.name
    this.year = item.year
    this.lastSession = item.lastSession
    this.raceName = item.raceName
    this.sourceid = item.sourceid
    this.active = item.active
    this.notify = item.notify
    this.year = item.year
    this.noTeamStandings = item.noTeamStandings
  }

  static getNewItem() {
    return new Champ({"id": "", "name": "", "last_session": "", "race_name": "", "sourceid": ""})
  }
}

import { Injectable } from '@angular/core'
import { Source } from '../model/source'
import { Category } from '../model/category'
import { Schedule } from '../model/schedule'
import { Session } from '../model/session'
import { Race } from '../model/race'
import { Champ } from '../model/champ'
import { Driver } from '../model/driver'
import { Team } from '../model/team'
import { Result } from '../model/result'
import { Stage } from '../model/stage'
import { Notification } from '../model/notification'
import { Country } from '../model/country'
import { City } from '../model/city'
import { Equiv } from '../model/equiv'
import { Gpname } from '../model/gpname'
import { LiveSession } from '../model/livesession'
import { ScrapeStat } from '../model/scrapestat'
import { ContentStats } from '../model/content-stats'
import { FailedContent } from '../model/failedcontent'
import { Content } from '../model/content'
import { Observable } from 'rxjs'
import { HttpClient, HttpHeaders } from '@angular/common/http'

import { map } from 'rxjs/operators'
import { environment } from '../constants'


@Injectable({
  providedIn: 'root'
})
export class DataService {

  contentApiUrl: string;
  dataApiUrl: string
  utilityApiUrl: string

  constructor(private http: HttpClient) {
    this.contentApiUrl = environment.prodContentApiUrlBase
    this.dataApiUrl = environment.prodDataApiUrlBase
    this.utilityApiUrl = environment.prodUtilityApiUrlBase
  }

  setApis() {
    let isProd = localStorage.getItem('env') == 'prod'
    if (isProd) {
      this.dataApiUrl = environment.prodDataApiUrlBase
      this.contentApiUrl = environment.prodContentApiUrlBase
      this.utilityApiUrl = environment.prodUtilityApiUrlBase
    }
    else {
      this.dataApiUrl = environment.testDataApiUrlBase
      this.contentApiUrl = environment.testContentApiUrlBase
      this.utilityApiUrl = environment.testUtilityApiUrlBase
    }
  }


  getChamps(year: number) : Observable<Champ[]> {
    this.setApis()

    console.log("Calling "+this.dataApiUrl+'/champ?year='+year)
    return this.http.get<Champ[]>(this.dataApiUrl+'/champ?year='+year
    ).pipe(
        map((data: any[]) => data.sort((a,b) => a.id < b.id ? -1 : 1)
        .map((item: any) => new Champ(
          item
        ),
        )))
  }

  getChamp(id: string, year: number) : Observable<Champ> {
    this.setApis()

    return this.http.get<any>(this.dataApiUrl+'/champ/'+id+'?year='+year
    ).pipe(
        map((item: any) => new Champ(
          item
          ),
        ))
  }

  deleteChamp(id: string) : Observable<Champ> {
    this.setApis()

    return this.http.delete<Champ>(this.dataApiUrl+'/champ/'+id)
  }

  getStages(id: string) : Observable<Stage[]> {
    this.setApis()
    return this.http.get<Stage[]>(this.dataApiUrl+'/stage/'+id
    ).pipe(
        map((data: any[]) => data && data.sort((a,b) => a.id < b.id ? -1 : 1)
        .map((item: any) => new Stage(
          item
        ),
        )))
  }

  getStage(id: string, name: string, location: string) : Observable<Stage> {
    this.setApis()
    console.log("GETTING ", this.dataApiUrl+'/stage/'+id+'/'+name+'/'+location)
    return this.http.get<any>(this.dataApiUrl+'/stage/'+id+'/'+name+'/'+location
    ).pipe(
        map((item: any) => new Stage(
          item
          ),
        ))
  }

  deleteStage(id: string, name: string, location: string) : Observable<Stage> {
    this.setApis()
    console.log("DELETING: ", this.dataApiUrl+'/stage/'+id+'/'+name+'/'+location)
    return this.http.delete<Stage>(this.dataApiUrl+'/stage/'+id+'/'+name+'/'+location)
  }

  updateStage(id: string, obj: string) : Observable<any> {

    const headers = new HttpHeaders()
        .set("Content-Type", "application/json");
    this.setApis()


    return this.http.put(this.dataApiUrl+'/stage/'+id, obj, {headers})

  }


  getCountries() : Observable<Country[]> {
    this.setApis()
    return this.http.get<Country[]>(this.dataApiUrl+'/country/'
    ).pipe(
        map((data: any[]) => data.sort((a,b) => a.country_code < b.country_code ? -1 : 1)
        .map((item: any) => new Country(
          item
        ),
        )))
  }

  getCountry(code: string) : Observable<Country> {
    this.setApis()
    console.log("GETTING ", this.dataApiUrl+'/country/'+code)
    return this.http.get<any>(this.dataApiUrl+'/country/'+code
    ).pipe(
        map((item: any) => new Country(
          item
          ),
        ))
  }

  deleteCountry(code: string) : Observable<Country> {
    this.setApis()
    console.log("DELETING: ", this.dataApiUrl+'/country/'+code)
    return this.http.delete<Country>(this.dataApiUrl+'/country/'+code)
  }

  updateCountry(obj: string) : Observable<any> {

    const headers = new HttpHeaders()
        .set("Content-Type", "application/json");
    this.setApis()


    return this.http.put(this.dataApiUrl+'/country/', obj, {headers})

  }

  getCities() : Observable<City[]> {
    this.setApis()
    return this.http.get<City[]>(this.dataApiUrl+'/city/'
    ).pipe(
        map((data: any[]) => data.sort((a,b) => a.city_name < b.city_name ? -1 : 1)
        .map((item: any) => new City(
          item
        ),
        )))
  }

  getCity(code: string) : Observable<City> {
    this.setApis()
    console.log("GETTING ", this.dataApiUrl+'/city/'+code)
    return this.http.get<any>(this.dataApiUrl+'/city/'+code
    ).pipe(
        map((item: any) => new City(
          item
          ),
        ))
  }

  deleteCity(code: string) : Observable<City> {
    this.setApis()
    console.log("DELETING: ", this.dataApiUrl+'/city/'+code)
    return this.http.delete<City>(this.dataApiUrl+'/city/'+code)
  }

  updateCity(obj: string) : Observable<any> {

    const headers = new HttpHeaders()
        .set("Content-Type", "application/json");
    this.setApis()


    return this.http.put(this.dataApiUrl+'/city/', obj, {headers})

  }

  getEquivs() : Observable<Equiv[]> {
    this.setApis()
    return this.http.get<Equiv[]>(this.dataApiUrl+'/equiv/'
    ).pipe(
        map((data: any[]) => data.sort((a,b) => a.name < b.name ? -1 : 1)
        .map((item: any) => new Equiv(
          item
        ),
        )))
  }

  getEquiv(name: string) : Observable<Equiv> {
    this.setApis()
    console.log("GETTING ", this.dataApiUrl+'/equiv/'+name)
    return this.http.get<any>(this.dataApiUrl+'/equiv/'+name
    ).pipe(
        map((item: any) => new Equiv(
          item
          ),
        ))
  }

  deleteEquiv(name: string) : Observable<Equiv> {
    this.setApis()
    console.log("DELETING: ", this.dataApiUrl+'/equiv/'+name)
    return this.http.delete<Equiv>(this.dataApiUrl+'/equiv/'+name)
  }

  updateEquiv(obj: string) : Observable<any> {

    const headers = new HttpHeaders()
        .set("Content-Type", "application/json");
    this.setApis()


    return this.http.put(this.dataApiUrl+'/equiv/', obj, {headers})

  }

  getGpnames(id: string) : Observable<Gpname[]> {
    this.setApis()
    return this.http.get<Gpname[]>(this.dataApiUrl+'/gpname/'+id
    ).pipe(
        map((data: any[]) => data && data.sort((a,b) => a.pattern < b.pattern ? -1 : 1)
        .map((item: any) => new Gpname(
          item
        ),
        )))
  }

  getGpname(id: string, pattern: string) : Observable<Gpname> {
    this.setApis()
    return this.http.get<any>(this.dataApiUrl+'/gpname/'+id+'/'+pattern
    ).pipe(
        map((item: any) => new Gpname(
          item
          ),
        ))
  }

  deleteGpname(id: string, pattern: string) : Observable<Gpname> {
    this.setApis()
    return this.http.delete<Gpname>(this.dataApiUrl+'/gpname/'+id+'/'+pattern)
  }

  updateGpname(id: string, obj: string) : Observable<any> {
    console.log("Updating id = ", id)
    const headers = new HttpHeaders()
        .set("Content-Type", "application/json");
    this.setApis()


    return this.http.put(this.dataApiUrl+'/gpname/'+id, obj, {headers})

  }

  getLiveSessions() : Observable<LiveSession[]> {
    this.setApis()
    return this.http.get<LiveSession[]>(this.dataApiUrl+'/livesession/'
    ).pipe(
      map((data: any[]) => {
        if (!data)
          return []
        else
          return data
            .sort((a,b) => a.timestamp > b.timestamp ? -1 : 1)
              .map((item: any) => new LiveSession(item))
      }))
      //   map((data: any[]) => data.filter((a) => a.champ == id)
      //   .sort((a,b) => a.timestamp > b.timestamp ? -1 : 1)
      //   // map((data: any[]) => data.sort((a,b) => a.timestamp < b.timestamp ? -1 : 1)
      //   .map((item: any) => new LiveSession(
      //     item
      //   ),
      // )))
  }

  getLiveSession(id: string) : Observable<LiveSession> {
    this.setApis()
    return this.http.get<any>(this.dataApiUrl+'/livesession/'+id
    ).pipe(
        map((item: any) => new LiveSession(
          item
          ),
        ))
  }

  deleteLiveSession(id: string) : Observable<LiveSession> {
    this.setApis()
    return this.http.delete<LiveSession>(this.dataApiUrl+'/livesession/'+id)
  }

  updateLiveSession(obj: string) : Observable<any> {
    const headers = new HttpHeaders()
        .set("Content-Type", "application/json");
    this.setApis()
    console.log("OBJ = ", obj)
    return this.http.put(this.dataApiUrl+'/livesession', obj, {headers})

  }

  getScrapeStatsForChamp(id: string) : Observable<ScrapeStat[]> {
    this.setApis()
    return this.http.get<ScrapeStat[]>(this.dataApiUrl+'/scrapestat/'+id
    ).pipe(
        map((data: any[]) => {
          if (!data)
            return []
          else
            return data.filter((a) => a.champ == id)
              .sort((a,b) => a.acquiredstamp > b.acquiredstamp ? -1 : 1)
                .map((item: any) => new ScrapeStat(item))
        }))
  }


  getScrapeStat(sessionid: string, acquiredstamp: string) : Observable<ScrapeStat> {
    this.setApis()
    return this.http.get<any>(this.dataApiUrl+'/scrapestat/'+sessionid+'/'+acquiredstamp
    ).pipe(
        map((item: any) => new ScrapeStat(
          item
          ),
        ))
  }

  getScrapeStats() : Observable<ScrapeStat[]> {
    this.setApis()
    return this.http.get<ScrapeStat[]>(this.dataApiUrl+'/scrapestat/'
    ).pipe(
        map((data: any[]) => {
          if (!data)
            return []
          else
            //console.log("Got stats: ", data)
            return data.sort((a,b) => a.acquiredstamp > b.acquiredstamp ? -1 : 1)
                .map((item: any) => new ScrapeStat(item))
        }))
  }

  deleteScrapeStat(sessionid: string, acquiredstamp: string) : Observable<ScrapeStat> {
    this.setApis()
    return this.http.delete<ScrapeStat>(this.dataApiUrl+'/scrapestat/'+sessionid+'/'+acquiredstamp)
  }

  deleteScrapeStats(champid: string) : Observable<ScrapeStat[]> {
    this.setApis()
    return this.http.delete<ScrapeStat[]>(this.dataApiUrl+'/scrapestat/'+champid)
  }

  updateScrapeStat(obj: string) : Observable<any> {
    const headers = new HttpHeaders()
        .set("Content-Type", "application/json");
    this.setApis()
    console.log("OBJ = ", obj)
    return this.http.put(this.dataApiUrl+'/scrapestat', obj, {headers})

  }

  getFailedContent(date_from: string, type: string = '', reason_code: string = '') : Observable<FailedContent[]> {
    this.setApis()
    let query = "?fromDate="+date_from
    if (type != '' && type != 'all') 
      query += "&type="+type
    if (reason_code != '' && reason_code != 'all')
      query += "&fail_reason="+reason_code
    return this.http.get<FailedContent[]>(this.contentApiUrl+'/content-failed'+query
    ).pipe(
        map((data: any[]) => {
          if (!data)
            return []
          else
            //console.log("Got stats: ", data)
            return data.sort((a,b) => a.acquiredstamp > b.acquiredstamp ? -1 : 1)
                .map((item: any) => new FailedContent(item))
        }))
  }

  getFailedContentItem(type: string, acquiredstamp: string) : Observable<FailedContent> {
    this.setApis()
    let query = ""

    return this.http.get<any>(this.contentApiUrl+'/content-failed?type='+type+'&id='+acquiredstamp
    ).pipe(
        map((item: any) => new FailedContent(
          item
          ),
        ))
  }

  deleteFailedContent(type: string = '', acquiredstamp: string = '') : Observable<FailedContent[]> {
    this.setApis()
    let query = ""
    if (type != '') {
      query = "?type="+type
      if (acquiredstamp != '')
        query += "&id="+acquiredstamp
    }
    return this.http.delete<FailedContent[]>(this.contentApiUrl+'/content-failed'+query)
  }


  getChampSchedules(id: string) : Observable<any[]> {
    this.setApis()

    //return this.http.get<Schedule[]>(this.dataApiUrl+'/champ/'+id+'/schedules'
    return this.http.get<Schedule[]>(this.dataApiUrl+'/schedules/'+id
  ).pipe(
      map((data: any[]) => data.sort((a,b) => a.Name < b.Name ? -1 : 1)
      .map((item: any) => new Schedule(
        item
        ),
      )))
  }

  scrapeChampRace(id: string, year: number, raceid: string, champid: string='', quiet: boolean=true, useExtraInfo: boolean = false) : Observable<Race> {
    const headers = new HttpHeaders()
        .set("Content-Type", "application/json");
    this.setApis()
    return this.http.put<Race>(this.dataApiUrl+'/'+id+'/race?year='+year+'&raceid='+raceid+'&quiet='+quiet+'&use_extra_info='+useExtraInfo+'&champid='+champid, {}, {headers})
  }

  scrapeChampRaces(id: string, year: number, start: number, num: number, champid: string = '', quiet: boolean =  true, useExtraInfo: boolean = false) : Observable<Race[]> {
    const headers = new HttpHeaders()
        .set("Content-Type", "application/json");
    this.setApis()
    return this.http.put<Race[]>(this.dataApiUrl+'/'+id+'/races?year='+year+'&start_round='+start+'&num_rounds='+num+'&quiet='+quiet+'&use_extra_info='+useExtraInfo+'&champid='+champid, {}, {headers})
  }

  getChampRaces(id: string, year: number) : Observable<Race[]> {
    this.setApis()
    console.log("Year = ", year)
    return this.http.get<Race[]>(this.dataApiUrl+'/'+id+'/races?year='+year
      ).pipe(
          map((data: any[]) => data.sort((a,b) => a.scheduled < b.scheduled ? -1 : 1)
          .map((item: any) => new Race(
            item
            ),
          )))
  }

  // scrapeChampRace(id: string, quiet: boolean, useExtraInfo: boolean) : Observable<Race> {
  //   const headers = new HttpHeaders()
  //       .set("Content-Type", "application/json");
  //   this.setApis()
  //
  //   return this.http.put<Race>(this.dataApiUrl+'/'+id+'/race?raceid='+num+'&quiet='+quiet+'&use_extra_info='+useExtraInfo, {headers})
  // }

  getChampRace(id: string, raceid: string) : Observable<Race> {
    this.setApis()
    return this.http.get<Race>(this.dataApiUrl+'/'+id+'/race?raceid='+raceid)
    .pipe(map((item: any) => new Race(
              item
            )))
  }

  getChampDrivers(id: string, year: Number) : Observable<Driver[]> {
    this.setApis()
    return this.http.get<Driver[]>(this.dataApiUrl+'/'+id+'/drivers?year='+year.toString()
      ).pipe(
          map((data: any[]) => data.sort((a,b) => a.scheduled < b.scheduled ? -1 : 1)
          .map((item: any) => new Driver(
            item
            ),
          )))
  }

  getChampDriver(id: string, driverid: string, year: Number) : Observable<Driver> {
    this.setApis()
    return this.http.get<Driver>(this.dataApiUrl+'/'+id+'/driver/?id='+driverid+'&year='+year.toString()
      ).pipe(
          map((item: any) => new Driver(
            item
            ),
          ))
  }


  getChampTeam(id: string, teamid: string, year: Number) : Observable<Team> {
    this.setApis()
    return this.http.get<Team>(this.dataApiUrl+'/'+id+'/team?year='+year.toString()+'&id='+teamid
      ).pipe(
          map((item: any) => new Team(
            item
            ),
          ))
  }

  updateRace(id: string, raceid: string, obj: string) : Observable<any> {
    const headers = new HttpHeaders()
        .set("Content-Type", "application/json");
    this.setApis()


    return this.http.patch(this.dataApiUrl+'/'+id+'/race/'+raceid, obj, {headers})

  }

  updateSession(id: string, sessionid: string, obj: string) : Observable<any> {

    const headers = new HttpHeaders()
        .set("Content-Type", "application/json");
    this.setApis()


    return this.http.patch(this.dataApiUrl+'/'+id+'/session/'+sessionid, obj, {headers})

  }

  updateResult(id: string, sessionid: string, driverid: string, obj: string) : Observable<any> {

    const headers = new HttpHeaders()
        .set("Content-Type", "application/json");
    this.setApis()


    return this.http.patch(this.dataApiUrl+'/'+id+'/result/'+sessionid+'/'+driverid, obj, {headers})

  }

  updateDriver(id: string, driverid: string, year: Number, obj: string) : Observable<any> {

    const headers = new HttpHeaders()
        .set("Content-Type", "application/json");
    this.setApis()


    return this.http.patch(this.dataApiUrl+'/'+id+'/driver/'+driverid+'?year='+year.toString(), obj, {headers})

  }

  updateTeam(id: string, teamid: string, year: Number, obj: string) : Observable<any> {

    const headers = new HttpHeaders()
        .set("Content-Type", "application/json");
    this.setApis()


    return this.http.patch(this.dataApiUrl+'/'+id+'/team/'+teamid+'?year='+year.toString(), obj, {headers})

  }

  scrapeChampDrivers(id: string, year: Number, champid: string = '') : Observable<Driver[]> {
    const headers = new HttpHeaders()
        .set("Content-Type", "application/json");
    this.setApis()

    return this.http.put<Driver[]>(this.dataApiUrl+'/'+id+'/drivers?year='+year.toString()+'&champid='+champid, {}, {headers})
  }

  getChampTeams(id: string, year: Number) : Observable<Team[]> {
    this.setApis()
    return this.http.get<Team[]>(this.dataApiUrl+'/'+id+'/teams?year='+year.toString()
      ).pipe(
          map((data: any[]) => data.sort((a,b) => a.scheduled < b.scheduled ? -1 : 1)
          .map((item: any) => new Team(
            item
            ),
          )))
  }

  scrapeChampTeams(id: string, year: Number, champid: string = '') : Observable<Team[]> {
    const headers = new HttpHeaders()
        .set("Content-Type", "application/json");
    this.setApis()

    return this.http.put<Team[]>(this.dataApiUrl+'/'+id+'/teams?year='+year.toString()+'&champid='+champid, {}, {headers})
  }

  getRaceSessions(id: string, raceid: string) : Observable<Session[]> {
    this.setApis()
    return this.http.get<Session[]>(this.dataApiUrl+'/'+id+'/sessions?raceid='+raceid
      ).pipe(
          map((data: any[]) => data.sort((a,b) => a.scheduled < b.scheduled ? -1 : 1)
          .map((item: any) => new Session(
            item
            ),
          )))
  }

  getRaceSession(id: string, raceid: string, sessionid: string) : Observable<Session> {
    this.setApis()
    return this.http.get<Session>(this.dataApiUrl+'/'+id+'/session?raceid='+raceid+'&sessionid='+sessionid
  ).pipe(map((item: any) => new Session(
            item
          )))
  }

  getNotifications(topic: string) : Observable<Notification[]> {
    this.setApis()
    return this.http.get<Notification[]>(this.utilityApiUrl+'/notifications/'+topic
      ).pipe(
          map((data: any[]) => data.sort((a,b) => a.datestamp > b.datestamp ? -1 : 1)
          .map((item: any) => new Notification(
            item
            ),
          )))
  }

  getChampNotifications(topic: string, id: string) : Observable<Notification[]> {
    this.setApis()
    console.log("Getting notifications ", topic, id)
    return this.http.get<Notification[]>(this.utilityApiUrl+'/notifications/'+topic+'/'+id
      ).pipe(
          map((data: any[]) => data.sort((a,b) => a.datestamp > b.datestamp ? -1 : 1)
          .map((item: any) => new Notification(
            item
            ),
          )))
  }

  getSessionNotifications(topic: string, id: string, raceid: string, sessionid: string) : Observable<Notification[]> {
    this.setApis()
    return this.http.get<Notification[]>(this.utilityApiUrl+'/notifications/'+topic+'/'+id+'/'+raceid+'/'+sessionid
      ).pipe(
          map((data: any[]) => data && data.sort((a,b) => a.datestamp > b.datestamp ? -1 : 1)
          .map((item: any) => new Notification(
            item
            ),
          )))
  }

  getRaceNotifications(topic: string, id: string, raceid: string) : Observable<Notification[]> {
    this.setApis()
    return this.http.get<Notification[]>(this.utilityApiUrl+'/notifications/'+topic+'/'+id+'/'+raceid
      ).pipe(
          map((data: any[]) => data.sort((a,b) => a.datestamp > b.datestamp ? -1 : 1)
          .map((item: any) => new Notification(
            item
            ),
          )))
  }

  getNotification(topic: string, datestamp: Number) : Observable<Notification> {
    this.setApis()
    console.log("GETTING ", topic, datestamp)
    return this.http.get<Notification>(this.utilityApiUrl+'/notification/'+topic+'/'+datestamp
  ).pipe(map((item: any) => new Notification(
            item
          )))
  }


  updateNotification(topic: string, datestamp: Number, obj: string) : Observable<any> {

    const headers = new HttpHeaders()
        .set("Content-Type", "application/json");
    this.setApis()

    return this.http.patch(this.utilityApiUrl+'/notification/'+topic+'/'+datestamp.toString(), obj, {headers})

  }

  // scrapeChampRace(id: string, raceid: string) : Observable<any> {
  //   const headers = new HttpHeaders()
  //       .set("Content-Type", "application/json");
  //   this.setApis()
  //
  //   return this.http.put<any>(this.dataApiUrl+'/'+id+'/race?get_results=False&raceid='+raceid, {headers})
  // }

  deleteRace(id: string, raceid: string) {
    const headers = new HttpHeaders()
        .set("Content-Type", "application/json");
    this.setApis()

    return this.http.delete(this.dataApiUrl+'/'+id+'/race/'+raceid, {headers})

  }


  deleteSession(id: string, sessionid: string) {
    const headers = new HttpHeaders()
        .set("Content-Type", "application/json");
    this.setApis()

    return this.http.delete(this.dataApiUrl+'/'+id+'/session/'+sessionid, {headers})

  }

  deleteResult(id: string, sessionid: string, driverid: string) {
    const headers = new HttpHeaders()
        .set("Content-Type", "application/json");
    this.setApis()

    return this.http.delete(this.dataApiUrl+'/'+id+'/result/'+sessionid+'/'+driverid, {headers})

  }

  deleteTeam(id: string, teamid: string, year: Number) {
    const headers = new HttpHeaders()
        .set("Content-Type", "application/json");
    this.setApis()

    return this.http.delete(this.dataApiUrl+'/'+id+'/team/'+teamid+'?year='+year.toString(), {headers})

  }

  deleteDriver(id: string, driverid: string, year: Number) {
    const headers = new HttpHeaders()
        .set("Content-Type", "application/json");
    this.setApis()

    return this.http.delete(this.dataApiUrl+'/'+id+'/driver/'+driverid+'?year='+year.toString(), {headers})

  }

  getSessionResults(id: string, sessionid: string) : Observable<Result[]> {
    this.setApis()
    return this.http.get<Result[]>(this.dataApiUrl+'/'+id+'/results?sessionid='+sessionid
      ).pipe(
          map((data: any[]) => data.sort((a,b) => a.position < b.position ? -1 : 1)
          .map((item: any) => new Result(
            item
            ),
          )))
  }

  deleteSessionResults(id: string, sessionid: string) : Observable<any> {
    this.setApis()
    return this.http.delete(this.dataApiUrl+'/'+id+'/result/'+sessionid)
  }

  getSessionResult(id: string, sessionid: string, driverid: string) : Observable<Result> {
    this.setApis()
    return this.http.get<Result>(this.dataApiUrl+'/'+id+'/result?sessionid='+sessionid+'&driverid='+driverid
      ).pipe(
          map((item: any) => new Result(
            item
            ),
          ))
  }

  scrapeSessionResults(id: string, sessionid: string) : Observable<any> {
    const headers = new HttpHeaders()
        .set("Content-Type", "application/json");
    this.setApis()

    return this.http.put<any>(this.dataApiUrl+'/'+id+'/results?sessionid='+sessionid, {}, {headers})
  }

  generateChampSchedules(id: string) : Observable<Schedule[]> {
    const headers = new HttpHeaders()
        .set("Content-Type", "application/json");
    this.setApis()

    return this.http.put<Schedule[]>(this.dataApiUrl+'/schedules/'+id, {}, {headers})
  }

  deleteChampSchedules(id: string) : Observable<any> {
    const headers = new HttpHeaders()
        .set("Content-Type", "application/json");
    this.setApis()

    return this.http.delete(this.dataApiUrl+'/schedules/'+id, {headers})
  }

  getCategory(id: string) : Observable<Category> {
    this.setApis()

    return this.http.get<Category>(this.contentApiUrl+'/category?category_id='+id
    ).pipe(
        map((item: any) => new Category(
          item.body
          ),
        ))
  }

  getCategories() : Observable<Category[]> {
    this.setApis()

    return this.http.get<Category[]>(this.contentApiUrl+'/category'
    ).pipe(
        map((data: any[]) => data.sort((a,b) => a.category_id < b.category_id ? -1 : 1)
        .map((item: any) => new Category(
          item.body
          ),
        )))
  }


  updateCategory(obj: any) : Observable<any> {
    this.setApis()

    const headers = new HttpHeaders()
        .set("Content-Type", "application/json");

    return this.http.put(this.contentApiUrl+'/category', obj, {headers})
  }


  getSources(type="text") : Observable<Source[]> {
    this.setApis()

    return this.http.get<Source[]>(this.contentApiUrl+'/source?type='+type
    ).pipe(
        map((data: any[]) => data.sort((a,b) => a.source_id < b.source_id ? -1 : 1)
        .map((item: any) => new Source(
          item.body
          ),
        )))
  }

  getSource(id: string) : Observable<Source> {
    this.setApis()

    return this.http.get<Source>(this.contentApiUrl+'/source?source_id='+id
    ).pipe(
        map((item: any) => new Source(
          item.body
          ),
        ))
  }

  updateSource(obj: any) : void {
    this.setApis()

    const headers = new HttpHeaders()
        .set("Content-Type", "application/json");

    this.http.put(this.contentApiUrl+'/source', obj, {headers})
        .subscribe(
            val => {
                console.log("PUT call successful value returned in body",
                            val);
            },
            response => {
                console.log("PUT call in error", response);
            },
            () => {
                console.log("Source has been updated.");
            }
        )
  }


  updateChamp(obj: string) : Observable<any> {

    const headers = new HttpHeaders()
        .set("Content-Type", "application/json");
    this.setApis()


    return this.http.put(this.dataApiUrl+'/champ', obj, {headers})

  }


  getSchedule(name: string) : Observable<Schedule> {

    this.setApis()
    return this.http.get<Schedule>(this.dataApiUrl+'/schedule/'+name)
    .pipe(
        map((item: any) => new Schedule(
          item
          ),
        ))
  }

  updateSchedule(obj: any) : Observable<any> {


    const headers = new HttpHeaders()
        .set("Content-Type", "application/json");
    this.setApis()
    //console.log("OBJ: ", obj)
    return this.http.put(this.dataApiUrl+'/schedule', obj, {headers})

  }

  toggleSchedule(obj: any) : Observable<any> {

    console.log("Sending schedule: ", obj)
    const headers = new HttpHeaders()
        .set("Content-Type", "application/json");
    this.setApis()

    return this.http.put<Schedule>(this.dataApiUrl+'/schedule?toggle=true', obj, {headers})
  }

  deleteSchedule(name: string) : Observable<Schedule> {

    const headers = new HttpHeaders()
        .set("Content-Type", "application/json");
    this.setApis()
    return this.http.delete<Schedule>(this.dataApiUrl+'/schedule/'+name, {headers})
  }

  getContentStatsBySource(source: Source, dateFrom: string) : Observable<ContentStats> {
    this.setApis()

    return this.http.get<ContentStats>(this.contentApiUrl+'/source-stats?source_id='+source.id+'&fromDate='+dateFrom
    ).pipe(
        map((item: any) => new ContentStats(
          item.source_id,
          item.category,
          item.type,
          item.from_date,
          item.last_date,
          item.total,
          item.last_updated
          ),
        ))
  }

  getContentStatsForSources(dateFrom: string) : Observable<ContentStats[]> {
    this.setApis()

    return this.http.get<ContentStats[]>(this.contentApiUrl+'/source-stats?fromDate='+dateFrom
    ).pipe(
      map((data: any[]) => data.sort((a,b) => a.source_id < b.source_id ? -1 : 1)
      .map((item: any) => new ContentStats(
          item.source_id,
          item.category_id,
          item.type,
          item.from_date,
          item.last_date,
          item.total,
          item.last_updated
          ),
        )))
  }

  getContentStatsByCategory(category: string, dateFrom: string) : Observable<ContentStats> {
    this.setApis()

    return this.http.get<ContentStats>(this.contentApiUrl+'/stats?category='+category+'&fromDate='+dateFrom
    ).pipe(
        map((item: any) => new ContentStats(
          item.source_id,
          item.category,
          item.type,
          item.from_date,
          item.last_date,
          item.total,
          item.last_updated
          ),
        ))
  }


  getContentStatsForCategories(dateFrom: string) : Observable<ContentStats[]> {
    this.setApis()

    return this.http.get<ContentStats[]>(this.contentApiUrl+'/category-stats?fromDate='+dateFrom
    ).pipe(
      map((data: any[]) => data.sort((a,b) => a.source_id < b.source_id ? -1 : 1)
      .map((item: any) => new ContentStats(
          item.source_id,
          item.category_id,
          item.type,
          item.from_date,
          item.last_date,
          item.total,
          item.last_updated
          ),
        )))
  }


  getContentStatsByType(type: string, dateFrom: string) : Observable<ContentStats> {
    this.setApis()

    return this.http.get<ContentStats>(this.contentApiUrl+'/content?type='+type+'&fromDate='+dateFrom
    ).pipe(
        map((item: any) => new ContentStats(
          item.source_id,
          item.category,
          item.type,
          item.from_date,
          item.last_date,
          item.total,
          item.last_updated
          ),
        ))
  }

  getContents(type: string, dateFrom: string, limit: number, category: string, source: string) : Observable<Content[]> {
    this.setApis()

    // var d = new Date();
    // let fromDateMs = d.getTime() - 1000 * 60 * 60 * 24 * daysAgo;  
    // d.setTime(fromDateMs)
    // let dateFrom = d.toISOString()
    // let dd = dateFrom.split('.')
    // dateFrom = dd[0]

    let args='type='+type
    if (dateFrom)
      args += '&fromDate='+dateFrom
    // if (limit)
    args += '&limit='+10000
    if (category)
      args += '&category_id='+category
    if (source)
      args += '&source_id='+source
    return this.http.get<Content[]>(this.contentApiUrl+'/articles?'+args
    ).pipe(
      map((data: any[]) => data['feed'].sort((a,b) => a.published > b.published ? -1 : 1)
      .map((item: any) => new Content(item, type))
        ))
    
  }

  getContent(id: string) : Observable<Content> {
    this.setApis()

    return this.http.get<Content>(this.contentApiUrl+'/article?id='+id
    ).pipe(
      
      map((item: any) =>  new Content(item, item['type']))
        )
  }

}


import { Component, OnInit, Input , Output, EventEmitter} from '@angular/core';
import { Equiv } from '../../../model/equiv'
import { BaseItemComponent } from '../base-item/base-item.component'
import { DataService } from '../../../services/data.service'
import { environment } from '../../../constants'
import { FormGroup, FormControl, FormsModule, Validators } from '@angular/forms'
import { AppUserAuth } from '../../../security/app-user-auth'
import { StringValidator } from '../base-item/string.validator'

@Component({
  selector: 'app-equiv-item',
  templateUrl: '../../../base-classes/abstract-base-item/abstract-base-item.component.html',
  styleUrls: ['../../../styles/global.scss','../../../base-classes/abstract-base-item/abstract-base-item.component.scss']
})
export class EquivItemComponent extends BaseItemComponent {

  @Input() public name: string
  item: Equiv

  setParams(params) {
    this.name = params['name']
  }

  getItem() {
    this.dataService.getEquiv(this.name)
    .subscribe(item => {
      console.log("ITEM = ", item)
      this.dataGetSuccess(item)
    })
  }

  disableField(field: string, ctrls: any) {
    if (field == 'name')
      ctrls[field].disable()
  }


  updateItem() {
    let st = this.item
    let cat = st.toJson()
    this.dataService.updateEquiv(cat)
    .subscribe(
      val => {
        this.dataSuccess(val, 'PUT')
      },
      err => this.dataError(err, 'PUT'),
      () => {}
    )
  }

  deleteItem() {
    let st = this.item
    this.dataService.deleteEquiv(st.name)
    .subscribe(
      val => this.dataSuccess(val, 'DELETE'),
      err => this.dataError(err, 'DELETE'),
      () => {}
    )
  }

  getValidators() {
    let vals = {}
    vals["name"] = [Validators.required, StringValidator.cannotContainSpace]
    vals["equiv"] = [Validators.required]
    return vals
  }

  getDisabledFields() {
    let list = ["name"]
    return list
  }

  getItemName() {
    return "Equivalent"
  }

  getItemNames() {
    return "Equivalents"
  }

  getItemId() {
    return this.name
  }

  getLinkToList() {
    return "/data/constants/equivs"
  }

  duplicateItem() {
    let newItem = new Equiv()
    newItem.copy(this.item)
    this.item = newItem
    this.updateForm()
  }


}

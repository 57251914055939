import { Component, OnInit, Input } from '@angular/core';
import { ScrapeStat } from '../../../model/scrapestat'
import { ItemListComponent } from '../item-list/item-list.component'
import { ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms'
import { StringValidator } from '../base-item/string.validator'

declare var require: any

@Component({
  selector: 'app-scrape-stats-list',
  templateUrl: '../item-list/item-list.component.html',
  styleUrls: ['../../../styles/global.scss','../item-list/item-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ScrapeStatsListComponent extends ItemListComponent {

  public champid: string

// OVERRIDDEN METHODS:

  setParams(params) {
    this.champid = params.id
  }

  getLinkToEdit(item) {
    // console.log("Item = ", item)
    return "/data/champ/"+this.champid+"/scrapestat/"+item.sessionid+'/'+item.acquiredstamp
  }

  getItemsName() {
    return this.champid.toUpperCase()+" Scrape Stats"
  }

  getItemName() {
    return this.champid.toUpperCase()+" Scrape Stat"
  }

  getItemId(item) {
    return item.sessionid+' - '+item.date
  }

  haveDeleteAllFeature() {
    return this.items.length > 0
  }


  addItemTHValues(tableid) {
    let str = ""
    let tab = document.getElementById(tableid)
    if (!tab)
      return
    let elem = this.addElement(tab, "thead", '')
    if (!elem)
      return
    while (elem.firstChild) {
      elem.removeChild(elem.lastChild)
    }
    this.addElement(elem, 'th', 'Champ')
    this.addElement(elem, 'th', 'Session')
    this.addElement(elem, 'th', 'Date')
    this.addElement(elem, 'th', 'Timestamp')
    this.addElement(elem, 'th', 'Type')
    this.addElement(elem, 'th', 'Message')
    this.addElement(elem, 'th', 'Rule trigger')
    this.addElement(elem, 'th', '')
  }


  addItemTDValues(item) {
    let elem = document.getElementById(this.getTableId())
    if (!elem)
      return
    let tr = this.addElement(elem, "tr", '')
    if (!tr)
      return
    this.addElement(tr, 'td', item.champid)
    tr.onclick = (event) => this.editItem(event, item)
    this.addElement(tr, 'td', item.sessionid)
    this.addElement(tr, 'td', item.date.split('.')[0])
    this.addElement(tr, 'td', item.acquiredstamp.split('.')[0])
    this.addElement(tr, 'td', item.type)
    this.addElement(tr, 'td', item.message.slice(0,10)+'...')
    this.addElement(tr, 'td', item.fields ? item.fields.rule_trigger : '')
    let btn = this.addDeleteButtonIcon(tr, 'td', this.canEdit())
    btn.onclick = (event) => this.askDeleteItem(event, item)
  }

  getItems() {
    if (this.champid === undefined)
      return
    document.body.style.cursor='wait';
    this.dataService.getScrapeStatsForChamp(this.champid)
        .subscribe(items => this.afterGetItems(items))
  }


  deleteItem(item) {
    document.body.style.cursor='wait';
    this.dataService.deleteScrapeStat(item.sessionid, item.acquiredstamp)
      .subscribe(res => this.afterSuccessfulDelete(res),
                 response => this.afterFailedDelete(response))
  }

  deleteAllItems() {
    document.body.style.cursor='wait';
    this.dataService.deleteScrapeStats(this.champid)
      .subscribe(res => this.afterSuccessfulDelete(res),
                 response => this.afterFailedDelete(response))
  }

  getValidators() {
    let vals = {}
    vals["sessionid"] = [Validators.required, StringValidator.cannotContainSpace]
    vals["timestamp"] = [Validators.required, StringValidator.cannotContainSpace]
    vals["date"] = [Validators.required]
    return vals
  }

  getDisabledFields() {
    let list = ["champid","sessionid"]
    return list
  }

  // getNewItem() {
  //   return
  // }
  //
  // createItem(form) {
  // }

  updateValues(cat: ScrapeStat, form: any) {
    let keys = this.getFormKeys(form)
    for (const key of keys) {
      if (form.get(key) instanceof UntypedFormControl) {
         cat[key] = form.get(key).value
      }
    }
  }

  haveCreateFeature() {
    return false
  }


// END: OVERRIDDEN METHODS


}

import { BaseItem } from './base-item'

export class FailedContent extends BaseItem {

  public type: string
  public acquiredstamp: string
  public acquireddate: string
  public reason_code: string
  public reason: string
  public source: string
  public content: string
  public url: string

  getMainParams(body) {
      this.type = body.type
      this.acquireddate = body.acquireddate
      this.reason_code = body.reason_code
      this.acquiredstamp = body.acquiredstamp
      this.reason = body.reason
      this.source = body.source
      this.content = body.body
      let tt = JSON.parse(this.content)
      this.url = tt['url']
  }

  setMainParams(body) {
    body.type = this.type
    body.acquireddate = this.acquireddate
    body.reason_code = this.reason_code
    body.acquiredstamp = this.acquiredstamp
    body.reason = this.reason
    body.source = this.source
    body.body = this.content
}

 
  // static getNewItem(id) {
  //   return new LiveSession({"champid": id, "sessionName": "", "id": "", "timestamp": ""})
  // }
}

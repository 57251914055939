import { Component, Input , Output, OnInit, EventEmitter } from '@angular/core'
import { Category } from '../../model/category'
import { AbstractBaseItemComponent } from '../../base-classes/abstract-base-item/abstract-base-item.component'

import { UntypedFormGroup } from '@angular/forms'

@Component({
  selector: 'app-category-item',
  templateUrl: '../../base-classes/abstract-base-item/abstract-base-item.component.html',
  styleUrls: ['../../styles/global.scss','../../base-classes/abstract-base-item/abstract-base-item.component.scss']
})
export class CategoryItemComponent extends AbstractBaseItemComponent   {

  categoryForm: UntypedFormGroup
  fieldForm: UntypedFormGroup
  state: string
  category: Category
  @Input() public id: string
  @Output() categoryUpdated: EventEmitter<Category> = new EventEmitter()

  setParams(params) {
    this.id = params['id']
  }

  getItem() {
    this.state = ''
    this.dataService.getCategory(this.id)
        .subscribe(item => {
          this.item = item
          console.log("Got category: ", item)
          this.dataGetSuccess(item)
        })

  }

  updateItem(item) {
    item.setValues()
    this.dataService.updateCategory(item)
      .subscribe(
        val => this.dataSuccess(val, 'PUT'),
        err => this.dataError(err, 'PUT'),
        () => {}         
      )
  }

  getLinkToList() {
    return "/content/categories"
  }

  getItemName() {
    return "Category Item"
  }

  getItemNames() {
    return "Categories"
  }

  getItemId() {
    return this.id
  }

  getDisabledFields() {
    return ['id']
  }

  disableField(field: string, ctrls: any) {
    if (field == 'id')
      ctrls[field].disable()
  }

  // updateCategory() {

  //   let ctrls = this.getControls(this.category)
  //   this.categoryForm = new UntypedFormGroup(ctrls)
  // }

  // getKeys() {
  //   let keys = this.getFormKeys(this.categoryForm)
  //   let newKeys = []
  //   for (const k of keys) {
  //     if (k != 'body')
  //       newKeys.push(k)
  //   }
  //   return newKeys
  // }

  isTextarea(key) {
    return key == 'keywords'
  }

  // addToCategory(type, value) {
  //   let cat = this.category
  //   if (type == 'string') {
  //     cat[value] = ''
  //   }
  //   else if (type == 'boolean') {
  //     cat[value] = false
  //   }
  //   this.updateCategory()
  // }

  // createFieldForm() {
  //   this.fieldForm = new UntypedFormGroup({
  //     type: new UntypedFormControl('string'),
  //     value: new UntypedFormControl(''),
  //  })
  // }

  // getControls(fields) {
  //   let ctrls = {}
  //   //console.log("FIelds = ", fields)
  //   for (const field in fields) {
  //     let subField = fields[field]
  //     if (typeof subField === 'string' || typeof subField === 'boolean' || subField === undefined) {
  //       if (field !== 'nm')
  //         ctrls[field] = new UntypedFormControl(subField)
  //     }
  //     else {
  //       let subCtrls = this.getControls(subField)
  //       ctrls[subField['nm']] = new UntypedFormGroup(subCtrls)
  //     }
  //   }
  //   return ctrls
  // }

  // capitalize(str: string) {
  //   return str.charAt(0).toUpperCase() + str.slice(1)
  // }

  // isFormGroup(obj: any) {
  //   return obj instanceof UntypedFormGroup
  // }

  // getFormKeys(group: any) {
  //   let keys = Object.keys(group.controls)
  //   return keys
  // }

  // isBool(key, group: UntypedFormGroup) {
  //   if (typeof group.value[key] == 'boolean')
  //     return true
  //   return false
  // }

  // addField() {
  //   let type = this.fieldForm.value.type
  //   let val = this.fieldForm.value.value
  //   console.log("Adding field: ", type, val)
  //   this.addToCategory(type, val)
  // }

  // save(): void {
  //   this.updateValues(this.category, this.categoryForm)

  //   let cat = this.category.toJson()
  //   //return
  //   this.dataService.updateCategory(cat)

  //   setTimeout(() => {  this.refreshList() }, 1000);
  //   //setTimeout(this.refreshList, 1000)
  // }

  // refreshList() {
  //   this.categoryUpdated.emit(this.category)
  // }

  // updateValues(cat: Category, form: any) {
  //   let keys = this.getFormKeys(form)
  //   for (const key of keys) {
  //     if (form.get(key) instanceof UntypedFormControl) {
  //        cat[key] = form.get(key).value
  //     }
  //   }
  // }

  // setValues(form: any) {
  //   let res = {}
  //   let keys = this.getFormKeys(form)
  //   for (const key of keys) {
  //     if (form.get(key) instanceof UntypedFormControl) {
  //       res[key] = form.get(key).value
  //     }
  //   }
  //   return res
  // }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router'
import { DataSideMenuComponent } from './data-menu/side-menu.component'
import { RaceSessionListComponent } from './race-session-list/race-session-list.component'
import { RaceItemComponent } from './race-item/race-item.component'
import { SessionItemComponent } from './session-item/session-item.component'
import { ChampRaceListComponent } from './champ-race-list/champ-race-list.component'
import { ChampDriverListComponent } from './champ-driver-list/champ-driver-list.component'
import { ChampTeamListComponent } from './champ-team-list/champ-team-list.component'
import { ChampScheduleItemComponent } from './champ-schedule-item/champ-schedule-item.component'
import { ChampScheduleListComponent } from './champ-schedule-list/champ-schedule-list.component'
import { StageItemComponent } from './constants/stage-item/stage-item.component'
import { StageListComponent } from './constants/stage-list/stage-list.component'
import { SessionResultListComponent } from './session-result-list/session-result-list.component'
import { ResultItemComponent } from './result-item/result-item.component'
import { DriverItemComponent } from './driver-item/driver-item.component'
import { TeamItemComponent } from './team-item/team-item.component'
import { ConstsListComponent } from './consts-list/consts-list.component'
import { CountryListComponent } from './constants/country-list/country-list.component'
import { CountryItemComponent } from './constants/country-item/country-item.component'
import { CityListComponent } from './constants/city-list/city-list.component'
import { CityItemComponent } from './constants/city-item/city-item.component'
import { EquivListComponent } from './constants/equiv-list/equiv-list.component'
import { EquivItemComponent } from './constants/equiv-item/equiv-item.component'
import { GpnameListComponent } from './constants/gpname-list/gpname-list.component'
import { GpnameItemComponent } from './constants/gpname-item/gpname-item.component'
import { ChampListComponent } from './constants/champ-list/champ-list.component'
import { ChampItemComponent } from './constants/champ-item/champ-item.component'
import { ChampLivesessionListComponent } from './constants/champ-livesession-list/champ-livesession-list.component'
import { ChampLivesessionItemComponent } from './constants/champ-livesession-item/champ-livesession-item.component'
import { NotificationListComponent } from './notification-list/notification-list.component'
import { ChampNotificationListComponent } from './champ-notification-list/champ-notification-list.component'
import { SessionNotificationListComponent } from './session-notification-list/session-notification-list.component'
import { RaceNotificationListComponent } from './race-notification-list/race-notification-list.component'
import { NotificationItemComponent } from './notification-item/notification-item.component'
import { ScrapeStatsListComponent } from './constants/scrape-stats-list/scrape-stats-list.component'
import { ScrapeStatsItemComponent } from './constants/scrape-stats-item/scrape-stats-item.component'
import { DataMenu } from './data-menu/data-menu'
import { AuthGuard } from '../security/auth.guard'

const  dataRoutes: Routes = [
  { path: '', component: DataMenu},
  { path: 'data', component: DataMenu,
  data: { breadcrumb: {alias: 'Data'} },
  children: [
      {
        path: '',
        outlet: 'sidemenu',
        component: DataSideMenuComponent
      },
      {
        path: 'champs',
        canActivate: [AuthGuard],
        component: ChampListComponent,
        data: {claimName: 'canAccessData', breadcrumb: {alias: 'Champs'} }
      },
      {
        path: 'champ/:id',
        canActivate: [AuthGuard],
        component: ChampItemComponent,
        data: {claimName: 'canAccessData', breadcrumb: {alias: 'Champ'} }
      },
      {
        path: 'scrapestat/:sessionid/:acquiredstamp',
        canActivate: [AuthGuard],
        data: {claimName: 'canAccessData'},
        component: ScrapeStatsItemComponent
      },

      {
        path: 'champ/:id',
        // canActivate: [AuthGuard],
        // data: {claimName: 'canAccessData', breadcrumb: {alias: 'Champ2'} },
        // component: ChampItemComponent,
        children: [
          {
            path: 'races',
            canActivate: [AuthGuard],
            data: {claimName: 'canAccessData', breadcrumb: {alias: 'Races'} },
            component: ChampRaceListComponent
          },
          {
            path: 'races/:raceid',
            // canActivate: [AuthGuard],
            // data: {claimName: 'canAccessData', breadcrumb: {alias: 'Champ2'} },
            // component: ChampItemComponent,
            children: [
              {
                path: 'notifications/:topic',
                canActivate: [AuthGuard],
                data: {claimName: 'canAccessData', breadcrumb: {alias: 'Notifications'} },
                component: RaceNotificationListComponent
              },
            ]
          },
          {
            path: 'drivers',
            canActivate: [AuthGuard],
            data: {claimName: 'canAccessData', breadcrumb: {alias: 'Drivers'} },
            component: ChampDriverListComponent
          },
          {
            path: 'driver/:driverid',
            canActivate: [AuthGuard],
            data: {claimName: 'canAccessData'},
            component: DriverItemComponent
          },
          {
            path: 'notifications/:topic',
            canActivate: [AuthGuard],
            data: {claimName: 'canAccessData'},
            component: ChampNotificationListComponent,
          },
          {
            path: 'teams',
            canActivate: [AuthGuard],
            data: {claimName: 'canAccessData'},
            component: ChampTeamListComponent
          },
          {
            path: 'team/:teamid',
            canActivate: [AuthGuard],
            data: {claimName: 'canAccessData'},
            component: TeamItemComponent
          },
          {
            path: 'sessions/:raceid',
            canActivate: [AuthGuard],
            data: {claimName: 'canAccessData'},
            component: RaceSessionListComponent
          },
          {
            path: 'race/:raceid',
            canActivate: [AuthGuard],
            data: {claimName: 'canAccessData'},
            component: RaceItemComponent
          },
          {
            path: 'schedule/:name',
            canActivate: [AuthGuard],
            data: {claimName: 'canAccessData'},
            component: ChampScheduleItemComponent
          },
          {
            path: 'schedules',
            canActivate: [AuthGuard],
            data: {claimName: 'canAccessData'},
            component: ChampScheduleListComponent
          },
          {
            path: 'stages',
            canActivate: [AuthGuard],
            data: {claimName: 'canAccessData'},
            component: StageListComponent
          },
          {
            path: 'stages',
            children: [
              {
                path: ':name/:location',
                canActivate: [AuthGuard],
                data: {claimName: 'canAccessData'},
                component: StageItemComponent
              },
            ]
          },
          {
            path: 'gpnames/:pattern',
            canActivate: [AuthGuard],
            data: {claimName: 'canAccessData'},
            component: GpnameItemComponent
          },
          {
            path: 'gpnames',
            canActivate: [AuthGuard],
            data: {claimName: 'canAccessData'},
            component: GpnameListComponent
          },
          {
            path: 'livesession/:id',
            canActivate: [AuthGuard],
            data: {claimName: 'canAccessData'},
            component: ChampLivesessionItemComponent
          },
          {
            path: 'livesessions',
            canActivate: [AuthGuard],
            data: {claimName: 'canAccessData'},
            component: ChampLivesessionListComponent
          },
          {
            path: 'scrapestats',
            canActivate: [AuthGuard],
            data: {claimName: 'canAccessData'},
            component: ScrapeStatsListComponent
          },
          {
            path: 'scrapestat/:sessionid/:acquiredstamp',
            canActivate: [AuthGuard],
            data: {claimName: 'canAccessData'},
            component: ScrapeStatsItemComponent
          },
          {
            path: 'race/:raceid',
            children: [
              {
                path: 'notifications/:topic',
                canActivate: [AuthGuard],
                data: {claimName: 'canAccessData'},
                component: RaceNotificationListComponent
              },
              {
                path: 'session/:sessionid',
                canActivate: [AuthGuard],
                data: {claimName: 'canAccessData'},
                component: SessionItemComponent
              },
              {
                path: 'session/:sessionid',
                children: [
                  {
                    path: 'results',
                    canActivate: [AuthGuard],
                    data: {claimName: 'canAccessData'},
                    component: SessionResultListComponent
                  },
                  {
                    path: 'result/:driverid',
                    canActivate: [AuthGuard],
                    data: {claimName: 'canAccessData'},
                    component: ResultItemComponent
                  },
                  {
                    path: 'scrapestat/:acquiredstamp',
                    canActivate: [AuthGuard],
                    data: {claimName: 'canAccessData'},
                    component: ScrapeStatsItemComponent
                  },
                  {
                    path: 'notifications/:topic',
                    canActivate: [AuthGuard],
                    data: {claimName: 'canAccessData'},
                    component: SessionNotificationListComponent
                  },
                ]
              },
              {
                path: 'sessions',
                canActivate: [AuthGuard],
                data: {claimName: 'canAccessData'},
                component: RaceSessionListComponent
              },
            ]
          }
        ]
      },
      // {
      //   path: 'data',
      //   canActivate: [AuthGuard],
      //   data: {claimName: 'canAccessData'},
      //   component: ChampListComponent,
      // },
      {
        path: 'notification/:topic/:datestamp',
        canActivate: [AuthGuard],
        data: {claimName: 'canAccessData'},
        component: NotificationItemComponent,
      },
      {
        path: 'notifications/:topic',
        canActivate: [AuthGuard],
        data: {claimName: 'canAccessData'},
        component: NotificationListComponent,
      },
      {
        path: 'constants',
        canActivate: [AuthGuard],
        data: {claimName: 'canAccessData'},
        component: ConstsListComponent,
      },
      {
        path: 'constants',
        children: [
          {
            path: 'countries',
            canActivate: [AuthGuard],
            data: {claimName: 'canAccessData'},
            component: CountryListComponent,
          },
          {
            path: 'countries/:id',
            canActivate: [AuthGuard],
            data: {claimName: 'canAccessData'},
            component: CountryItemComponent,
          },
          {
            path: 'cities',
            canActivate: [AuthGuard],
            data: {claimName: 'canAccessData'},
            component: CityListComponent,
          },
          {
            path: 'cities/:id',
            canActivate: [AuthGuard],
            data: {claimName: 'canAccessData'},
            component: CityItemComponent,
          },
          {
            path: 'equivs',
            canActivate: [AuthGuard],
            data: {claimName: 'canAccessData'},
            component: EquivListComponent,
          },
          {
            path: 'equivs/:name',
            canActivate: [AuthGuard],
            data: {claimName: 'canAccessData'},
            component: EquivItemComponent,
          },
        ]
      },
    ]
    }
]

@NgModule({
  imports: [
    RouterModule.forRoot(dataRoutes, { relativeLinkResolution: 'legacy' })
  ],
  exports: [ RouterModule ]
})
export class DataRoutingModule { }

import { Component, OnInit, Input, Output } from '@angular/core';
import { LiveSession } from '../../../model/livesession'
import { BaseItemComponent } from '../base-item/base-item.component'
import { DataService } from '../../../services/data.service'
import { Validators } from '@angular/forms'
import { StringValidator } from '../base-item/string.validator'

@Component({
  selector: 'app-champ-livesession-item',
  templateUrl: '../../../base-classes/abstract-base-item/abstract-base-item.component.html',
  styleUrls: ['../../../styles/global.scss','../../../base-classes/abstract-base-item/abstract-base-item.component.scss']
})
export class ChampLivesessionItemComponent extends BaseItemComponent {

  @Input() public id: string
  item: LiveSession

  setParams(params) {
    this.id = params['id']
  }

  getItem() {
    this.dataService.getLiveSession(this.id)
    .subscribe(item => {
      console.log("ITEM = ", item)
      this.dataGetSuccess(item)
    })
  }

  disableField(field: string, ctrls: any) {
    if (field == 'id' || field == 'type' || field == 'champid')
      ctrls[field].disable()
  }


  updateItem() {
    let st = this.item
    let cat = st.toJson()
    this.dataService.updateLiveSession(cat)
    .subscribe(
      val => {
        this.dataSuccess(val, 'PUT')
      },
      err => this.dataError(err, 'PUT'),
      () => {}
    )
  }

  deleteItem() {
    let st = this.item
    this.dataService.deleteLiveSession(st.id)
    .subscribe(
      val => this.dataSuccess(val, 'DELETE'),
      err => this.dataError(err, 'DELETE'),
      () => {}
    )
  }

  getValidators() {
    let vals = {}
    vals["timestamp"] = [Validators.required, StringValidator.cannotContainSpace]
    vals["updated"] = [Validators.required]
    vals["sessionName"] = [Validators.required, StringValidator.cannotContainSpace]
    return vals
  }

  getDisabledFields() {
    let list = ["champid", "id", "type"]
    return list
  }

  getItemName() {
    return this.item.champid.toUpperCase()+" Live Session"
  }

  getItemNames() {
    return this.item.champid.toUpperCase()+" Live Sessions"
  }

  getItemId() {
    return this.id
  }

  getLinkToList() {
    return "/data/livesession/"+this.id
  }

  duplicateItem() {
    let newItem = new LiveSession()
    newItem.copy(this.item)
    this.item = newItem
    this.updateForm()
  }


}

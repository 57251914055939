import { BaseItem } from './base-item'

export class Gpname extends BaseItem {

  public id: string
  public name: string
  public pattern: string

  setId(id) {
    this.id = id
  }

  getMainParams(body) {
      this.id = body.champid
      this.name = body.name
      this.pattern = body.pattern
  }

  setMainParams(body) {
      body.champid = this.id
      body.name = this.name
      body.pattern = this.pattern
  }

  copy(item: Gpname) {
    this.id = item.id
    this.name = item.name
    this.pattern = item.pattern+'copy'
  }

  static getNewItem(id) {
    return new Gpname({"champid": id, "pattern": "", "name": ""})
  }
}

import { ActivatedRoute } from '@angular/router'
import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataService } from '../../services/data.service';
import { Champ } from '../../model/champ'
import { ChampItemComponent } from '../constants/champ-item/champ-item.component'

import { trigger, state, style, animate, transition } from '@angular/animations'

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./data-menu.scss'],
  animations: [
    trigger('openClose', [
      state('open', style({height: '*', opacity: 1, visibility: 'visible'})),
      state('closed', style({height: '0px', opacity: 0, visibility: 'hidden'})),
      transition('open <=> closed',
        animate('200ms cubic-bezier(.37,1.04,.68,.98)')),
    ])
  ]
})
export class DataSideMenuComponent {

  champs: Champ[]
  isProd: boolean
  highlightedChamp: string
  timerId: any
  year: number
  // onUserChange= new Champ<void>();



  constructor(route: ActivatedRoute, private dataService : DataService) {

      route.params.subscribe(params => console.log("side menu id parameter",params['id']));

  }

  ngOnInit() {
    this.isProd = localStorage.getItem('env') == 'prod'
    this.highlightedChamp = localStorage.getItem('highlightedChamp')
    this.getChamps()
    this.timerId = setInterval(() => {
      this.getChamps()
    }, 30000); // Activate every 30 secs
  }

  getYear() {
    let y = localStorage.getItem("year")
    if (y !== undefined && y !== '0')
      this.year =Number(y)
 }

  getChamps() {
    this.getYear()
    this.dataService.getChamps(this.year)
        .subscribe(champs => {
          this.champs = champs
          console.log("Champs: ", champs)
          this.getLiveSessions()
        })
  }

  openSubMenu(id) {
    this.highlightedChamp = id
    localStorage.setItem('highlightedChamp', this.highlightedChamp)
  }

  isOpenSubMenu(id) {
    return id == this.highlightedChamp
  }


  getLiveSessions() {
    this.dataService.getLiveSessions()
        .subscribe(liveItems => {
          this.champs.forEach((champ) => this.setLiveSession(liveItems.filter((item) => { return (item.champid == champ.id) }), champ))
          this.getScrapeStatus()
          })
  }

  setLiveSession(liveItems, champ) {
    let isLiveSession = false
    //console.log("Champ ", champ.id, ", live: ", liveItems)
    if (liveItems) {
      let latestInfo = liveItems[0]
      let isLiveSession = latestInfo && !['Finished', 'Not started', 'Cancelled'].includes(latestInfo.status)
      if (isLiveSession) {
        champ.liveSession = latestInfo.session
      }
    }
  }

  perhapsShowLiveLinks(champ: Champ) {
    let val = ''
    //console.log("CHamp: ", champ)
    if (champ.liveSession) {
      val += '<i class="fa fa-bolt"></i> '
      val += (champ.scrapeStatus) ? '<i style="color: green" class="far fa-check-circle"></i>' : '<i style="color: red" class="fas fa-exclamation-circle"></i>'
    }
    let el = document.getElementById('title'+champ.id)
    if (!el)
      return el
    el.innerHTML = ' '+val
    if (champ.liveSession)
      (<HTMLLinkElement>el).href = "/data/champ/"+champ.id+"/race/"+champ.liveSession.race_id+"/sessions"
  }




  getScrapeStatus() {
    this.dataService.getScrapeStats()
        .subscribe(scrapeItems => this.setScrapeStatus(scrapeItems))
  }

  setScrapeStatus(scrapeItems) {
    this.champs.forEach((champ) => {
      let scrapeStatus = true
      if (!('liveSession' in champ) || !champ.liveSession)
        //champ.scrapeStatus = scrapeStatus
        return
      //console.log("Live session = ", champ.liveSession)
      let sessionid = champ.liveSession.sessionid
      if (scrapeItems && scrapeItems.length > 0) {
        for (const info of scrapeItems) {
          if (info.sessionid == sessionid && 'type' in info && info.champ == champ.id) {
            scrapeStatus = !info.type.toLowerCase().includes('failed')
            break
          }
        }
      }
      champ.scrapeStatus = scrapeStatus
    })
  }



}

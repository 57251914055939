<div class="panel panel-primary">

  <h5 class="panel-title">{{ getTitle() }}</h5>
  <div class="panel-body">
    <div class="col-sm-12">


      <div id="topdiv" *ngIf="state == 'ready'">
        <button (click)="refresh()" class="btn flushright">
          <i class="fa fa-refresh"></i>
        </button>
        <table *ngIf="items && items.length > 0" id="{{ getTableId() }}" class="items">
        </table>
        <p *ngIf="!items || items.length === 0" class="status-message">
          No {{ getItemsName() }} found.
        </p>
      </div>

      <div *ngIf="state !== 'ready'">
        <button (click)="refresh()" class="btn flushright">
          <i class="fa fa-refresh" aria-hidden="true"></i>
        </button>
        <p *ngIf="!items" class="loading-message">
          Loading {{ getItemsName() }}...
        </p>
      </div>

    </div>
  </div>

  <mat-paginator *ngIf="state == 'ready' && maxItems > numOnPage" 
    [length]="this.maxItems" 
    [pageSize]="this.numOnPage" 
    [pageIndex]="this.page"
    (page)="handlePageChange($event)"
    [showFirstLastButtons]="true"> 
  </mat-paginator>


  <form [formGroup]="form" *ngIf="haveScrapeItemsFeature() && state === 'ready'" (ngSubmit)="askScrapeItems(event)" class="form-horizontal">
    <h5 class="panel-title">Scrape {{ getItemsName() }}</h5>
    <table id="getTableId()" class="scrape">
      <tr>
      <td>
        <label class="control-label">Start {{ getIndexName() }} </label>
        <input class="numbox" (change)='onStartIndexChanged()' formControlName="start"  type="number" min="1" >
      </td>
      <td>
        <label class="control-label">Num {{ getIndexesName() }} </label>
        <input class="numbox" id="numC" formControlName="num"  type="number" min="1" >
      </td>
      <td *ngIf="haveExtraInfo()">
        <!-- <input formControlName="extrainfo" name="extra" type="checkbox"> -->
        <label class="control-label">Use extra info</label>
        <label class="switch">
          <input class="switch" formControlName="extrainfo" name="extrainfo" type="checkbox" >
          <span class="slider round"></span>
        </label>
      </td>
      <td>
        <label class="control-label">Quiet</label>
        <label class="switch">
          <input class="switch" formControlName="quiet" name="quiet" type="checkbox" >
          <span class="slider round"></span>
        </label>
      </td>
      <td>
        <button type="submit" class="btn btn-primary">Scrape</button>
      </td>
      </tr>
    </table>
  </form>

  <div *ngIf="state === 'ready'">
    <button id="scrapeAllButton" *ngIf="haveScrapeAllFeature()" (click)="askScrapeAllItems()" class="btn btn-primary">Scrape {{ getItemsName() }}</button>
    <button id="deleteAllButton" *ngIf="haveDeleteAllFeature()" (click)="askDeleteAllItems()" class="btn btn-primary">Delete all {{ getItemsName() }}</button>
    <button id="extraFeatureButton" *ngIf="haveExtraFeature()" (click)="askRunExtraFeature()" class="btn btn-primary">{{ extraFeatureName() }}</button>
  </div>

</div>

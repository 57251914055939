<div class="panel panel-primary">

  <h5 *ngIf="state === 'ready'" class="panel-title">{{ getItemName() }} Fields for {{ getItemId() }}</h5>
  <div id="special-area"></div>

  <button (click)="refresh()" class="btn flushright">
    <i class="fa fa-refresh" aria-hidden="true"></i>
  </button>

  <div *ngIf="state !== 'ready'">
    <p *ngIf="!items" class="loading-message">
      Loading {{ getItemsName() }}...
    </p>
  </div>

  <form id="form-{{ id }}" [formGroup]="form" *ngIf="state === 'ready'" (ngSubmit)="save()" class="form-horizontal">
  <div class="panel-body">
    <div class="col-sm-12">
      <table >
        <tr *ngFor="let key of getKeys()" class="field form-group">
          <!-- <div *ngIf="!['articlePage', 'mainListing'].includes(key) && key !== 'type'"> -->
            <td class="label">
              <label class="control-label" for="{{ key }}">{{ capitalize(key) }}</label>
            </td>
            <td class="col-sm-6">
              <input *ngIf="isBool(key, this.form)" id="{{ key }}" formControlName="{{ key }}" type="checkbox" class="checkbox form-control">
              <textarea *ngIf="isText(key, this.form)" id="{{ controlId(key) }}" formControlName="{{ key }}" rows="4" class="text form-control"></textarea>
              <input *ngIf="isOther(key, this.form)" id="{{ controlId(key) }}" formControlName="{{ key }}" type="text"  class="text form-control">
              <div *ngIf="invalid(key)" class="alert alert-danger">
                   {{ validationError(key)}}
               </div>
            </td>
          <!-- </div> -->
        </tr>
      </table>
    </div>
  </div>

  <div *ngIf="canEdit()" class="panel-footer">
    <button *ngIf="haveUpdateButton()" class="btn btn-primary">Submit changes above</button>
    <button *ngIf="haveButton(1)" (click)="goToButton(1)" class="btn btn-primary">{{ getButtonDesc(1) }}</button>
    <button *ngIf="haveButton(2)" (click)="goToButton(2)" class="btn btn-primary">{{ getButtonDesc(2) }}</button>
    <button *ngIf="haveButton(3)" (click)="goToButton(3)" class="btn btn-primary">{{ getButtonDesc(3) }}</button>
    <button (click)="goBack()" class="btn btn-primary">Cancel</button>
  </div>

</form>

</div>


<div *ngIf="haveAddFields() && state === 'ready' && canEdit()">
  <h5 class="panel-title">Add Field</h5>

  <form [formGroup]="fieldForm" *ngIf="state === 'ready' " (ngSubmit)="addField()" class="form-horizontal">
    <label class="control-label">Add Field: </label>
    <select formControlName="type" name="type">
      <option value="boolean">Boolean</option>
      <option value="string">String</option>
    </select>
    <input formControlName="value" name="value" type="text" placeholder="Name of field">
    <button class="btn btn-primary">Add</button>
  </form>

  <hr/>

  <button *ngIf="state === 'ready'" class="btn btn-primary" [disabled]="!canEdit()" (click)="duplicateItem()" >Duplicate {{ getItemName() }}</button>

  <hr/>

  <button *ngIf="state === 'ready'" class="delbtn" [disabled]="!canEdit()" (click)="deleteItem()" >Delete This {{ getItemName() }}</button>
</div>

import { BaseItem } from './base-item'

export class City extends BaseItem {

  public name: string
  public countryCode: string
  public countryName: string
  public timezone: number

  getMainParams(body) {
      this.name = body.city_name
      this.countryCode = body.country_code
      this.countryName = body.country_name
      this.timezone = body.timezone
  }

  setMainParams(body) {
      body.country_code = this.countryCode
      body.country_name = this.countryName
      body.city_name  = this.name
      body.timezone = this.timezone
  }

  copy(item: City) {
    this.name = item.name+'-copy'
    this.countryName = item.countryName
    this.countryCode = item.countryCode
    this.timezone = item.timezone
  }

  static getNewItem() {
    return new City({"city_name": "", "country_code": "", "country_name": ""})
  }
}

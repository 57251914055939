
<!-- <app-source-list></app-source-list> -->
<!-- <div class="container">


<ul ngbNav #nav="ngbNav" class="nav-tabs">
  <li ngbNavItem>
    <a ngbNavLink routerLinkActive="active" routerLink="content-sources">Content by Source</a>
  </li>
  <li ngbNavItem>
    <a ngbNavLink routerLinkActive="active" routerLink="content-categories">Content by Category</a>
  </li>
</ul> -->


<!-- </div> -->

<main>
    <div class="container">
        <div class="row row-offcanvas row-offcanvas-right">
            <div class="col-xs-12 col-sm-9">
                <xng-breadcrumb class="breadcrumb" [separator]="iconTemplate"></xng-breadcrumb>
                <ng-template #iconTemplate>
                  >
                </ng-template>
                  <div class="jumbotron">
                    <h1>Content Feed</h1>
                    <p>Manage Content Scraping</p>
                </div>
                <router-outlet ></router-outlet>
            </div>
            <div class="col-xs-12 col-sm-3">
              <router-outlet name="sidemenu"></router-outlet>
            </div>
        </div>
    </div>
</main>

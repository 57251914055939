import { Component } from '@angular/core';
import { AbstractItemListComponent } from '../../base-classes/abstract-item-list/abstract-item-list.component'
import { ViewEncapsulation } from '@angular/core';

declare var require: any

@Component({
  selector: 'app-content-list',
  templateUrl: '../../base-classes/abstract-item-list/abstract-item-list.component.html',
  styleUrls: ['../../styles/global.scss','../../base-classes/abstract-item-list/abstract-item-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContentListComponent extends AbstractItemListComponent {

  public type: string


// OVERRIDDEN METHODS:

  setParams(params) {
    this.daysBack = 5
    console.log("Setting params: ", params['type'], this.daysBack)
    this.isProd = localStorage.getItem('env') == 'prod'
  }

  setTitle() {
    this.pageTitle += "Content list"
  }

  getLinkToEdit(item) {
    // console.log("Item = ", item)
    let ln = "/content/content/"+this.getItemId(item)
    console.log("Link = ", ln)
    return ln
  }

  haveScrapeItemsFeature() {
    return false
   }
 
  getItemsName() {
    return "Content"
  }

  getItemName() {
    return "Content"
  }

  getItemId(item) {
    return item.id
  }

  getYear() {

  }

  createSelectFilters() {
    this.selectFilters = []
    let fil = {
      'name': 'Type',
      'id': 'type',
      'selected': 'all',
      'options': [{'name': 'Text', 'id': 'text'},{'name': 'Video', 'id': 'video'}]
    }
    this.selectFilters.push(fil)
    fil = {
      'name': 'Category',
      'id': 'category',
      'selected': 'all',
      'options': []
    }
    this.selectFilters.push(fil)
    fil = {
      'name': 'Source',
      'id': 'source',
      'selected': 'all',
      'options': []
    }
    this.selectFilters.push(fil)
  }

  getFilterList(index) {
    if (!this.haveSelectFilters())
      return


    document.body.style.cursor='wait';
    let fil = this.selectFilters[index]
    if (fil['id'] == 'category') {
      this.dataService.getCategories()
          .subscribe(items => {
            //console.log("got categories", items)
            this.postGetFilterOptions(index, fil, items)
          })
    }
    else if (fil['id'] == 'source') {
      let type = this.getFilterSelectedOption('type')
      console.log("type selection = ", type)
      this.dataService.getSources(type)
          .subscribe(items => {
            //console.log("got sources", items)
            this.postGetFilterOptions(index, fil, items)
          })
    }
    else if (fil['id'] == 'type') {
      index++
      if (index >= this.selectFilters.length)
        this.getItems()
      else
        this.getFilterList(index)
    }      
  }

  addItemTHValues(tableid) {
    let str = ""
    let tab = document.getElementById(tableid)
    if (!tab) {
      console.log("No elem", tableid)
      return
    }
    let elem = this.addElement(tab, "thead", '')
    if (!elem)
      return
    while (elem.firstChild) {
      elem.removeChild(elem.lastChild)
    }
    this.addElement(elem, 'th', 'source')
    this.addElement(elem, 'th', 'headline')
    this.addElement(elem, 'th', 'published')
    this.addElement(elem, 'th', 'categories')
    this.addElement(elem, 'th', '# images')
    this.addElement(elem, 'th', '')
  }


  addItemTDValues(item) {
    let elem = document.getElementById(this.getTableId())
    if (!elem) {
      console.log("No elem", this.getTableId())
      return
    }
    let tr = this.addElement(elem, "tr", '', 'content-row')
    if (!tr) 
      return
    // this.addElement(tr, 'td', this.getItemId(item))
    if (this.getItemId(item))
      tr.onclick = (event) => this.editItem(event, item)
    this.addElement(tr, 'td', item.sourceid)
    this.addElement(tr, 'td', item.headline)
    this.addElement(tr, 'td', item.published)
    this.addElement(tr, 'td', item.categories)
    this.addElement(tr, 'td', item.images.length)
    let btn = this.addDeleteButtonIcon(tr, 'td', this.canEdit())
    btn.onclick = (event) => this.askDeleteItem(event, item)
  }


  getItems() {
    // console.log("Limit = ", this.limit)
    if (this.dateFrom && this.limit) {
      document.body.style.cursor='wait';
      let catFilter = this.getFilterSelectedOption('category')
      let srcFilter = this.getFilterSelectedOption('source')
      let typeFilter = this.getFilterSelectedOption('type')
      console.log("Type = ", typeFilter)
      catFilter = (catFilter == 'all') ? '' : catFilter
      srcFilter = (srcFilter == 'all') ? '' : srcFilter
      console.log("cat filter = ", catFilter, ", src filter = ", srcFilter, ", days ", this.dateFrom)
      this.dataService.getContents(typeFilter, this.dateFrom, this.limit, catFilter, srcFilter)
          .subscribe(items => {
            items = items.slice(0,this.limit)
            // console.log("got items", items)
            this.afterGetItems(items)
          })
    }
    else {
      console.log("not updating get", this.dateFrom, this.limit)
    }
  }

  // deleteItem(item) {
  //   document.body.style.cursor='wait';
  //   this.dataService.deleteSession(this.id, this.getItemId(item))
  //   // this.dataService.deleteChamp(this.getItemId(item))
  //     .subscribe(res => this.afterSuccessfulDelete(res),
  //                response => this.afterFailedDelete(response))
  // }


  haveDeleteAllFeature() {
    return false
  }


  haveFilter() {
    return true
  }

  haveDateFilter(): boolean {
    return true
  }
// END: OVERRIDDEN METHODS


}

import { Component, OnInit, Input } from '@angular/core';
import { environment } from '../../constants'
import { Notification } from '../../model/notification'
import { ItemListComponent } from '../constants/item-list/item-list.component'
import { ViewEncapsulation } from '@angular/core';
import { FormGroup, UntypedFormControl, FormsModule, Validators } from '@angular/forms'
import { StringValidator } from '../constants/base-item/string.validator'

@Component({
  selector: 'app-champ-notification-list',
  templateUrl: '../constants/item-list/item-list.component.html',
  styleUrls: ['../../styles/global.scss','../constants/item-list/item-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChampNotificationListComponent extends ItemListComponent {

  public topic: string
  public champid: string


// OVERRIDDEN METHODS:

  setParams(params) {
    this.topic = params.topic
    this.champid = params.id
  }

  haveCreateFeature() {
    return false
  }

  setTitle() {
    this.pageTitle += "Champ Notification list"
  }

  getLinkToEdit(item) {
    // console.log("Item = ", item)
    return "/data/notification/"+this.topic+"/"+this.getItemId(item)
  }

  getItemsName() {
    return this.champid.toUpperCase()+" Notifications"
  }

  getItemName() {
    return this.champid.toUpperCase()+" Notification"
  }

  getItemId(item) {
    return item.datestamp
  }

  getTableId(): string {
    return "table-"+this.champid+"champ-notification"
  }

  addItemTHValues(tableid) {
    let str = ""
    let tab = document.getElementById(tableid)
    if (!tab)
      return
    let elem = this.addElement(tab, "thead", '')
    if (!elem)
      return
    while (elem.firstChild) {
      elem.removeChild(elem.lastChild)
    }
    this.addElement(elem, 'th', 'DateStamp')
    this.addElement(elem, 'th', 'Date')
    this.addElement(elem, 'th', 'Stage')
    this.addElement(elem, 'th', 'Title')
    this.addElement(elem, 'th', '')
  }

  addItemTDValues(item) {
    let elem = document.getElementById(this.getTableId())
    if (!elem)
      return
    let tr = this.addElement(elem, "tr", '')
    if (!tr)
      return
    this.addElement(tr, 'td', this.getItemId(item))
    tr.onclick = (event) => this.editItem(event, item)
    let dt = item.date
    if (!dt)
      dt = new Date(item.datestamp).toLocaleString()
    this.addElement(tr, 'td', dt)
    this.addElement(tr, 'td', item.stage_id)
    this.addElement(tr, 'td', item.title)
    let btn = this.addDeleteButtonIcon(tr, 'td', this.canEdit())
    btn.onclick = (event) => this.askDeleteItem(event, item)
  }

  getItems() {
    document.body.style.cursor='wait';
    console.log("CALLING ", this.topic, this.champid)
    this.dataService.getChampNotifications(this.topic, this.champid)
        .subscribe(items => this.afterGetItems(items))
  }


  deleteItem(item) {
    document.body.style.cursor='wait';
    // this.dataService.deleteNotification(this.topic, this.getItemId(item))
    //   .subscribe(res => this.afterSuccessfulDelete(res),
    //              response => this.afterFailedDelete(response))
  }

  getValidators() {
    let vals = {}
    return vals
  }

  getDisabledFields() {
    let list = []
    return list
  }

  haveDeleteAllFeature() {
    return false
  }

// END: OVERRIDDEN METHODS


}

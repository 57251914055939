declare var require: any
import { BaseItem } from './base-item'

export class Driver extends BaseItem{

  public name: string
  public abbrev: string
  public id: string
  public country_code: string
  public team: string
  public points: Number
  public position: Number
  public country: string
  public api_id: string
  public year: string
  public result: any

  // constructor (
  //   public body: string) {
  //     //console.log("body = ", body)
  //     this.fromJson(body)
  // }

  fromJson(body) {
    this.getDriver(body)
  }

  setMainParams(body) {
    body.id = this.id
    body.position = Number(this.position)
    body.name  = this.name
    body.abbrev = this.abbrev
    body.country_code = this.country_code
    body.points = this.points
    body.team = this.team
    body.country = this.country
    body.api_id = this.api_id
    body.year = this.year
    body.result = this.result
}


  // toJson() {
  //   let res = this.setCategory()
  //   return JSON.stringify(res)
  // }

  getDriver(body) {
      this.id = body.id
      this.position =  body.position
      this.name = body.name
      this.abbrev = body.abbrev
      this.country_code = body.country_code
      this.points = body.points
      this.team = body.team
      this.country = body.country
      this.api_id = body.api_id
      this.year = body.year
      this.result = body.result
  }


}

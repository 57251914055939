import { ActivatedRoute } from '@angular/router'
import { Component, OnInit } from '@angular/core';
// import { NgbNavModule, NgbNav } from '@ng-bootstrap/ng-bootstrap'
import { DataService } from '../../services/data.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Source } from '../../model/source';
import { Category} from '../../model/category'

import { trigger, state, style, animate, transition } from '@angular/animations'

@Component({
  selector: 'app-content-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./content-menu.scss'],
  animations: [
    trigger('openClose', [
      state('open', style({height: '*', opacity: 1, visibility: 'visible'})),
      state('closed', style({height: '0px', opacity: 0, visibility: 'hidden'})),
      transition('open <=> closed',
        animate('200ms cubic-bezier(.37,1.04,.68,.98)')),
    ])
  ]
})
export class SideMenuComponent {

  isProd: boolean
  sources: Source[]
  textSources: Source[]
  videoSources: Source[]
  categories: Category[]
  highlightedMenu: string
  sourceType: string


  constructor(route: ActivatedRoute,
              private dataService : DataService) {

      route.params.subscribe(params => console.log("side menu id parameter",params['id']));

  }

  getSources(type) {
    this.dataService.getSources(type)
        .subscribe(sources => {
          if (type == 'text')
            this.textSources = sources
          if (type == 'video')
            this.videoSources = sources
        })
  }

  getCategories() {
    this.dataService.getCategories()
        .subscribe(categories => {
          this.categories = categories
        })
  }


  openSubMenu(item) {
    if (this.highlightedMenu == item) 
      this.highlightedMenu = null
    else 
      this.highlightedMenu = item
    localStorage.setItem('highlightedMenu', this.highlightedMenu)
  }

  isOpenSubMenu(item) {
    return item == this.highlightedMenu
  }

  ngOnInit() {
    this.isProd = localStorage.getItem('env') == 'prod'
    this.highlightedMenu = localStorage.getItem('highlightedMenu')
    console.log("Highlighted = ", this.highlightedMenu)
    this.getSources('text')
    this.getSources('video')
    this.getCategories()

  }

}

import { Component } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { AbstractItemListComponent } from '../../base-classes/abstract-item-list/abstract-item-list.component'

@Component({
  selector: 'app-category-list',
  templateUrl: '../../base-classes/abstract-item-list/abstract-item-list.component.html',
  styleUrls: ['../../styles/global.scss','../../base-classes/abstract-item-list/abstract-item-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class CategoryListComponent extends AbstractItemListComponent  {

  setTitle() {
    this.pageTitle += "Content categories"
  }

  getItems() {
    this.dataService.getCategories()
        .subscribe(items => {
            this.afterGetItems(items)
        })
  }

  getItemsName() {
    return "Categories"
  }

  getItemName() {
    return "Category"
  }

  getItemId(item) {
    return item.id
  }

  getLinkToEdit(item) {
    return "/content/categories/"+this.getItemId(item)
  }

  getTableId() {
    return "table-categories"
  }

  addItemTHValues(tableid) {
    let str = ""
    let tab = document.getElementById(tableid)
    if (!tab) {
      console.log("No elem", tableid)
      return
    }
    let elem = this.addElement(tab, "thead", '')
    if (!elem)
      return
    while (elem.firstChild) {
      elem.removeChild(elem.lastChild)
    }
    this.addElement(elem, 'th', 'id')
    this.addElement(elem, 'th', 'name')
    this.addElement(elem, 'th', 'keywords')
    this.addElement(elem, 'th', '')
  }


  addItemTDValues(item) {
    let elem = document.getElementById(this.getTableId())
    if (!elem) {
      console.log("No elem", this.getTableId())
      return
    }
    let tr = this.addElement(elem, "tr", '')
    if (!tr) 
      return
    // this.addElement(tr, 'td', this.getItemId(item))
    if (this.getItemId(item))
      tr.onclick = (event) => this.editItem(event, item)
    this.addElement(tr, 'td', item.id)
    this.addElement(tr, 'td', item.name)
    this.addElement(tr, 'td', item.keywords)
    let btn = this.addDeleteButtonIcon(tr, 'td', this.canEdit())
    btn.onclick = (event) => this.askDeleteItem(event, item)
  }

  haveDeleteAllFeature() {
    return false
  }


}

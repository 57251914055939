import { Component, OnInit, Input , Output, EventEmitter} from '@angular/core';
import { Stage } from '../../../model/stage'
import { BaseItemComponent } from '../base-item/base-item.component'
import { DataService } from '../../../services/data.service'
import { environment } from '../../../constants'
import { FormGroup, FormControl, FormsModule, Validators } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { AppUserAuth } from '../../../security/app-user-auth'
import { SecurityService } from '../../../security/security.service'
import { StringValidator } from '../base-item/string.validator'

@Component({
  selector: 'app-stage-item',
  templateUrl: '../../../base-classes/abstract-base-item/abstract-base-item.component.html',
  styleUrls: ['../../../styles/global.scss','../../../base-classes/abstract-base-item/abstract-base-item.component.scss']
})
export class StageItemComponent extends BaseItemComponent {

  @Input() public abbrev: string
  @Input() public location: string
  @Input() public id: string
  item: Stage

  setParams(params) {
    this.abbrev = params['name']
    this.location = params['location']
    this.id = params['id']
  }

  getItem() {
    this.dataService.getStage(this.id, this.abbrev, this.location)
    .subscribe(item => {
      console.log("ITEM = ", item)
      this.dataGetSuccess(item)
    })
  }

  disableField(field: string, ctrls: any) {
    if (field == 'abbrev' || field == 'id' || field == 'location' || field == 'sort')
      ctrls[field].disable()
  }


  updateItem() {
    let st = this.item
    st['sort'] = st['abbrev']+'#'+st['location']
    let cat = st.toJson()
    console.log(cat)
    this.dataService.updateStage(st.id, cat)
    .subscribe(
      val => {
        this.location = st['location']
        this.dataSuccess(val, 'PUT')
      },
      err => this.dataError(err, 'PUT'),
      () => {}
    )
  }

  deleteItem() {
    let st = this.item
    this.dataService.deleteStage(st.id, st.abbrev, st.location)
    .subscribe(
      val => this.dataSuccess(val, 'DELETE'),
      err => this.dataError(err, 'DELETE'),
      () => {}
    )
  }

  getValidators() {
    let vals = {}
    vals["location"] = [Validators.required, StringValidator.cannotContainSpace]
    vals["id"] = [Validators.required, StringValidator.cannotContainSpace]
    vals['abbrev'] = [Validators.required, StringValidator.cannotContainSpace]
    vals['sessionType'] = [Validators.required, StringValidator.cannotContainSpace]
    vals['name'] = [Validators.required]
    return vals
  }

  getDisabledFields() {
    let list = ["id", "abbrev", "sort"]
    return list
  }

  getItemName() {
    return this.id.toUpperCase()+" Stage"
  }

  getItemNames() {
    return this.id.toUpperCase()+" Stages"
  }

  getItemId() {
    return this.abbrev+'#'+this.location
  }

  getLinkToList() {
    return "/data/champ/"+this.id+"/stages"
  }

  duplicateItem() {
    let newItem = new Stage()
    newItem.copy(this.item)
    this.item = newItem
    this.updateForm()
  }


}

import { Component, OnInit, Input , Output, EventEmitter} from '@angular/core';
import { BaseItem } from '../../model/base-item'
import { DataService } from '../../services/data.service'
import { environment } from '../../constants'
import { AbstractBaseItemComponent } from '../../base-classes/abstract-base-item/abstract-base-item.component'

@Component({
  selector: 'app-scrape-base-item',
  templateUrl: '../../base-classes/abstract-base-item/abstract-base-item.component.html',
  styleUrls: ['../../styles/global.scss','../../base-classes/abstract-base-item/abstract-base-item.component.scss']
})
export class ScrapeBaseItemComponent extends AbstractBaseItemComponent {

  year: Number

  setTitle() {
    this.pageTitle += this.getItemName()+" Fields: "+this.id.toUpperCase()
  }

  dataGetSuccess(item) {
    this.item = item
    this.addSpecialArea('special-area')
    this.updateForm()
    this.state = "ready"
  }

  getYear() {
     let y = localStorage.getItem("year")
     if (y !== undefined && y !== '0')
       this.year =Number(y)
     else
      this.year = this.getDefaultYear()
    console.log("Year = "+this.year)
  }

  refresh() {
    this.getYear()
    super.refresh()
  }

  getDefaultYear() {
    return (new Date()).getFullYear()
  }

}

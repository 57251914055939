import { Component, OnInit } from '@angular/core';
import { Source } from '../../model/source';
import { Observable } from 'rxjs';
import { MatTableModule } from '@angular/material/table'
import { MatSortModule } from '@angular/material/sort';
import { Router } from '@angular/router'

import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { BreadcrumbModule } from "xng-breadcrumb";

@Component({
  selector: 'app-content-menu',
  templateUrl: './content-menu.html',
  styleUrls: ['./content-menu.scss']
})
export class ContentMenu  {

  constructor(private router: Router) {

  }

  ngOnInit(): void {
    // this.router.navigate(['/content/contents/text'])
  }

}

import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { Champ } from '../../model/champ'

@Component({
  selector: 'app-consts',
  templateUrl: './consts-list.component.html',
  styleUrls: ['./consts-list.component.scss']
})
export class ConstsListComponent implements OnInit {

  champs: Champ[]
  year: number

  constructor(private dataService : DataService) { }

  ngOnInit() {
    this.getChamps()
  }

  getYear() {
    let y = localStorage.getItem("year")
    if (y !== undefined && y !== '0')
      this.year =Number(y)
  } 

  getChamps() {
    this.getYear()
    this.dataService.getChamps(this.year)
        .subscribe(champs => {
          this.champs = champs
        })
  }

}

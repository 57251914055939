import { BaseItem } from './base-item'

export class Stage extends BaseItem {

  public id: string
  public name: string
  public sessionType: string
  public abbrev: string
  public duration: Number
  public liveName: string
  public archiveName: string
  public location: string
  public notLive: boolean
  public notScrapeable: boolean
  public concurrent: boolean
  public sort: string
  public order: Number

  setId(id) {
    this.id = id
  }

  getMainParams(body) {
      this.id = body.champid
      this.name = body.name
      this.abbrev = body.abbrev
      this.sessionType = body.session_type
      this.liveName = body.live_name
      this.archiveName = body.archive_name
      this.order = this.setIntVal(body.order, 0)
      this.location = body.location
      this.duration = this.setIntVal(body.duration, 30)
      this.notLive = this.setBoolVal(body.not_live, false)
      this.notScrapeable = this.setBoolVal(body.not_scrapeable, false)
      this.concurrent = this.setBoolVal(body.concurrent, false)
      this.sort = body.sort
  }

  setMainParams(body) {
      body.champid = this.id
      body.name = this.name
      body.abbrev = this.abbrev
      body.session_type = this.setVal(this.sessionType)
      body.live_name = this.setVal(this.liveName)
      body.archive_name = this.setVal(this.archiveName)
      body.location = this.setVal(this.location, 'default')
      body.duration = this.setIntVal(this.duration, 30)
      body.not_live = this.notLive
      body.not_scrapeable = this.notScrapeable
      body.concurrent = this.concurrent
      body.order = this.setIntVal(this.order)
      body.sort = this.abbrev+'#'+this.location
  }

  copy(item: Stage) {
    this.id = item.id
    this.name = item.name+'-copy'
    this.location = item.location
    this.sort = item.abbrev+'#'+this.location
    this.abbrev = item.abbrev
    this.sessionType = item.sessionType
    this.liveName = item.liveName
    this.archiveName = item.archiveName
    this.duration = item.duration
    this.notLive = item.notLive
    this.notScrapeable = item.notScrapeable
    this.concurrent = item.concurrent
    this.order = item.order
  }

  static getNewItem(id) {
    return new Stage({"champid": id, "abbrev": "", "location": ""})
  }
}

<!-- <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"> -->

<!-- <app-source-list></app-source-list> -->
<!-- <div class="container">-->
<ul id="menu-account" >
  <li *ngFor="let champ of champs">
    <a routerLinkActive="active" (click)="openSubMenu(champ.id)" routerLink="/data/champ/{{ champ.id }}">{{ champ.name }}</a><a class="live-session" id="{{ 'title'+champ.id }}" >{{ perhapsShowLiveLinks(champ) }}</a>
    <ul id="sub-menu-account-{{ champ.id }}" [@openClose]="(isOpenSubMenu(champ.id)) ? 'open' : 'closed'" class="header" >
      <li *ngIf="true">
        <a routerLinkActive="active" routerLink="/data/champ/{{ champ.id }}/schedules">Schedules</a>
      </li>
      <li>
        <a routerLinkActive="active" routerLink="/data/champ/{{ champ.id }}/notifications/mmlive-alerts">Notifications</a>
      </li>
      <li >
        <a routerLinkActive="active" routerLink="/data/champ/{{ champ.id }}/stages">Stages</a>
      </li>
      <li >
        <a routerLinkActive="active" routerLink="/data/champ/{{ champ.id }}/gpnames">GP Names</a>
      </li>
      <li >
        <a routerLinkActive="active" routerLink="/data/champ/{{ champ.id }}/livesessions">Live Sessions</a>
      </li>
      <li >
        <a routerLinkActive="active" routerLink="/data/champ/{{ champ.id }}/scrapestats">Scrape Stats</a>
      </li>
      <li >
        <a routerLinkActive="active" routerLink="/data/champ/{{ champ.id }}/races">Races</a>
        <ul [@openClose]="(isOpenSubMenu(champ.id)) ? 'open' : 'closed'" class="header" >
          <!-- <li *ngFor="let race of getRaces(champ.id)"> -->
        </ul>
      </li>
      <li >
        <a routerLinkActive="active" routerLink="/data/champ/{{ champ.id }}/drivers">Drivers</a>
      </li>
      <li >
        <a routerLinkActive="active" routerLink="/data/champ/{{ champ.id }}/teams">Teams</a>
      </li>
    </ul>
  </li>
  <li >
    <a routerLinkActive="active" routerLink="/data/constants">Constants</a>
    <ul id="sub-menu-account-constants">
      <li>
        <a routerLinkActive="active" routerLink="/data/champs/">Champs</a>
      </li>
      <li>
        <a routerLinkActive="active" routerLink="/data/constants/countries/">Countries</a>
      </li>
      <li>
        <a routerLinkActive="active" routerLink="/data/constants/cities/">Cities</a>
      </li>
      <li>
        <a routerLinkActive="active" routerLink="/data/constants/equivs/">Equivalents</a>
      </li>
    </ul>
  </li>
</ul>
<!--
<ul ngbNav  class="nav-tabs">
  <li ngbNavItem>
    <a ngbNavLink routerLinkActive="active" routerLink="content-sources">Content by Source</a>
  </li>
  <li ngbNavItem>
    <a ngbNavLink routerLinkActive="active" routerLink="content-categories">Content by Category</a>
  </li>
</ul> -->

<!-- </div> -->

import { Component, OnInit, Input , Output, EventEmitter} from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, FormsModule, Validators } from '@angular/forms'
import { Content } from '../../model/content'
import { AbstractBaseItemComponent } from '../../base-classes/abstract-base-item/abstract-base-item.component'

@Component({
  selector: 'app-content-item',
  templateUrl: './content-item.component.html',
  styleUrls: ['../../styles/global.scss','../../base-classes/abstract-base-item/abstract-base-item.component.scss']
})
export class ContentItemComponent extends AbstractBaseItemComponent {

  item: Content
  type: string

  setParams(params) {
    this.id = params['id']
  }

  getItem() {
    this.dataService.getContent(this.id)
    .subscribe(item => {
      this.dataGetSuccess(item)
      this.type = item.type
    })
  }
  getLinkToList() {
    return "/content/contents/"+this.type
  }

  getItemName() {
    return "Content Item"
  }

  getItemNames() {
    return "Content"
  }

  getItemId() {
    return this.id
  }

  getValidators() {
    return {}
  }

  haveUpdateButton() {
    return false
  }

  getDisabledFields() {
    return ['id','type']
  }

  isText(key, group: UntypedFormGroup) {
    if (key == 'summary')
      return  true
    return false
  }

  isList(key, group: UntypedFormGroup) {
    if (key == 'images')
      return  true
    return false
  }

  isFirstImage(im) {
    return (im.first)
  }

  
  disableField(field: string, ctrls: any) {
    if (field == 'id' || field == 'type')
      ctrls[field].disable()
  }

  getImages() {
    return this.item.images
  }
}

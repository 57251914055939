import { Team } from '../../model/team'
import { Champ } from '../../model/champ'
import { Component } from '@angular/core';
import { Session } from '../../model/session'
import { ViewEncapsulation } from '@angular/core';
import { ScrapeItemListComponent } from '../scrape-item-list/scrape-item-list.component'


@Component({
  selector: 'app-driver-list',
  templateUrl: '../scrape-item-list/scrape-item-list.component.html',
  styleUrls: ['../../styles/global.scss','../scrape-item-list/scrape-item-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChampDriverListComponent extends ScrapeItemListComponent {

  public id: string
  public champid: string 

  setParams(params) {
    this.id = params['id']
  }

  haveScrapeItemsFeature() {
   return false
  }

  haveScrapeAllFeature() {
    return true
  }

  getLinkToEdit(item) {
    // console.log("Item = ", item)
    return "/data/champ/"+this.id+"/driver/"+this.getItemId(item)+"?year="+this.year
  }

  getItemsName() {
    return this.id.toUpperCase()+" Drivers"
  }

  getItemName() {
    return this.id.toUpperCase()+" Driver"
  }

  getItemId(item) {
    return item.id
  }

  addItemTHValues(tableid) {
    let str = ""
    let tab = document.getElementById(tableid)
    if (!tab)
      return
    let elem = this.addElement(tab, "thead", '')
    if (!elem)
      return
    while (elem.firstChild) {
      elem.removeChild(elem.lastChild)
    }
    this.addElement(elem, 'th', 'Pos')
    this.addElement(elem, 'th', 'Name')
    this.addElement(elem, 'th', 'Country')
    this.addElement(elem, 'th', 'Team')
    this.addElement(elem, 'th', 'Points')
    this.addElement(elem, 'th', '')
  }


  addItemTDValues(item) {
    let elem = document.getElementById(this.getTableId())
    if (!elem)
      return
    let tr = this.addElement(elem, "tr", '')
    if (!tr)
      return
    // this.addElement(tr, 'td', this.getItemId(item))
    tr.onclick = (event) => this.editItem(event, item)
    this.addElement(tr, 'td', item.position)
    this.addElement(tr, 'td', item.name)
    this.addElement(tr, 'td', item.country_code)
    this.addElement(tr, 'td', item.team)
    this.addElement(tr, 'td', item.points)

    let btn = this.addDeleteButtonIcon(tr, 'td', this.canEdit())
    btn.onclick = (event) => this.askDeleteItem(event, item)

  }

  getItems() {
    document.body.style.cursor='wait';
    this.dataService.getChamp(this.id, this.year)
    .subscribe(res => {
      this.champid = res.api_id
      console.log("ID = ", this.champid)
      this.dataService.getChampDrivers(this.id, this.year)
      .subscribe(items => {
        items.sort((a,b) => {
          return (a.position < b.position) ? -1 : 1
        })
        console.log(items)
        this.afterGetItems(items)
      })
    })
  }

  deleteItem(item) {
    document.body.style.cursor='wait';
    this.dataService.deleteDriver(this.id, item.id, this.year)
      .subscribe(res => this.afterSuccessfulDelete(res),
                 response => this.afterFailedDelete(response))
  }

  scrapeAllItems() {
    this.beforeScrapeItems()
    this.dataService.scrapeChampDrivers(this.id, this.year, this.champid)
      .subscribe(res => this.afterScrapeItemsSuccess(res), err => this.afterScrapeError(err))
  }


}

declare var require: any

export class Schedule {

  public name: string
  public cron: string
  public enabled: Boolean
  public description: string
  public humanTime: string
  public month: Number
  public day: Number
  public hour: Number
  public minute: Number
  public date: string
  public target: any
  public function: string
  public inputStr: string

  constructor (
    public body: string) {
      //console.log("body = ", body)
      this.fromJson(body)
  }

  fromJson(body) {
    this.getSchedule(body)
  }

  // toJson() {
  //   let res = this.setCategory()
  //   return JSON.stringify(res)
  // }

  getSchedule(body) {
      this.description = body.Description
      this.name = body.Name
      this.enabled = body.State == 'ENABLED'
      this.cron = body.ScheduleExpression
      this.target = body.Target
      this.getTargetVals(this.target)
      this.getTimes(this.cron)
  }


  getTargetVals(targ: any) {
    this.function = targ['Arn'].split('function:')[1]
    this.inputStr = targ['Input']
  }

  getTimes(cron: string) {
    let regExp = /\((.*?)\)/

    let cr = regExp.exec(cron)[1];
    //console.log(prettycron.toString(cr[1]))
    let pos = cr.lastIndexOf('*')
    cr = cr.slice(0, pos)
    //console.log("Name: ", this.name, ", Str = ", cr)

    let o = this.parseCronStr(cr)
    //let date = o.toDate().toString().split('GMT')[0]
    this.month = o.getMonth()+1
    this.day = o.getDate()
    this.hour = o.getHours()
    this.minute = o.getMinutes()
    this.date = this.fillZeroIfLessThanTen(this.month)+'/'+
                    this.fillZeroIfLessThanTen(this.day)+' '+
                    this.fillZeroIfLessThanTen(this.hour)+':'+
                    this.fillZeroIfLessThanTen(this.minute)

  }

  fillZeroIfLessThanTen(val) {
      return (val < 10) ? '0'+val : val
  }

  parseCronStr(cronStr) {
    //let parser = require('cron-parser')
    (window as any).global = window;
    var parser = require('cron-parser');

    var options = {
      //currentDate: '2021-04-17 09:28:01',
      //tz: 'GMT'
      utc: true
    };

    try {//*/15 0-4 29 3 ? *
      var interval = parser.parseExpression('0 '+cronStr, options);
      //console.log("FIELDS:", interval.next().getDate())

      return interval.next()
      //return interval.next().toString()
      //console.log('Date: ', interval.next().toString()); // Sat Dec 29 2012 00:42:00 GMT+0200 (EET)
//      console.log('Date: ', interval.next().toString()); // Sat Dec 29 2012 00:44:00 GMT+0200 (EET)

//      console.log('Date: ', interval.prev().toString()); // Sat Dec 29 2012 00:42:00 GMT+0200 (EET)
//      console.log('Date: ', interval.prev().toString()); // Sat Dec 29 2012 00:40:00 GMT+0200 (EET)
    } catch (err) {
      console.log('Error: ' + err.message);
    }
  }

}

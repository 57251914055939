declare var require: any
import { BaseItem } from './base-item'

export class Race extends BaseItem {

  public name: string
  public raceid: string
  public country: string
  public location: string
  public description: string
  public full_description: string
  public scheduled: string
  public scheduled_end: string
  public status: string
  public laps: Number
  public round: Number
  public url: string
  public api_id: string
  public champid: string
  public length: string
  public distance: string

  // constructor (
  //   public body: string) {
  //     //console.log("body = ", body)
  //     this.fromJson(body)
  // }

  fromJson(body) {
    this.getRace(body)
  }

  // toJson() {
  //   let res = this.setCategory()
  //   return JSON.stringify(res)
  // }

  setMainParams(body) {
    body.raceid = this.raceid
    body.champid = this.champid
    body.name  = this.name
    body.description = this.description
    body.original_description = this.full_description
    body.scheduled = this.scheduled
    body.scheduled_end = this.scheduled_end
    body.country = this.country
    body.location = this.location
    body.api_id = this.api_id
    body.status = this.status
    body.laps = this.laps
    body.url = this.url
    body.round = this.round
    body.length = this.length
    body.distance = this.distance
  }

  getRace(body) {
      this.raceid = body.raceid
      this.champid = body.champid
      this.api_id = body.api_id
      this.description = body.description
      this.full_description = body.original_description
      this.name = body.name
      this.country = body.country
      this.location = body.location
      this.scheduled = body.scheduled
      this.scheduled_end = body.scheduled_end
      this.status = body.status
      this.laps = body.laps
      this.url = body.url
      this.distance = body.distance
      this.length = body.length
      this.getRound()
  }

  getRound() {
    this.round = parseInt(this.raceid .split(':')[0].replace('r', ''))
  }

}

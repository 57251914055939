import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http'
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { ContentModule } from './content/content.module'
import { DataModule } from './data/data.module'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { MatTableModule } from '@angular/material/table'
import { MatSortModule } from '@angular/material/sort';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from './login/login.component'
import { SecurityService } from './security/security.service'
import { BreadcrumbModule } from "xng-breadcrumb"
import { BreadcrumbService } from 'xng-breadcrumb';
import { TabbedItemComponent } from './base-classes/tabbed-item/tabbed-item.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    TabbedItemComponent,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    ContentModule,
    DataModule,
    BrowserAnimationsModule,
    MatTableModule,
    MatSortModule,
    NgbModule,
    BreadcrumbModule,
    BrowserModule,
  ],
  providers: [
    SecurityService,
    BreadcrumbService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Component } from '@angular/core';
import { AbstractItemListComponent } from '../../base-classes/abstract-item-list/abstract-item-list.component'
import { ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-failed-content-list',
  templateUrl: '../../base-classes/abstract-item-list/abstract-item-list.component.html',
  styleUrls: ['../../styles/global.scss','../../base-classes/abstract-item-list/abstract-item-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FailedContentListComponent extends AbstractItemListComponent {

  public type: string
  public reason_code: string

// OVERRIDDEN METHODS:

  setParams(params) {
    this.type = params.type
    this.reason_code = params.reason_code
  }

  getLinkToEdit(item) {
    // console.log("Item = ", item)
    return "/content/failed_content_item/"+item.type+'/'+item.acquiredstamp
  }

  getItemsName() {
    return "Failed Content"
  }

  getItemName() {
    return "Failed Content"
  }

  getItemId(item) {
    return item.type+' - '+item.acquireddate
  }

  haveDeleteAllFeature() {
    return this.items.length > 0
  }

  createSelectFilters() {
    this.selectFilters = []
    let fil = {
      'name': 'Type',
      'id': 'type',
      'selected': 'all',
      'options': [{'name': 'Text', 'id': 'text'},{'name': 'Video', 'id': 'video'}]
    }
    this.selectFilters.push(fil)
    fil = {
      'name': 'Reason',
      'id': 'reason',
      'selected': 'all',
      'options': [{'name': 'Copyright', 'id': 'COPYRIGHT'},{'name': 'Image', 'id': 'IMAGE'}]
    }
    this.selectFilters.push(fil)
  }

  getFilterList(index) {
    if (!this.haveSelectFilters())
      return


    document.body.style.cursor='wait';
    let fil = this.selectFilters[index]
    if (fil['id'] == 'type') {
      index++
      if (index >= this.selectFilters.length)
        this.getItems()
      else
        this.getFilterList(index)
    }      
    if (fil['id'] == 'reason') {
      index++
      if (index >= this.selectFilters.length)
        this.getItems()
      else
        this.getFilterList(index)
    }      
  }


  addItemTHValues(tableid) {
    let str = ""
    let tab = document.getElementById(tableid)
    if (!tab)
      return
    let elem = this.addElement(tab, "thead", '')
    if (!elem)
      return
    while (elem.firstChild) {
      elem.removeChild(elem.lastChild)
    }
    this.addElement(elem, 'th', 'Type')
    this.addElement(elem, 'th', 'Date')
    this.addElement(elem, 'th', 'Reason code')
    this.addElement(elem, 'th', 'Reason')
    this.addElement(elem, 'th', 'Source')
    this.addElement(elem, 'th', '')
    this.addElement(elem, 'th', '')
  }


  addItemTDValues(item) {
    let elem = document.getElementById(this.getTableId())
    if (!elem)
      return
    let tr = this.addElement(elem, "tr", '')
    if (!tr)
      return
    this.addElement(tr, 'td', item.type)
    tr.onclick = (event) => this.editItem(event, item)
    this.addElement(tr, 'td', item.acquireddate)
    this.addElement(tr, 'td', item.reason_code)
    this.addElement(tr, 'td', item.reason)
    this.addElement(tr, 'td', item.source)
    let btn = this.addDeleteButtonIcon(tr, 'td', this.canEdit())
    btn.onclick = (event) => this.askDeleteItem(event, item)
    let btn2 = this.addLinkButtonIcon(tr, 'td', this.canEdit())
    btn2.onclick = (event) => this.followLink(event, item.url)
  }

  getItems() {
    document.body.style.cursor='wait';
    let typeFilter = this.getFilterSelectedOption('type')
    let reasonFilter = this.getFilterSelectedOption('reason')
    this.dataService.getFailedContent(this.dateFrom, typeFilter, reasonFilter)
        .subscribe(items => this.afterGetItems(items))
  }


  deleteItem(item) {
    document.body.style.cursor='wait';
    this.dataService.deleteFailedContent(item.type, item.acquiredstamp)
      .subscribe(res => this.afterSuccessfulDelete(res),
                 response => this.afterFailedDelete(response))
  }

  deleteAllItems() {
    document.body.style.cursor='wait';
    this.dataService.deleteFailedContent()
      .subscribe(res => this.afterSuccessfulDelete(res),
                 response => this.afterFailedDelete(response))
  }

  haveFilter() {
    return true
  }

  haveDateFilter(): boolean {
    return true
  }

// END: OVERRIDDEN METHODS


}

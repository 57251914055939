<div class="panel panel-primary">

  <h5 class="panel-title">{{ getTitle() }}</h5>
  <div class="panel-body">
    <div class="col-sm-12">


      <div *ngIf="state == 'ready'">
        <button (click)="refresh()" class="btn flushright">
          <i class="fa fa-refresh"></i>
        </button>
        <table *ngIf="items && items.length > 0" id="{{ getTableId() }}" class="items">
        </table>
        <p *ngIf="!items || items.length === 0" class="status-message">
          No {{ getItemsName() }} found.
        </p>
      </div>

      <div *ngIf="state !== 'ready'">
        <button (click)="refresh()" class="btn flushright">
          <i class="fa fa-refresh"></i>
        </button>
        <p *ngIf="!items" class="loading-message">
          Loading {{ getItemsName() }}...
        </p>
      </div>

    </div>
  </div>

  <mat-paginator *ngIf="state == 'ready' && maxItems > numOnPage" 
                 [length]="this.maxItems" 
                 [pageSize]="this.numOnPage" 
                 [pageIndex]="this.page"
                 (page)="handlePageChange($event)"
                 [showFirstLastButtons]="true"> 
  </mat-paginator>

  <form [formGroup]="form" *ngIf="state === 'ready' && haveCreateFeature()" (ngSubmit)="save()" class="form-horizontal">
    <h5 class="panel-title">New {{ getItemName() }}</h5>
    <div class="panel-body">
      <div class="col-sm-12">
        <p *ngIf="state === 'ready' && canEdit()">
          <table>
            <tr *ngFor="let key of getKeys()" class="field form-group">
                <td class="label">
                  <label class="control-label" for="{{ key }}">{{ capitalize(key) }}</label>
                </td>
                <td class="col-sm-6">
                  <input *ngIf="isBool(key, this.form)" id="{{ key }}" formControlName="{{ key }}" type="checkbox" class="checkbox form-control">
                  <input *ngIf="!isBool(key, this.form) " id="{{ key }}" formControlName="{{ key }}" type="text"  class="text form-control">

                  <div *ngIf="invalid(key)" class="alert alert-danger">
                       {{ validationError(key)}}
                   </div>
                </td>
            </tr>
          </table>
          <button class="btn btn-primary">Create</button>
      </div>
    </div>
  </form>

  <div *ngIf="state === 'ready'">
    <button id="deleteAllButton" *ngIf="haveDeleteAllFeature()" (click)="askDeleteAllItems()" class="btn btn-primary">Delete all {{ getItemsName() }}</button>
  </div>

</div>

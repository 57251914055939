import { Component, OnInit } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { ScrapeBaseItemComponent } from '../scrape-base-item/scrape-base-item.component'
import { Driver } from '../../model/driver'

@Component({
  selector: 'app-champ-schedule-item',
  templateUrl: '../../base-classes/abstract-base-item/abstract-base-item.component.html',
  styleUrls: ['../../styles/global.scss','../../base-classes/abstract-base-item/abstract-base-item.component.scss']
})
export class ChampScheduleItemComponent extends ScrapeBaseItemComponent {

  public id: string
  public name: string

  setParams(params) {
    this.id = params['id']
    this.name = params['name']
  }

  getItem() {
    this.dataService.getSchedule(this.name)
    .subscribe(item => {
      this.dataGetSuccess(item)
    })
  }

  getLinkToList() {
    return "/data/champ/"+this.id+"/schedules/"
  }

  getItemName() {
    return "Schedule"
  }

  getItemNames() {
    return this.id.toUpperCase()+" Schedules"
  }

  getItemId() {
    return this.id.toUpperCase()+' / '+this.name
  }

  getValidators() {
    return {}
  }

  getDisabledFields() {
    return ['id','name','function']
  }

  disableField(field: string, ctrls: any) {
    if (field == 'id' || field == 'name' || field == 'function')
      ctrls[field].disable()
  }

  updateItem(item) {
    this.dataService.updateSchedule(JSON.stringify(this.item))
    .subscribe(
      val => this.dataSuccess(val, 'PATCH'),
      err => this.dataError(err, 'PATCH'),
      () => {}
    )
  }


}

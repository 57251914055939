import { Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, FormsModule, Validators } from '@angular/forms'
import { AbstractItemListComponent } from '../../../base-classes/abstract-item-list/abstract-item-list.component'

@Component({
  selector: 'app-item-list',
  templateUrl: './item-list.component.html',
  styleUrls: ['../../../styles/global.scss', '../../../base-classes/abstract-item-list/abstract-item-list.component.scss','./item-list.component.scss']
  // templateUrl: './item-list.component.html',
  // styleUrls: ['../../../styles/global.scss','./item-list.component.scss']
})
export class ItemListComponent extends AbstractItemListComponent {
 
  year: number
  
  updateForm() {
    let validators = this.getValidators()
    let disabledFields = this.getDisabledFields()
    if (this.haveCreateFeature()) {
      this.item = this.getNewItem()
      console.log("New item: ", this.item)
      let ctrls = this.getControls(this.item, validators, disabledFields)
      this.form = new UntypedFormGroup(ctrls)
    }
  }

  setTitle() {
    this.pageTitle += this.getItemsName()+" List"
  }

  getYear() {
    let y = localStorage.getItem("year")
    if (y !== undefined && y !== '0')
      this.year =Number(y)
 }

  // createFieldForm() {
  //   this.fieldForm = new FormGroup({
  //     type: new FormControl('string'),
  //     value: new FormControl(''),
  //  })
  // }

  getControls(fields, validators, disabled) {
    let ctrls = {}
    // console.log("Fields = ", fields)
    for (const field in fields) {
      let subField = fields[field]
      //console.log("Subfield = ", subField)
      if (subField !== undefined && typeof subField === 'string' ) {

          if (field in validators)
            ctrls[field] = new UntypedFormControl(subField, validators[field])
          else
            ctrls[field] = new UntypedFormControl(subField)
          if (disabled.includes(field)) {
            this.disableField(field, ctrls)
          }
      }
    }
    return ctrls
  }

  save(): void {
    if (this.anyInvalidFields())
      return
    this.createItem(this.form)
  }

  ////////////////////////////////////////////////////////////////////////////////
  // NEED TO BE WRITTEN BY DERIVED CLASS

  getNewItem() {
    return null
  }

  haveCreateFeature() {
    return true
  }

  haveDeleteAllFeature() {
    return this.items.length > 0
  }

  createItem(form) {
    console.log("Updating item ", form)

  }
  // END: NEED TO BE WRITTEN BY DERIVED CLASS
  ////////////////////////////////////////////////////////////////////////////////

  afterGetItems(items) {
    this.items = items
    this.page = 0
    if (items) {
      this.maxItems = items.length
      this.maxPages = items.length / this.numOnPage
      if (items.length % this.numOnPage > 0)
        this.maxPages += 1
      this.showItems()
      }
    this.updateForm()
    // this.createFieldForm()

    this.state = "ready"
    //console.log("Finished after get")
    document.body.style.cursor='default';
  }


  getNextItems(page) {
    this.page = page
    this.showItems()
  }

  clearTableValues() {
    let elem = document.getElementById(this.getTableId())
    if (!elem)
      return
    while (elem.firstChild) {
      elem.removeChild(elem.lastChild)
    }
    elem = document.getElementById("newtable")
    if (!elem)
      return
    while (elem.firstChild) {
      elem.removeChild(elem.lastChild)
    }
  }


}

import { BaseItem } from './base-item'
import { ContentStats } from './content-stats'

export class Category extends BaseItem {

  public keywords: any
  public id: string
  public name: string
  public stats: ContentStats


  getValues(body) {
    // console.log("Body = ", body)
      body = JSON.parse(body)
      this.id = body.id
      this.keywords = JSON.stringify(body.keywords)
      this.name = body.name
      // console.log("GET Keywords: ", this.keywords)
  }

  setValues() {
      
      this.keywords =  JSON.parse(this.keywords)
      console.log("SET Keywords: ", this.keywords)
    }

}
